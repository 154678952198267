import React from 'react'
import Layout from '../../Components/Layout/Layout'
import img from './faq.jpg'
function ComplaintPolicy () {
  return (
    <>
      <Layout>
        <div>
          <img src={img} />
        </div>
        <div className='container'>
          <div className='row mt-3 text-center'>
            <h3>Complaints Policy & Procedures</h3>
          </div>
          <div className='row mt-4 mb-4'>
            <p className='FontSize15'>
            At Globetm, your satisfaction is our top priority. We understand that issues can arise, and when they do, we want to resolve them as quickly and efficiently as possible. Here's a brief overview of our Complaint Policy:

            </p>{' '}
            <h3>How to Submit a Complaint</h3>
            <p>You can submit a complaint through the following channels:</p>
            <p className='mt-2'>
              Email: info@globetm.co.uk<br/>Phone:+44 7703 399669<br/>Online Form: Contact us on our website<br/>Please include as much detail as possible, such as your booking reference and the nature of your complaint.
            </p>
            <h3 className='mt-2'>Our Process</h3>
            <p>You can submit a complaint through the following channels:</p>
            <p className='mt-2'>
              Acknowledgment: We’ll acknowledge your complaint within 24-48 hours.<br/>Investigation: We’ll investigate your concerns thoroughly.<br/>Resolution: We aim to resolve complaints within 7-14 business days.<br/>Escalation: If you're unsatisfied with the outcome, you can request an escalation.<br/>Final Response: We strive to provide a final response within 30 days.<br/>Continuous Improvement<br/>We view your feedback as a valuable opportunity for improvement and regularly review our services and policies based on your experiences.
            </p>
            <h3 className='mt-2'>Contact Us</h3>
            <p>If you have any questions or need further assistance, please don’t hesitate to contact us. Your peace of mind is our priority, and we’re here to ensure your travel booking experience is smooth and enjoyable.</p>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ComplaintPolicy
