import React, { useState, useEffect } from 'react'
import logo from './logo.png'
import Axios from 'axios'
import ReactDOM from 'react-dom'
import { json, useNavigate, } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CurrencyConverter } from '../GlobalData/GlobalData'
import indflag from '../../Images/flag/portugal.png'
import { Hotelapitoken, ApiEndPoint } from '../GlobalData/GlobalData'
import { CurrencyRates, AllCurrencyRates } from '../../Redux/Actions/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPhone,
  faSignIn,
  faBars,
  faTimes,
  faChevronDown,
  faCross,
  faClose,
  faAngleRight,
  faArrowDown,
  faAngleDown,
  faMosque,
  faKaaba,
  faDownload,
  faGlobe,
  faBell,
  faCircleUser
} from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next';
import i18n from '../../i18n';
import LanguageSwitcher from '../Activity/TranslationTrigger';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useTranslation } from 'react-i18next';
function Navbar2 ({goLogout}) {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const navigation = useNavigate()
  var token = Hotelapitoken()
  var endpoint = ApiEndPoint()
  const [modalOpen, setModalOpen] = useState(false)
  const [countryList, setCountryList] = useState([])
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)
  const [userCountry, setUserCountry] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [dropdownCat, setDropdownCat] = useState([])
  const [isDropdownOpen2, setDropdownOpen] = useState(false)

  const [selectedCurrency, setSelectedCurrency] = useState('GBP')
 
  // This function put query that helps to
  // change the language

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen2)
  }

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen)
  }
  const buttonIconClass = isMobileNavOpen
    ? 'navbar-toggler-icon-cross'
    : 'navbar-toggler-icon'
  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }
  useEffect(() => {
    GetCatagories();
    fetchData();
    AllCurrency();
    const storedCurrency = localStorage.getItem('selectedCurrency')
    if (storedCurrency) {
      setSelectedCurrency(storedCurrency)
    }
  }, []);

  const SearchValue = e => {
    var filteredList = countryList.filter(item =>
      item.currency.toLowerCase().includes(e.target.value.toLowerCase())
    )
    setUserCountry(filteredList)
    setSearchValue(e.target.value)
  };

  async function fetchData () {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      // 'Authorization': 'Bearer YourAccessToken', // Replace with your authorization token
      'Content-Type': 'application/json' // Specify content type if needed
    }
    try {
      const response = await Axios.get(
        endpoint + '/api/countries/fetch',
        headers
      )
      setCountryList(response.data.countries)
    } catch (error) {
      // Handle any errors
      console.error('Error:', error)
    }
  }
  const handleCurrencyDivClick = (index, currency) => {
    toggleModal();
    setActiveIndex(index);
    setSelectedCurrency(currency);
    CurrencyCalucaltion(currency);
    localStorage.setItem('selectedCurrency', currency)

    // Perform other calculations or actions here
  }

  const CurrencyCalucaltion = c => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/' + c, // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    }

    Axios.request(config)
      .then(response => {
        response.data.selectedcurrency = c;
        dispatch(CurrencyRates(response.data));
        
      })
      .catch(error => {
        // Handle errors here
        console.error(error)
      })
  }

  const AllCurrency = () => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/GBP', // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    };

    Axios.request(config)
      .then(response => {
        // Handle the response data here
        dispatch(AllCurrencyRates(response.data))
      })
      .catch(error => {
        // Handle errors here
        console.error(error)
      })
  }

  const GetCatagories = async () => {
    var data = {
      token: token
    };
    try {
      const response = await Axios.post(
        endpoint + '/api/get_all_catigories_list_apis_new',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
        sessionStorage.setItem('Catogories',JSON.stringify(response.data.categories));
      setDropdownCat(response.data.categories);
     
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const FindPackageDetail = id => {
    toggleDropdown();
    navigation('/umrah_packages', { state: id })
  };
  const languages = [
    { value: "en", text: "English" },
    { value: "ar-SA", text: "Arabic" },
];
const logout=()=>{
  goLogout();
}

  return (
    <>
       <div class="header header-transparent theme">
      <div class="container">
        <nav id="navigation" class="navigation navigation-landscape">
          <div class="nav-header">
            <NavLink class="nav-brand static-show" to='/'><img src={logo} class="logo nav-logo" alt=""></img></NavLink>
            <div class="nav-toggle"></div>
            <div class="mobile_nav">
              <ul>
              <li class="currencyDropdown me-2">
                <a  onClick={toggleModal} class="nav-link" data-bs-toggle="modal" data-bs-target="#currencyModal"><span
                    class="fw-medium">{selectedCurrency}</span></a>
              </li>
                <li>
                  <a href="#" class="bg-light-primary text-primary rounded" data-bs-toggle="modal"
                    data-bs-target="#login"><i class="fa-regular fa-circle-user fs-6"><FontAwesomeIcon icon={faCircleUser}/></i></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="nav-menus-wrapper" style={{transitionProperty: 'none'}}>
            <ul class="nav-menu">

              <li className='ms-3'><NavLink to='/'>Home<span class="submenu-indicator"></span></NavLink>
              </li>

              <li><NavLink to='/about-us'>About us<span class="submenu-indicator"></span></NavLink>
              </li>

              <li><NavLink to='/contact-us'>Contact us<span class="submenu-indicator"></span></NavLink>  
              </li>

              {/* <li><a href="JavaScript:Void(0);">Menu<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu xxl-menu">
                  <li>
                    <a href="home-stay.html">
                      <div class="mega-advance-menu">
                        <div class="mega-first square--50 rounded-2 gray-simple text-success fs-4"><i
                            class="fa-solid fa-spa"></i></div>
                        <div class="mega-last ps-2">
                          <h6 class="lh-base fs-6 font--bold m-0">Home Stays</h6>
                          <p class="text-sm-muted m-0">Beautiful Place for stays</p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="home-hotel.html">
                      <div class="mega-advance-menu">
                        <div class="mega-first square--50 rounded-2 gray-simple text-warning fs-4"><i
                            class="fa-solid fa-hotel"></i></div>
                        <div class="mega-last ps-2">
                          <h6 class="lh-base fs-6 font--bold m-0">Home Hotel</h6>
                          <p class="text-sm-muted m-0">Beautiful Place for stays</p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="home-flight.html">
                      <div class="mega-advance-menu">
                        <div class="mega-first square--50 rounded-2 gray-simple text-primary fs-4"><i
                            class="fa-solid fa-plane"></i></div>
                        <div class="mega-last ps-2">
                          <h6 class="lh-base fs-6 font--bold m-0">Home Flight</h6>
                          <p class="text-sm-muted m-0">Beautiful Place for stays</p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="home-rental.html">
                      <div class="mega-advance-menu">
                        <div class="mega-first square--50 rounded-2 gray-simple text-purple fs-4"><i
                            class="fa-solid fa-eye"></i></div>
                        <div class="mega-last ps-2">
                          <h6 class="lh-base fs-6 font--bold m-0">Home Rental</h6>
                          <p class="text-sm-muted m-0">Beautiful Place for stays</p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="home-car.html">
                      <div class="mega-advance-menu">
                        <div class="mega-first square--50 rounded-2 gray-simple text-seagreen fs-4"><i
                            class="fa-brands fa-dropbox"></i></div>
                        <div class="mega-last ps-2">
                          <h6 class="lh-base fs-6 font--bold m-0">Home Cabs</h6>
                          <p class="text-sm-muted m-0">Beautiful Place for stays</p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="home-stay.html">
                      <div class="mega-advance-menu">
                        <div class="mega-first square--50 rounded-2 gray-simple text-info fs-4"><i
                            class="fa-solid fa-person-walking-luggage"></i></div>
                        <div class="mega-last ps-2">
                          <h6 class="lh-base fs-6 font--bold m-0">Home Destination</h6>
                          <p class="text-sm-muted m-0">Beautiful Place for stays</p>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </li> */}

              {/* <li><a href="documantion/index.html" target="_blank">Docs</a></li> */}

            </ul>

            <ul class="nav-menu nav-menu-social align-to-right">
              <li class="currencyDropdown me-2">
                <a  onClick={toggleModal} class="nav-link" data-bs-toggle="modal" data-bs-target="#currencyModal"><span
                    class="fw-medium">{selectedCurrency}</span></a>
              </li>
              {/* <li class="languageDropdown me-2">
                <a href="#" class="nav-link" data-bs-toggle="modal" data-bs-target="#countryModal"><img
                    src={indflag} class="img-fluid" width="17" alt="Country"/></a>
              </li> */}
              <li class="list-buttons">
                <a style={{cursor:'pointer'}} onClick={logout} class="bg-primary" data-bs-toggle="modal" data-bs-target="#login"><i
                    class="fa-regular fa-circle-user fs-6 me-2"><FontAwesomeIcon icon={faCircleUser}/></i>Logout</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
      {/*********** Cuurency  Model ****************/}
      <Modal
        style={{ maxWidth: '90%' }}
        isOpen={modalOpen}
        toggle={toggleModal}
      >
        <ModalHeader toggle={toggleModal}>Select Your Currency</ModalHeader>
        <ModalBody>
          <div className='form-group'>
            <p>
              Where applicable, prices will be converted to—and shown in—the
              currency you select. The currency you pay in may differ based on
              your reservation, and a service fee may also apply.
            </p>
            <div className='border-line mt-2 mb-2'></div>
            {/* <div className='row mt-2'>
         <h5>Suggested for you</h5>
       </div> */}
            <div className='row'>
              <div className='d-flex justify-content-between'>
                <h5>All Currencies</h5>
                <input
                  type='text'
                  value={searchValue}
                  onChange={SearchValue}
                  placeholder='Search'
                />
              </div>

              {searchValue === ''
                ? // Render the full list when there is no search input
                  countryList.map((item, index) => (
                    <div key={index} className='col-md-4 mt-2 currency-modal'>
                      <div
                        className={` ${
                          index === activeIndex ? 'currency-div' : ''
                        }`}
                        onClick={() =>
                          handleCurrencyDivClick(index, item.currency)
                        }
                      >
                        <p className='currency-item'>
                          {item.name} - {item.currency}
                        </p>
                      </div>
                    </div>
                  ))
                : // Render the filtered list when there is a search input
                  userCountry.map((item, index) => (
                    <div key={index} className='col-md-4 mt-2 currency-modal'>
                      <div
                        className={` ${
                          index === activeIndex ? 'currency-div' : ''
                        }`}
                        onClick={() =>
                          handleCurrencyDivClick(index, item.currency)
                        }
                      >
                        <p className='currency-item'>
                          {item.name} - {item.currency}
                        </p>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default Navbar2
