import React,{useEffect,useState} from 'react';
import Googlemap from '../../Components/Googlemap/Googlemap';
import Layout from '../../Components/Layout/Layout';
import wow from 'wowjs';
import { ToastContainer,toast } from 'react-toastify';
import emailjs from 'emailjs-com';
const Contact = ({ onLogout }) => {
    const [formData,setFormData]=useState({email:'',name:'',subject:'',phnno:'',message:''});
    const [isBooking, setIsBooking] = useState(false);
    useEffect(()=>{
        new wow.WOW().init();
    },[]);
    
    const handleChange=(event)=>{
        const {value,name}=event.target;
        setFormData(prevdata=>({
            ...prevdata,
            [name]:value,
        }));
    };

    const isValidEmail=(email)=> {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

    const submitForm = async()=>{
        if(formData.name==='')
        {
            toast.info('Please Enter Your Name.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        } else if(formData.email===''){

            toast.info('Please Enter Your Email.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }else if(formData.subject==='')
        {
            toast.info('Please Enter Subject.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }else if(formData.phnno==='')
        {
            toast.info('Please Enter Phone Number.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }else if(formData.message==='')
        {
            toast.info('Please Enter Message.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }
        else if(!isValidEmail(formData.email))
        {
            toast.info('Please Enter a valid email.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }

        setIsBooking(true);
        try {
            const templateParams = {
              email: formData.email,
              name: formData.name,
              subject: formData.subject, 
              phone : formData.phnno,
              message: formData.message
            };
      
            await emailjs.send('service_d6zrc2l', 'template_la4ks0l', templateParams, 'c163bgNie5rW0iU1f');
            setIsBooking(false);
            toast.success('Email Send Successfully.', {
                position: toast.POSITION.TOP_RIGHT
              });
          } catch (error) {
            setIsBooking(false);
            console.error('Error sending email', error);
            toast.error('Error sending email.', {
                position: toast.POSITION.TOP_RIGHT
              });
          }
    }
    return (
        <>
        <Layout logout={onLogout}>
            <ToastContainer/>
        {/* <!-- ============================ Booking Title ================================== --> */}
		<section class="bg-cover position-relative about_us_bg"  data-overlay="5">
			<div class="container">
				<div class="row align-items-center justify-content-center">
					<div class="col-xl-7 col-lg-9 col-md-12">

						<div class="fpc-capstion text-center my-4">
							<div class="fpc-captions">
								<h1 class="xl-heading text-light">Get-in Touch</h1>
								<p class="text-light">We're Here to Help</p>
							</div>
						</div>

					</div>
				</div>
			</div>
		</section>
        {/* <!-- ============================ Form Section ================================== --> */}
		<section>
			<div class="container">

				<div class="row justify-content-between g-4 mb-5">
					<div class="col-xl-6 col-lg-6 col-md-6">
						<div class="card p-4 rounded-4 border br-dashed text-center h-100">
							<div class="crds-icons d-inline-flex mx-auto mb-3 text-primary fs-2"><i class="fa-solid fa-briefcase"></i>
							</div>
							<div class="crds-desc">
								<h5>Drop a Mail</h5>
								<p class="fs-6 text-md lh-2 mb-0">info@globetm.co.uk</p>
							</div>
						</div>
					</div>
					<div class="col-xl-6 col-lg-6 col-md-6">
						<div class="card p-4 rounded-4 border br-dashed text-center h-100">
							<div class="crds-icons d-inline-flex mx-auto mb-3 text-primary fs-2"><i class="fa-solid fa-headset"></i>
							</div>
							<div class="crds-desc">
								<h5>Call Us</h5>
								<p class="fs-6 text-md lh-2 mb-0">+44 7703 399669</p>
							</div>
						</div>
					</div>
					{/* <div class="col-xl-4 col-lg-4 col-md-6">
						<div class="card p-4 rounded-4 border br-dashed text-center h-100">
							<div class="crds-icons d-inline-flex mx-auto mb-3 text-primary fs-2"><i class="fa-solid fa-globe"></i>
							</div>
							<div class="crds-desc">
								<h5>Connect with Social</h5>
								<p class="text-md lh-2">Let's Connect with Us via social media</p>
								<ul class="list-inline mb-0">
									<li class="list-inline-item"> <a class="square--40 circle gray-simple color--facebook" href="#"><i
												class="fa-brands fa-facebook-f"></i></a> </li>
									<li class="list-inline-item"> <a class="square--40 circle gray-simple color--instagram" href="#"><i
												class="fa-brands fa-instagram"></i></a> </li>
									<li class="list-inline-item"> <a class="square--40 circle gray-simple color--twitter" href="#"><i
												class="fa-brands fa-twitter"></i></a> </li>
									<li class="list-inline-item"> <a class="square--40 circle gray-simple color--dribbble" href="#"><i
												class="fa-brands fa-dribbble"></i></a> </li>
								</ul>
							</div>
						</div>
					</div> */}
				</div>

				<div class="row align-items-center justify-content-between g-4">

					<div class="col-xl-7 col-lg-7 col-md-12">
						<div class="contactForm gray-simple p-4 rounded-3">
							<form>
								<div class="row align-items-center">

									<div class="col-xl-12 col-lg-12 col-md-12">
										<div class="touch-block d-flex flex-column mb-4">
											<h2>Drop Us a Line</h2>
											<p>Get in touch via form below and we will reply as soos as we can. </p>
										</div>
									</div>

									<div class="col-xl-6 col-lg-6 col-md-6">
										<div class="form-group">
											<label class="form-label">Your Name</label>
											<input type="text"  onChange={handleChange} className="form-control" name="name"/>
										</div>
									</div>

									<div class="col-xl-6 col-lg-6 col-md-6">
										<div class="form-group">
											<label class="form-label">eMail ID</label>
											<input type="email" onChange={handleChange} className="form-control" name="email"/>
										</div>
									</div>

									<div class="col-xl-6 col-lg-6 col-md-6">
										<div class="form-group">
											<label class="form-label">Phone No.</label>
											<input type="text" onChange={handleChange}  className="form-control" name="phnno"/>
										</div>
									</div>

									<div class="col-xl-6 col-lg-6 col-md-6">
										<div class="form-group">
											<label class="form-label">Subject</label>
											<input type="text" onChange={handleChange}  className="form-control" name="subject"/>
										</div>
									</div>

									<div class="col-xl-12 col-lg-12 col-md-12">
										<div class="form-group">
											<label class="form-label">Your Query</label>
											<textarea onChange={handleChange} name="message" class="form-control ht-120"></textarea>
										</div>
									</div>

									<div class="col-xl-12 col-lg-12 col-md-12">
										<div class="form-group mb-0">
											<button type="button" disabled={isBooking} onClick={submitForm} class="btn fw-medium btn-primary">
                                            {isBooking ? <Spinner /> : 'Send Message'}
                                            </button>

										</div>
									</div>

								</div>
							</form>
						</div>
					</div>

					<div class="col-xl-5 col-lg-5 col-md-12">
						<iframe class="full-width ht-100 grayscale rounded"
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2431.0074118442067!2d-1.8621419070118492!3d52.44589152602594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4870bbef3588bc13%3A0x8a441b0b4a83c6d0!2sAlhijaz%20Tours!5e1!3m2!1sen!2suk!4v1683195987117!5m2!1sen!2suk"
							height="500"  aria-hidden="false" tabindex="0"></iframe>
					</div>

				</div>

			</div>
		</section>
            </Layout>
        </>
    );

}

const Spinner = () => {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  };
export default Contact;