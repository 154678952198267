import React, { useState,useEffect } from 'react'
import login from '../../Images/login.svg'
import logo from '../../Images/logo.png'
import { ToastContainer, toast } from 'react-toastify'
import PropTypes from 'prop-types'
import Axios from 'axios'
import Loading from '../../Components/Loading/Loader'
import { ApiEndPoint } from '../../Components/GlobalData/GlobalData'
function Login ({ setToken }) {
  const [country, setCountry] = useState([]);
  const [city, setCity] = useState([]);
  const [userData, setUserData] = useState({ username: '', password: '' })
  const [signupData, setSignupData] = useState({title:'MR',fname: '',lname: '', email: '',cname: '',caddress: '',country: '',phno: '',zcode: '',pass:'',})
  const [signUp, setsignUp] = useState(false);
  const [signUpCity, setsignUpCity] = useState('');
  const [loader,setLoader]=useState(false);
  const endpoint=ApiEndPoint();

  useEffect(()=>{
    GetCountries();
  },[]);

  function handleChange (e) {
    setUserData({ ...userData, [e.target.name]: e.target.value })
  };
  function handleSignupChange (e) {
    if (e.target.name === 'country') {
      const code = e.target.value;
      const selectedcountry = country.find(item => item.iso2 === code);
      GetCountryCities(selectedcountry.id);
    }
      setSignupData({ ...signupData, [e.target.name]: e.target.value })
    
  };
  function handleSignupChangeCity (e) {
   
    setsignUpCity(e.target.value);
    
  };
  const HandleLogin = async() => {
    const data={
      'token':'IoOCQ3ObCcqj3BmtYVUBKTaCIWWoFnQcCorlRUsL-peMCb6m7dlEhXnSJhXXEo7Dh8bG7WQq18wbzMIvAKNk2RtIVKSBc3uUgZASa-0DZ0L5oiwJ9rSktbNb1dM3efA-b7BLH97ryRSj8vglisLUecscxtA1OFPF7kYWWaqDSKxovS9yKw4jBhUWwMrYT306oG2UZgmDpxP-zx6hENsrnFrHXtOqO6e5SA6ZdJsbJmOXZxDq5ZOcLdZ6PgzeQVdnivhXQHA8g3gzQoNuhYo4E1UYNOdTYGS16EvMpOUTxfmhmLz1-hw9SPnIiIzOX9K83qEOptngC4ftezuMmw2cFusTrxrKMvbH8SUqKAiywnTuiyV4yunaolsqVwbR-4PyM6FO8usVBMFf49vNBSO0nh-cdb8imZPtqb4xGeGHHIu5mG7uMAKZaJVbXGpC2eZfjab3NGV9Z-fmSmrDdAmO44ew0Xf0ZIXu4UoJx8a7EfGQRwWl51g5ZF93J0Vm',
      'email':userData.username,
      'password':userData.password
    }
    setLoader(true);
    try {
      const response = await Axios.post(endpoint+'/api/login_customer_submit',data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        } ,
      });
      setLoader(false);
     if(response.data.status==='error'){
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      })
   }else{
    setToken('true')
    localStorage.setItem('token', '12345')
   }
    } catch (error) {
      console.error('Error:', error);
    };
  };
  const GetCountries = async() => {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      // 'Authorization': 'Bearer YourAccessToken', // Replace with your authorization token
      'Content-Type': 'application/json' // Specify content type if needed
    }
    try {
      const response = await Axios.post(endpoint+'/api/allcountriesfetch', headers);
  
      // Handle the response data
      setSignupData({ ...signupData, country: response.data.countries[0]?.iso2 });
      setCountry(response.data.countries);
      GetCountryCities(response.data.countries[0]?.id);
    } catch (error) {
      // Handle any errors
      console.error('Error:', error);
    }

    
  };
  const GetCountryCities = async(id) => {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      // 'Authorization': 'Bearer YourAccessToken', // Replace with your authorization token
      'Content-Type': 'application/json' // Specify content type if needed
    }
    const data={
      'id':id
    }
    try {
      const response = await Axios.post(endpoint+'/api/countryCites_apis',data, headers);

      setCity(response.data.country);
      setsignUpCity(response.data.country[0]?.name);
      // setSignupData({ ...signupData, city: response.data.country[0]?.name })
    } catch (error) {
      // Handle any errors
      console.error('Error:', error);
    }

    
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const HandleRegister = async() => {
    if(signupData.fname===''){
      toast.error('Please Enter First Name.', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }else  if(signupData.lname===''){
      toast.error('Please Enter Last Name.', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }else  if(signupData.email===''){
      toast.error('Please Enter Your Email.', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }else if (!isValidEmail(signupData.email)) {
      toast.error('Please Enter a Valid Email.', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    } else  if(signupData.cname===''){
      toast.error('Please Enter Company Name.', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }else  if(signupData.caddress===''){
      toast.error('Please Enter Company Address.', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }else  if(signupData.country===''){
      toast.error('Please Select Country.', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }else  if(signUpCity===''){
      toast.error('Please Select City.', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }else  if(signupData.phno===''){
      toast.error('Please Enter Phone Number.', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }else  if(signupData.zcode===''){
      toast.error('Please Enter Zip Code.', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }else  if(signupData.pass===''){
      toast.error('Please Enter Password.', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    const data={
      'token':'IoOCQ3ObCcqj3BmtYVUBKTaCIWWoFnQcCorlRUsL-peMCb6m7dlEhXnSJhXXEo7Dh8bG7WQq18wbzMIvAKNk2RtIVKSBc3uUgZASa-0DZ0L5oiwJ9rSktbNb1dM3efA-b7BLH97ryRSj8vglisLUecscxtA1OFPF7kYWWaqDSKxovS9yKw4jBhUWwMrYT306oG2UZgmDpxP-zx6hENsrnFrHXtOqO6e5SA6ZdJsbJmOXZxDq5ZOcLdZ6PgzeQVdnivhXQHA8g3gzQoNuhYo4E1UYNOdTYGS16EvMpOUTxfmhmLz1-hw9SPnIiIzOX9K83qEOptngC4ftezuMmw2cFusTrxrKMvbH8SUqKAiywnTuiyV4yunaolsqVwbR-4PyM6FO8usVBMFf49vNBSO0nh-cdb8imZPtqb4xGeGHHIu5mG7uMAKZaJVbXGpC2eZfjab3NGV9Z-fmSmrDdAmO44ew0Xf0ZIXu4UoJx8a7EfGQRwWl51g5ZF93J0Vm',
      'email':signupData.email,
      'password':signupData.pass,
      'title':signupData.title,
      'first_name':signupData.fname,
      'last_name':signupData.lname,
      'company_name':signupData.cname,
      'company_address':signupData.caddress,
      'country':signupData.country,
      'city':signUpCity,
      'phone_no':signupData.phno,
      'zip_code':signupData.zcode,
      'otp_code':'',
    }
    setLoader(true);
    try {
      const response = await Axios.post(endpoint+'/api/register/customer/submit',data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        } ,
      });
      setLoader(false);
     if(response.data.status==='success'){
        setsignUp(!signUp);
        toast.success('You are registered successfully.', {
          position: toast.POSITION.TOP_RIGHT
        });
     }else{
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        })
     }
    } catch (error) {
      console.error('Error:', error);
    };
  };
  const Change = () => {
    setsignUp(!signUp)
  };
  return (
    <>
      <ToastContainer />
      {loader && (<Loading/>)}
      {signUp ? (
        <div id='main-wrapper'>
        <section class='py-5'>
          <div class='container'>
            <div class='row justify-content-center align-items-center m-auto'>
              <div class='col-12'>
                <div class='bg-mode shadow rounded-3 overflow-hidden'>
                  <div class='row g-0'>
                    {/* <div class='col-lg-6 d-flex align-items-center order-2 order-lg-1'>
                      <div class='p-3 p-lg-5'>
                        <img src={login} class='img-fluid' alt='' />
                      </div>
                      <div class='vr opacity-1 d-none d-lg-block'></div>
                    </div> */}

                    <div class=' order-1'>
                      <div class='row p-3 p-sm-4 p-md-5'>
                        <a href='index.html'>
                          <img
                            class='img-fluid mb-4'
                            src={logo}
                            width='200'
                            alt='logo'
                          />
                        </a>
                        <h1 class='mb-2 text-center fs-2'>Create New Account</h1>
                        
                        <div class='col-lg-6  form-floating mb-4'>
                        <select  onChange={handleSignupChange} value={signupData.title} name='title' class="form-control" aria-label="Default select example">
                            <option value="MR">MR.</option>
                            <option value="MRS">MRS.</option>
                            <option value="MISS">MISS.</option>
                          </select>
                            <label style={{left:'auto'}}>
                              Select Title*
                            </label>
                          </div>
                          <div class='col-lg-6  form-floating mb-4'>
                            <input
                              type='text'
                              onChange={handleSignupChange}
                              value={signupData.fname}
                              name='fname'
                              class='form-control'
                              placeholder='Enter Your First Name'
                              required=''
                            />
                            <label style={{left:'auto'}}>First Name*</label>
                          </div>
                        <div class='col-lg-6  form-floating mb-4'>
                            <input
                              type='text'
                              onChange={handleSignupChange}
                              value={signupData.lname}
                              name='lname'
                              class='form-control'
                              placeholder='Enter Your Last Name'
                              required=''
                            />
                            <label style={{left:'auto'}}>Last Name*</label>
                          </div>
                          <div class='col-lg-6  form-floating mb-4'>
                            <input
                              type='email'
                              onChange={handleSignupChange}
                              value={signupData.email}
                              name='email'
                              class='form-control'
                              placeholder='name@example.com'
                              required=''
                            />
                            <label style={{left:'auto'}}>User Email*</label>
                          </div>
                          <div class='col-lg-6  form-floating mb-4'>
                            <input
                              type='text'
                              onChange={handleSignupChange}
                              value={signupData.cname}
                              name='cname'
                              class='form-control'
                              placeholder='Enter Your Company Name'
                              required=''
                            />
                            <label style={{left:'auto'}}>Company Name*</label>
                          </div>
                          <div class='col-lg-6  form-floating mb-4'>
                            <input
                              type='text'
                              onChange={handleSignupChange}
                              value={signupData.caddress}
                              name='caddress'
                              class='form-control'
                              placeholder='Enter Your Company Address'
                              required=''
                            />
                            <label style={{left:'auto'}}>Company Address*</label>
                          </div>
                          <div class='col-lg-6  form-floating mb-4'>
                          <select  onChange={handleSignupChange} value={signupData.country} name='country' class="form-control" aria-label="Default select example">
                          {country.map(item => (
                                    <option key={item.id}  value={item.iso2}>
                                      {item.name}
                                    </option>
                                  ))}
                          </select>
                            <label style={{left:'auto'}}>Select Country*</label>
                          </div>
                          <div class='col-lg-6  form-floating mb-4'>
                          <select value={signUpCity} onChange={handleSignupChangeCity} name='city' class="form-control" aria-label="Default select example">
                          {city.map(item => (
                                    <option key={item.id}  value={item.name}>
                                      {item.name}
                                    </option>
                                  ))}
                          </select>
                            <label style={{left:'auto'}}>Select City*</label>
                          </div>
                          <div class='col-lg-6  form-floating mb-4'>
                            <input
                              type='text'
                              onChange={handleSignupChange}
                              value={signupData.phno}
                              name='phno'
                              class='form-control'
                              placeholder='Enter Your First Name'
                              onInput={(e) => (e.currentTarget.value = e.currentTarget.value .replace(/[^0-9.]/g, "") .replace(/(\..*?)\..*/g, "$1"))}
                              required=''
                            />
                            <label style={{left:'auto'}}>Phone Number*</label>
                          </div>
                        <div class='col-lg-6  form-floating mb-4'>
                             <input
                              type='text'
                              onChange={handleSignupChange}
                              value={signupData.zcode}
                              name='zcode'
                              class='form-control'
                              placeholder='Enter Your First Name'
                              required=''
                            />
                            <label style={{left:'auto'}}>Zip Code*</label>
                          </div>
                          <div class='form-floating mb-4'>
                            <input
                              type='password'
                              onChange={handleSignupChange}
                              value={signupData.pass}
                              name='pass'
                              class='form-control'
                              id='password-field'
                              placeholder='Password'
                              required=''
                            />
                            <label style={{left:'auto'}}>Password</label>
                            <span class='toggle-password position-absolute top-50 end-0 translate-middle-y me-3 fa-regular fa-eye'></span>
                          </div>

                          <div class='form-group'>
                            <button
                              onClick={HandleRegister}
                              type='submit'
                              class='btn btn-primary full-width font--bold btn-lg'
                            >
                              Create Account
                            </button>
                          </div>

                          <div class='modal-flex-item d-flex align-items-center justify-content-between mb-3'>
                           
                          </div>
                     
                        <div class='text-primary-hover mt-3 text-center'>
                          {' '}
                          Copyrights ©2024 globetm.co.uk.{' '}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      ):(
        <div id='main-wrapper'>
        <section class='py-5'>
          <div class='container'>
            <div class='row justify-content-center align-items-center m-auto'>
              <div class='col-12'>
                <div class='bg-mode shadow rounded-3 overflow-hidden'>
                  <div class='row g-0'>
                    <div class='col-lg-6 d-flex align-items-center order-2 order-lg-1'>
                      <div class='p-3 p-lg-5'>
                        <img src={login} class='img-fluid' alt='' />
                      </div>
                      <div class='vr opacity-1 d-none d-lg-block'></div>
                    </div>

                    <div class='col-lg-6 order-1'>
                      <div class='p-3 p-sm-4 p-md-5'>
                        <a href='index.html'>
                          <img
                            class='img-fluid mb-4'
                            src={logo}
                            width='200'
                            alt='logo'
                          />
                        </a>
                        <h1 class='mb-2 fs-2'>Welcome!</h1>
                        <p class='mb-0'>
                          Are you new here?
                          <p
                          onClick={Change}
                         style={{cursor:'pointer'}}
                            class='fw-medium text-primary'
                          >
                            {' '}
                            Create an account
                          </p>
                        </p>
                        <div class='form py-4'>
                          <div class='form-floating mb-4'>
                            <input
                              type='email'
                              onChange={handleChange}
                              name='username'
                              class='form-control'
                              placeholder='name@example.com'
                              required=''
                            />
                            <label>User Name</label>
                          </div>
                          <div class='form-floating mb-4'>
                            <input
                              type='password'
                              onChange={handleChange}
                              name='password'
                              class='form-control'
                              id='password-field'
                              placeholder='Password'
                              required=''
                            />
                            <label>Password</label>
                            <span class='toggle-password position-absolute top-50 end-0 translate-middle-y me-3 fa-regular fa-eye'></span>
                          </div>

                          <div class='form-group'>
                            <button
                              onClick={HandleLogin}
                              type='submit'
                              class='btn btn-primary full-width font--bold btn-lg'
                            >
                              Log In
                            </button>
                          </div>

                          <div class='modal-flex-item d-flex align-items-center justify-content-between mb-3'>
                            {/* <div class="modal-flex-first">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox" id="savepassword" value="option1"/>
                                                    <label class="form-check-label" for="savepassword">Save Password</label>
                                                </div>
                                            </div> */}
                            <div class='modal-flex-last'>
                              <a
                                href='JavaScript:Void(0);'
                                class='text-primary fw-medium'
                              >
                                Forget Password?
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class='text-primary-hover mt-3 text-center'>
                          {' '}
                          Copyrights ©2024 globetm.co.uk.{' '}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      )}
    </>
  )
}

export default Login
Login.propTypes = {
  setToken: PropTypes.func.isRequired
}
