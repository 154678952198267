import React from 'react'
import Layout from '../../Components/Layout/Layout'
import img from './faq.jpg'
function TermsConditions () {
  return (
    <>
      <Layout>
        <div>
          <img src={img} />
        </div>
        <div className='container'>
          <div className='row mt-3 text-center'>
            <h3>Terms & Conditions</h3>
          </div>
          <div className='row mt-4 '>
            <p className='FontSize15'>
              Welcome to <a href='https://globetm.co.uk/'>Globetm</a> We are
              dedicated to offering our customers quality flight services and
              support. Before you use our website and services, please read
              these Terms and Conditions ("Terms", "Terms and Conditions")
              carefully. These Terms apply to all visitors, users, and others
              who wish to access or use our Service.
            </p>
            <p className='mt-2'>
              By accessing or using the Service, you agree to be bound by these
              Terms. If you disagree with any part of the terms, then you do not
              have permission to access the Service.
            </p>
          </div>
          <div className='row mt-3 '>
            <h4>1-Using Our Service</h4>
            <p className='mt-2'>
              Globetm provides users with access to flight booking services,
              including flight comparisons, reservations, and customer support.
              You agree to use our service for lawful purposes only and in
              compliance with these Terms
            </p>
          </div>
          <div className='row mt-3 '>
            <h4>2-Accounts</h4>
            <p className='mt-2'>
              When you create an account with us, you guarantee that the
              information you provide is accurate, complete, and current at all
              times. Inaccurate, incomplete, or obsolete information may result
              in the immediate termination of your account on our Service.
            </p>
          </div>
          <div className='row mt-3 '>
            <h4>3-Intellectual Property</h4>
            <p className='mt-2'>
              The Service and its original content, features, and functionality
              are and will remain the exclusive property of [Your Company Name]
              and its licensors. Our trademarks and trade dress may not be used
              in connection with any product or service without the prior
              written consent of Globetm{' '}
            </p>
          </div>
          <div className='row mt-3 '>
            <h4>4-Links To Other Web Sites</h4>
            <p className='mt-2'>
              Our Service may contain links to third-party web sites or services
              that are not owned or controlled by Globetm.{' '}
            </p>
          </div>
          <div className='row mt-3 '>
            <h4>5-Links To Other Web Sites</h4>
            <p className='mt-2'>
              Our Service may contain links to third-party web sites or services
              that are not owned or controlled by Globetm.Globetm has no control
              over, and assumes no responsibility for, the content, privacy
              policies, or practices of any third-party web sites or services.
              We do not warrant the offerings of any of these
              entities/individuals or their websites.{' '}
            </p>
          </div>
          <div className='row mt-3 '>
            <h4>6-Termination</h4>
            <p className='mt-2'>
              We may terminate or suspend your account and bar access to the
              Service immediately, without prior notice or liability, under our
              sole discretion, for any reason whatsoever and without limitation,
              including but not limited to a breach of the Terms.
            </p>
          </div>
          <div className='row mt-3 '>
            <h4>7-Limitation Of Liability</h4>
            <p className='mt-2'>
              In no event shall Globetm nor its directors, employees, partners,
              agents, suppliers, or affiliates, be liable for any indirect,
              incidental, special, consequential or punitive damages, including
              without limitation, loss of profits, data, use, goodwill, or other
              intangible losses, resulting from (i) your access to or use of or
              inability to access or use the Service; (ii) any conduct or
              content of any third party on the Service; (iii) any content
              obtained from the Service; and (iv) unauthorized access, use or
              alteration of your transmissions or content, whether based on
              warranty, contract, tort (including negligence) or any other legal
              theory, whether or not we have been informed of the possibility of
              such damage, and even if a remedy set forth herein is found to
              have failed its essential purpose.
            </p>
          </div>
          <div className='row mt-3 '>
            <h4>8-Disclaimer</h4>
            <p className='mt-2'>
              Your use of the Service is at your sole risk. The Service is
              provided on an "AS IS" and "AS AVAILABLE" basis. The Service is
              provided without warranties of any kind, whether express or
              implied, including, but not limited to, implied warranties of
              merchantability, fitness for a particular purpose,
              non-infringement, or course of performance.
            </p>
          </div>
          <div className='row mt-3 '>
            <h4>9-Changes To Terms</h4>
            <p className='mt-2'>
              We reserve the right, at our sole discretion, to modify or replace
              these Terms at any time. If a revision is material, we will
              provide at least 15 days' notice prior to any new terms taking
              effect. What constitutes a material change will be determined at
              our sole discretion.
            </p>
          </div>
          <div className='row mt-3 mb-3'>
            <h4>10-Contact Us</h4>
            <p className='mt-2'>
              If you have any questions about these Terms, please contact us at
              +44 7703 399669.
            </p>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default TermsConditions
