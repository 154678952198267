import React, { useState } from 'react'
import Layout from '../../Components/Layout/Layout'
import { Accordion } from 'rsuite';
import img from './faq.jpg'
function FaqPage () {
  return (
    <>
    <Layout>
      <div>
        <img src={img}/>
      </div>
      <div className='container'>
      <section id='section-7 mt-5'>
        <h4 className='mt-5 flight-heading '>Frequently Asked Questions (FAQs)</h4>
        <Accordion>
    <Accordion.Panel header="How do I make a flight booking on your website?" defaultExpanded>
      <p>To book a flight, start by entering your travel details in the search box, including your departure and arrival cities, dates, and the number of passengers. Once you hit search, you'll be presented with a list of available flights. Choose the one that best fits your needs and follow the on-screen instructions to complete your booking.</p>
    </Accordion.Panel>
    <Accordion.Panel header="Can I book a one-way or round-trip flight?">
      <p>Yes, you can book both one-way and round-trip flights with us. Simply select your preferred option in the search box before conducting your search.</p>
    </Accordion.Panel>
    <Accordion.Panel header="How do I know my booking is confirmed?">
      <p>After you complete your booking, you will receive a confirmation email that includes your flight details and booking reference number. Please ensure that your email address is correct when booking. If you don't receive a confirmation, please contact our customer support team.</p>
    </Accordion.Panel>
    <Accordion.Panel header="Can I cancel or change my flight booking?">
      <p>Yes, bookings can be canceled or changed, but our ability to do so without a charge will depend on the airline's policy, the type of ticket you purchased, and how close to the departure date you are making the change or cancellation. Please refer to our cancellation policy or contact customer service for more information.</p>
    </Accordion.Panel>
    <Accordion.Panel header="Is it possible to book flights for someone else?">
      <p>Absolutely. Just enter the details of the person(s) flying when prompted during the booking process. Ensure that the passenger's name matches the ID they will use at the airport.</p>
    </Accordion.Panel>
    <Accordion.Panel header="What payment methods do you accept?">
      <p>We accept various payment methods including credit cards, debit cards, and some digital wallets. The available options will be displayed at checkout.</p>
    </Accordion.Panel>
    <Accordion.Panel header="How do I use a promo code?">
      <p>If you have a promo code, you can enter it in the designated box before completing your booking. If the promo code is valid, the discount will be applied to your booking total.</p>
    </Accordion.Panel>
    <Accordion.Panel header="What should I do if I don’t receive a booking confirmation?">
      <p>First, check your email spam or junk folder. If you still can't find the confirmation, please contact our customer support team with your booking details, and we'll assist you.</p>
    </Accordion.Panel>
    <Accordion.Panel header="Do you offer group discounts?">
      <p>Yes, we offer discounts for group bookings depending on the size of the group and the specifics of the trip. Please contact our customer support team for more information on group bookings.</p>
    </Accordion.Panel>
    <Accordion.Panel header="How can I request special assistance?">
      <p>If you or someone you're booking for requires special assistance (such as wheelchair access, assistance for visually impaired passengers, etc.), please contact us after booking your flight so we can make the necessary arrangements with the airline.</p>
    </Accordion.Panel>
    <Accordion.Panel header="How can I stay updated on changes to my flight?">
      <p>We recommend providing a valid contact number and email during booking, as this will be used by us and the airline to inform you of any changes. You can also check the status of your flight on our website using your booking reference number.</p>
    </Accordion.Panel>
  </Accordion>
      </section>
      </div>
      </Layout>
    </>
  )
}

export default FaqPage
