import React,{useEffect} from 'react';
import sideimg from '../../Images/side-3.png';
import Layout from '../../Components/Layout/Layout';
import wow from 'wowjs';

function About ({onLogout}) {

useEffect(()=>{
    new wow.WOW().init();
},[]);

  return (
    <>
      <Layout logout={onLogout}>
      {/* -- ============================ Booking Title ==================================  */}
		<section class="bg-cover position-relative about_us_bg"  data-overlay="5">
			<div class="container">
				<div class="row align-items-center justify-content-center">
					<div class="col-xl-7 col-lg-9 col-md-12">

						<div class="fpc-capstion text-center my-4">
							<div class="fpc-captions">
								<h1 class="xl-heading text-light">About Globetm</h1>

							</div>
						</div>

					</div>
				</div>
			</div>
			<div class="fpc-banner"></div>
		</section>

    {/* <!-- ============================ About Us Section ================================== --> */}
		<section>
			<div class="container">

				<div class="row align-items-center justify-content-between g-2">

					<div class="col-xl-12 col-lg-12 col-md-12">
						<p className='mt-2 mb-2 text-center'>Welcome to Globe Travel Management , your reliable companion in the skies. Since our inception in 2015, we've dedicated ourselves to making travel more accessible, affordable, and enjoyable for people around the globe. Based in United Kingdom, we've grown from a small startup into a trusted name in the flight booking industry, serving millions of customers on their journeys to destinations far and wide.</p>
						<div class="">
							<h3 class="lh-base  fw-bold">Who We're & Mission?</h3>
							<p>At Globetm, our mission is simple: to connect you with the world, one flight at a time. We believe that travel should be for everyone—a doorway to new experiences, cultures, and memories. That's why we work tirelessly to provide you with comprehensive flight options, competitive prices, and an easy-to-use platform that makes booking your next adventure a breeze.</p>
							
						</div>
						<div class="mt-2">
							<h3 class="lh-base  fw-bold">Why Choose Us?</h3>
							<p>A World of Flights With partnerships with hundreds of airlines globally, we offer an extensive selection of flights to destinations worldwide. Whether you're planning a quick domestic trip or an exotic international escape, we have you covered.</p>
							
						</div>
						<div class="mt-2">
							<h3 class="lh-base  fw-bold">Unbeatable Deals</h3>
							<p>We understand the value of a great deal. Our team is always on the lookout for the best prices and exclusive offers to ensure you get the most out of your travel budget.</p>
							
						</div>
						<div class="mt-2">
							<h3 class="lh-base  fw-bold">Customer-Centric Service</h3>
							<p>Your satisfaction is at the heart of everything we do. Our dedicated customer support team is here to assist you before, during, and after your booking. Whether you have a question, need to make changes to your flight, or require special assistance, we're just a call or click away.</p>
							
						</div>
						<div class="mt-2">
							<h3 class="lh-base  fw-bold">Hassle-Free Booking Experience</h3>
							<p>We've designed our website with you in mind. Our platform is intuitive and user-friendly, allowing you to search, compare, and book flights with ease. Plus, our secure payment system gives you peace of mind.</p>
							<p className='mt-2'>oday, we continue to innovate and evolve, driven by our passion for travel and commitment to providing exceptional service. We're not just about flights; we're about creating connections—bringing people together and making the world a smaller, more accessible place.</p>
						</div>
						<div class="mt-2">
							<h3 class="lh-base  fw-bold">Join Us on the Journey</h3>
							<p>Ready to take off? With [Your Company Name], your next adventure is just a few clicks away. Explore the world with us, and discover the ease and convenience of booking your flights with a partner who cares.</p>
							<p className='mt-2'>Thank you for choosing Globetm. Here's to many journeys together!</p>
						</div>
					</div>
				</div>

			</div>
		</section>
      </Layout>
    </>
  )
}

export default About
