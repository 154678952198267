import React, { useState, useEffect } from 'react'
import tr1 from '../../Images/destination/tr-1.jpg'
import tr2 from '../../Images/destination/tr-2.jpg'
import tr3 from '../../Images/destination/tr-10.jpg'
import tr4 from '../../Images/destination/tr-4.jpg'
import london from '../../Images/destination/London.jpg'
import tr6 from '../../Images/destination/tr-6.jpg'
import tr7 from '../../Images/destination/tr-7.jpg'
import jeddah from '../../Images/destination/jeddah.jpg'
import khi from '../../Images/destination/karachi.jpg'
import lhe from '../../Images/destination/lahore.jpg'
import madinah from '../../Images/destination/madinah.jpg'
import maxico from '../../Images/destination/maxico.jpeg'
import moment from 'moment/moment'
import Loading from '../Loading/Loader'
import { Modal, ModalBody } from 'reactstrap'
import {
  OneWayFlightIndexSearchData,
  OneWayFlightSearchData
} from '../../Redux/Actions/actions'
import { Hotelapitoken, ApiEndPoint } from '../GlobalData/GlobalData'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import { airportcode } from '../Data/AirportCodes'
import Axios from 'axios'
function InternationalRoutes () {
  var token = Hotelapitoken()
  var apiendpoint = ApiEndPoint()
  const navigation = useNavigate()
  const Dispatch = useDispatch()
  const Airportcode = airportcode.items
  const [isLoading, setIsLoading] = useState(false)
  const [userLocation, setUserLocation] = useState([])
  const [arivalAirpotrs, setArivalAirpotrs] = useState([])
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    GetFlighMarkup()
    getUserLocation()
  }, [])

  function getUserLocation () {
    // Fetch user's location data
    fetch(
      'https://api.geoapify.com/v1/ipinfo?&apiKey=0708571ae58c4688824a7d01ca397041'
    )
      .then(response => response.json())
      .then(data => {
        setUserLocation(data)
        // Handle location data as needed
      })
      .catch(error => {
        console.error('Error fetching user location:', error)
      })
  }

  // **********Flight Section*************

  const GetFlighMarkup = async () => {
    var data = {
      token: token
    }
    try {
      const response = await Axios.post(
        apiendpoint + '/api/get_markup_flight_new_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      localStorage.setItem('FlightMarkup', JSON.stringify(response.data))
    } catch (error) {
      console.error('Error', error)
    }
  }

  const OneWayapicall = async arrival => {
    debugger
    var Location = userLocation.city.name;
    
    const UserAirport = Airportcode.filter(option =>
      option.cityName.toLowerCase().includes(Location.toLowerCase())
    )
    if (UserAirport.length === 0) {
      return
    };
    if(UserAirport[0].airportCode ===arrival){
        toast.warning('Please Dont`t select Same Destination', {
            position: toast.POSITION.TOP_RIGHT
          })
        return;
    }
    const random15DigitNumber = generateRandomNumber()
    const currentDate = new Date()
    const twoDaysLater = new Date(currentDate)
    twoDaysLater.setDate(currentDate.getDate() + 2)
    const data = {
      token_authorization: token,
      case: 'search_flights',
      MaxStopsQuantity: 'All',
      DepartureDate: moment(twoDaysLater).format('YYYY-MM-DD'),
      DepartureCode: UserAirport[0].airportCode,
      ArrivalCode: arrival,
      AirTripType: 'OneWay',
      AirlinesCode: 'EK',
      adult: 1,
      child: 0,
      infant: 0,
      ConversationId: random15DigitNumber,
      CabinType: 'no'
    }
    Dispatch(OneWayFlightIndexSearchData(data))
    setIsLoading(true)
    try {
      const response = await Axios.post(
        apiendpoint + '/api/flight_search_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setIsLoading(false)
      if (response.data.Success === false) {
        toast.warning(response.data.Message, {
          position: toast.POSITION.TOP_RIGHT
        })
        return
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        Dispatch(OneWayFlightSearchData(response.data.Data))
        sessionStorage.setItem('15digitnumber', random15DigitNumber.toString())
        navigation('/Flight_search')
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error:', error)
    }
  }

  function generateRandomNumber () {
    const min = Math.pow(10, 14)
    const max = Math.pow(10, 15) - 1
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  const OpenModal = data => {
    if (data !== '') {
      const UserAirport = Airportcode.filter(option =>
        option.cityName.toLowerCase().includes(data.toLowerCase())
      )
      setArivalAirpotrs(UserAirport)
    }

    setIsOpen(!isOpen)
  }
  return (
    <>
      {isLoading && <Loading />}
      <ToastContainer/>
      <Modal
        isOpen={isOpen}
        className='Airport-Modal'
        toggle={() => OpenModal('')}
      >
        <ModalBody>
          <h4 className='p-3'>Where you want to go?</h4>
          <div className='row'>
            {arivalAirpotrs.map((item, index) => (
              <div
                key={index}
                data-testid='box-2'
                class='sc-aXZVg p-2 col-md-4 col-lg-4 sc-JrDLc   csbhqS'
              >
                <div
                  onClick={() => OneWayapicall(item.airportCode)}
                  class='sc-aXZVg sc-jEACwC sc-cMa-dbN kqMasH justify-content-around jjRHoq Pgbli sc-c30427c5-0 gBuBCo'
                  color='background.lightest'
                  data-testid='action-card'
                  role='link'
                  tabindex='0'
                >
                  <div class='sc-aXZVg ldwMXy'>
                    <div
                      color='primary.light'
                      height='48'
                      width='48'
                      class='sc-aXZVg sc-eqUAAy TZuCs cgaCzu'
                    >
                      <svg
                        width='1.5em'
                        height='1.5em'
                        viewBox='0 0 24 24'
                        xmlns='http://www.w3.org/2000/svg'
                        focusable='false'
                        aria-hidden='true'
                        role='img'
                      >
                        <g fill='none' fill-rule='evenodd'>
                          <path
                            d='M22.15.5c.31.004.617.07.902.197a.5.5 0 01.254.254c.126.285.193.591.198.881l-.012.418c-.077 1.384-.803 2.627-2.142 4.081l-.321.341-.68.69-2.084 
                                                      2.041.428 1.41c.45-.157.968-.103 1.412.186l.127.092 1.397 1.397a1.5 1.5 0 01.386 1.123c-.027.36-.184.696-.411.925l-.102.092-1.269 1.268 1.335 
                                                      4.404c.124.415.037.861-.23 1.2l-.096.108-.543.543a1.12 1.12 0 01-1.734-.146l-.065-.112-4.641-8.528-.625.625-1.263 1.249a20.596 20.596 0 01-2.67 
                                                      2.359l.348 4.502a.836.836 0 01-.162.517l-.075.09-.54.54a.87.87 0 01-1.327-.115l-.063-.103-1.734-3.45a1.66 1.66 0 01-1.125-.208l-.142-.095-.084-.078a1.666 
                                                      1.666 0 01-.374-1.344L.967 16.117a.87.87 0 01-.29-1.298l.078-.088.555-.554a.835.835 0 01.499-.218l.12-.003 4.478.355a20.52 20.52 0 011.94-2.245l.426-.413 
                                                      1.887-1.898L2.123 5.11a1.12 1.12 0 01-.574-.7l-.028-.133a1.12 1.12 0 01.24-.874l.091-.1.547-.546a1.32 1.32 0 011.165-.355l.14.034 4.402 1.333L9.34 2.54a1.5 1.5 0 
                                                      012.068-.251l.124.103 1.422 1.426a1.57 1.57 0 01.238 1.494l1.419.43 2.157-2.214.599-.59.45-.423C19.273 1.19 20.38.574 21.726.511L22.15.5z'
                            fill='#0068ef'
                          ></path>
                          <path
                            d='M8.75 18.262l.293 3.8-.352.352-1.6-3.184c.096-.045.193-.092.29-.142.311-.173.618-.352.92-.54l.449-.286zm8.709-8.07L20.61 20.59l.013.067a.32.32 0 
                                                      01-.048.194l-.043.054-.547.547-.035.027-.037.014-.039.001-.036-.012-.03-.022-.013-.014-4.798-8.816 1.42-1.413 1.042-1.024zM1.941 
                                                      14.958l3.798.304c-.198.299-.388.603-.57.912l-.268.468-.13.267-3.182-1.599.352-.352zM3.347 3.38l.068.013 10.411 3.154-1.236 1.27-1.195 
                                                      1.2-8.807-4.792-.04-.027a.115.115 0 01-.038-.07.12.12 0 01.013-.073l.022-.03.557-.557.053-.041a.32.32 0 01.192-.047z'
                            fill='#e8f2ff'
                          ></path>
                          <path
                            d='M22.141 1.501l.136.01.128.021.051.014.027.111.014.103.006.105-.01.316c-.066 1.051-.618 2.053-1.664 3.236l-.216.239-.41.433-.558.562-4.126 4.046-3.257 
                                                      3.246-.603.594-.386.394a19.638 19.638 0 01-4.366 3.275l-.225.111-.234.104-.356.14-.113.027a.67.67 0 01-.318-.02l-.096-.04-.039-.025-.028-.038a.66.66 0 
                                                      01-.04-.602c.102-.274.208-.517.327-.753a19.59 19.59 0 013.292-4.359l.395-.383 4.005-4.026.509-.518 3.371-3.471.236-.237.227-.226.436-.417.244-.225c1.203-1.09 
                                                      2.117-1.621 3.122-1.725l.202-.015.317-.007zm-2.66 10.28l.091.058 1.333 1.339.042.056a.511.511 0 01-.054.595l-.059.057-.93.926-.921-3.04a.57.57 0 01.498.01zm-8.704-8.717l.065.05 
                                                      1.363 1.364.042.07c.051.108.06.245.022.376l-.036.096-3.042-.922.891-.89.074-.078a.504.504 0 01.312-.145.5.5 0 01.31.08zm10.862-.737a1.186 1.186 0 00-1.679 0 .275.275 0 00-.001.388.27.27 
                                                      0 00.384.001.645.645 0 01.745-.12l.088.052.077.065.033.044a.644.644 0 010 .914.275.275 0 00-.002.387.27.27 0 00.384.003 1.194 1.194 0 00.168-1.484l-.078-.11-.088-.098-.031-.042z'
                            fill='#fff'
                          ></path>
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div width='100%' class='sc-aXZVg sc-eqUAAy feckNt dFCDBu'>
                    <div class='sc-aXZVg sc-eqUAAy gCbQdB heAqDO'>
                      <div
                        font-weight='700'
                        font-size='14px'
                        class='sc-fqkvVR sc-fUBkdm dxpPZm btsJdq'
                      >
                        {item.airportCode}-{item.airportName}, {item.cityName}
                      </div>
                    </div>
                  </div>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    height='20'
                    width='20'
                    title='ArrowRight'
                    aria-hidden='true'
                    fill='currentcolor'
                    color='primary'
                    tabindex='-1'
                    focusable='false'
                    role='img'
                    class='Svg-sc-12lgb6u-0 iJgYGn ArrowRight__SvgArrowRight-sc-i9is6p-0 sc-hBtRBD cpzuBV kOTrwD'
                  >
                    <title>ArrowRight</title>
                    <path d='M12 4l-1.4 1.4 5.6 5.6H4v2h12.2l-5.6 5.6L12 20l8-8-8-8z'></path>
                  </svg>
                </div>
              </div>
            ))}

            <button className='btn btn-primary' onClick={() => OpenModal('')}>
              Close
            </button>
          </div>
        </ModalBody>
      </Modal>
      {/* <!-- ============================ Popular Routes Design Start ================================== --> */}
      <section class='gray-simple'>
        <div class='container'>
          <div class='row align-items-center justify-content-center'>
            <div class='col-xl-8 col-lg-9 col-md-11 col-sm-12'>
              <div class='secHeading-wrap text-center mb-5'>
                <h2>Hot International Routes</h2>
                <p>
                  Cicero famously orated against his political opponent Lucius
                  Sergius Catilina.
                </p>
              </div>
            </div>
          </div>

          <div class='row justify-content-center gy-4 gx-3'>
            <div class='col-xl-3 col-lg-4 col-md-6 col-sm-6'>
              <div onClick={()=>OpenModal('Dubai')} class='pop-touritem'>
                <div class='card rounded-3 h-100 m-0'>
                  <div class='flight-thumb-wrapper'>
                    <div class='popFlights-item-overHidden'>
                      <img src={tr1} class='img-fluid' alt='' />
                    </div>
                  </div>
                  <div class='touritem-middle position-relative p-3'>
                    <div class='touritem-flexxer '>
                      <h4 class='city fs-6 m-0 fw-bold'>
                        <span>{userLocation.length===0?'':userLocation.city.name}</span>
                        <span class='svg-icon svg-icon-muted svg-icon-2hx px-1'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M17.4 7H4C3.4 7 3 7.4 3 8C3 8.6 3.4 9 4 9H17.4V7ZM6.60001 15H20C20.6 15 21 15.4 21 16C21 16.6 20.6 17 20 17H6.60001V15Z'
                              fill='currentColor'
                            />
                            <path
                              opacity='0.3'
                              d='M17.4 3V13L21.7 8.70001C22.1 8.30001 22.1 7.69999 21.7 7.29999L17.4 3ZM6.6 11V21L2.3 16.7C1.9 16.3 1.9 15.7 2.3 15.3L6.6 11Z'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                        <span>Dubai</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-xl-3 col-lg-4 col-md-6 col-sm-6'>
              <div onClick={()=>OpenModal('New York')} class='pop-touritem'>
                <div class='card rounded-3 h-100 m-0'>
                  <div class='flight-thumb-wrapper'>
                    <div class='popFlights-item-overHidden'>
                      <img src={tr2} class='img-fluid' alt='' />
                    </div>
                  </div>
                  <div class='touritem-middle position-relative p-3'>
                    <div class='touritem-flexxer'>
                      <h4 class='city fs-6 m-0 fw-bold'>
                        <span>{userLocation.length===0?'':userLocation.city.name}</span>
                        <span class='svg-icon svg-icon-muted svg-icon-2hx px-1'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M17.4 7H4C3.4 7 3 7.4 3 8C3 8.6 3.4 9 4 9H17.4V7ZM6.60001 15H20C20.6 15 21 15.4 21 16C21 16.6 20.6 17 20 17H6.60001V15Z'
                              fill='currentColor'
                            />
                            <path
                              opacity='0.3'
                              d='M17.4 3V13L21.7 8.70001C22.1 8.30001 22.1 7.69999 21.7 7.29999L17.4 3ZM6.6 11V21L2.3 16.7C1.9 16.3 1.9 15.7 2.3 15.3L6.6 11Z'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                        <span>New York</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-xl-3 col-lg-4 col-md-6 col-sm-6'>
              <div onClick={()=>OpenModal('Paris')} class='pop-touritem'>
                <div class='card rounded-3 h-100 m-0'>
                  <div class='flight-thumb-wrapper'>
                    <div class='popFlights-item-overHidden'>
                      <img src={tr3} class='img-fluid' alt='' />
                    </div>
                  </div>
                  <div class='touritem-middle position-relative p-3'>
                    <div class='touritem-flexxer'>
                      <h4 class='city fs-6 m-0 fw-bold'>
                        <span>{userLocation.length===0?'':userLocation.city.name}</span>
                        <span class='svg-icon svg-icon-muted svg-icon-2hx px-1'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M17.4 7H4C3.4 7 3 7.4 3 8C3 8.6 3.4 9 4 9H17.4V7ZM6.60001 15H20C20.6 15 21 15.4 21 16C21 16.6 20.6 17 20 17H6.60001V15Z'
                              fill='currentColor'
                            />
                            <path
                              opacity='0.3'
                              d='M17.4 3V13L21.7 8.70001C22.1 8.30001 22.1 7.69999 21.7 7.29999L17.4 3ZM6.6 11V21L2.3 16.7C1.9 16.3 1.9 15.7 2.3 15.3L6.6 11Z'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                        <span>Paris</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-xl-3 col-lg-4 col-md-6 col-sm-6'>
              <div onClick={()=>OpenModal('London')} class='pop-touritem'>
                <div class='card rounded-3 h-100 m-0'>
                  <div class='flight-thumb-wrapper'>
                    <div class='popFlights-item-overHidden'>
                      <img src={london} class='img-fluid' alt='' />
                    </div>
                  </div>
                  <div class='touritem-middle position-relative p-3'>
                    <div class='touritem-flexxer'>
                      <h4 class='city fs-6 m-0 fw-bold'>
                        <span>{userLocation.length===0?'':userLocation.city.name}</span>
                        <span class='svg-icon svg-icon-muted svg-icon-2hx px-1'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M17.4 7H4C3.4 7 3 7.4 3 8C3 8.6 3.4 9 4 9H17.4V7ZM6.60001 15H20C20.6 15 21 15.4 21 16C21 16.6 20.6 17 20 17H6.60001V15Z'
                              fill='currentColor'
                            />
                            <path
                              opacity='0.3'
                              d='M17.4 3V13L21.7 8.70001C22.1 8.30001 22.1 7.69999 21.7 7.29999L17.4 3ZM6.6 11V21L2.3 16.7C1.9 16.3 1.9 15.7 2.3 15.3L6.6 11Z'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                        <span>London</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-xl-3 col-lg-4 col-md-6 col-sm-6'>
              <div onClick={()=>OpenModal('Tokyo')} class='pop-touritem'>
                <div class='card rounded-3 h-100 m-0'>
                  <div class='flight-thumb-wrapper'>
                    <div class='popFlights-item-overHidden'>
                      <img src={tr4} class='img-fluid' alt='' />
                    </div>
                  </div>
                  <div class='touritem-middle position-relative p-3'>
                    <div class='touritem-flexxer'>
                      <h4 class='city fs-6 m-0 fw-bold'>
                        <span>{userLocation.length===0?'':userLocation.city.name}</span>
                        <span class='svg-icon svg-icon-muted svg-icon-2hx px-1'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M17.4 7H4C3.4 7 3 7.4 3 8C3 8.6 3.4 9 4 9H17.4V7ZM6.60001 15H20C20.6 15 21 15.4 21 16C21 16.6 20.6 17 20 17H6.60001V15Z'
                              fill='currentColor'
                            />
                            <path
                              opacity='0.3'
                              d='M17.4 3V13L21.7 8.70001C22.1 8.30001 22.1 7.69999 21.7 7.29999L17.4 3ZM6.6 11V21L2.3 16.7C1.9 16.3 1.9 15.7 2.3 15.3L6.6 11Z'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                        <span>Tokyo</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-xl-3 col-lg-4 col-md-6 col-sm-6'>
              <div onClick={()=>OpenModal('Istanbul')} class='pop-touritem'>
                <div class='card rounded-3 h-100 m-0'>
                  <div class='flight-thumb-wrapper'>
                    <div class='popFlights-item-overHidden'>
                      <img src={tr6} class='img-fluid' alt='' />
                    </div>
                  </div>
                  <div class='touritem-middle position-relative p-3'>
                    <div class='touritem-flexxer'>
                      <h4 class='city fs-6 m-0 fw-bold'>
                        <span>{userLocation.length===0?'':userLocation.city.name}</span>
                        <span class='svg-icon svg-icon-muted svg-icon-2hx px-1'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M17.4 7H4C3.4 7 3 7.4 3 8C3 8.6 3.4 9 4 9H17.4V7ZM6.60001 15H20C20.6 15 21 15.4 21 16C21 16.6 20.6 17 20 17H6.60001V15Z'
                              fill='currentColor'
                            />
                            <path
                              opacity='0.3'
                              d='M17.4 3V13L21.7 8.70001C22.1 8.30001 22.1 7.69999 21.7 7.29999L17.4 3ZM6.6 11V21L2.3 16.7C1.9 16.3 1.9 15.7 2.3 15.3L6.6 11Z'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                        <span>Istanbul</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-xl-3 col-lg-4 col-md-6 col-sm-6'>
              <div onClick={()=>OpenModal('Beijing')} class='pop-touritem'>
                <div class='card rounded-3 h-100 m-0'>
                  <div class='flight-thumb-wrapper'>
                    <div class='popFlights-item-overHidden'>
                      <img src={tr7} class='img-fluid' alt='' />
                    </div>
                  </div>
                  <div class='touritem-middle position-relative p-3'>
                    <div class='touritem-flexxer'>
                      <h4 class='city fs-6 m-0 fw-bold'>
                        <span>{userLocation.length===0?'':userLocation.city.name}</span>
                        <span class='svg-icon svg-icon-muted svg-icon-2hx px-1'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M17.4 7H4C3.4 7 3 7.4 3 8C3 8.6 3.4 9 4 9H17.4V7ZM6.60001 15H20C20.6 15 21 15.4 21 16C21 16.6 20.6 17 20 17H6.60001V15Z'
                              fill='currentColor'
                            />
                            <path
                              opacity='0.3'
                              d='M17.4 3V13L21.7 8.70001C22.1 8.30001 22.1 7.69999 21.7 7.29999L17.4 3ZM6.6 11V21L2.3 16.7C1.9 16.3 1.9 15.7 2.3 15.3L6.6 11Z'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                        <span>Beijing</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-xl-3 col-lg-4 col-md-6 col-sm-6'>
              <div onClick={()=>OpenModal('Lahore')} class='pop-touritem'>
                <div class='card rounded-3 h-100 m-0'>
                  <div class='flight-thumb-wrapper'>
                    <div class='popFlights-item-overHidden'>
                      <img src={lhe} class='img-fluid' alt='' />
                    </div>
                  </div>
                  <div class='touritem-middle position-relative p-3'>
                    <div class='touritem-flexxer'>
                      <h4 class='city fs-6 m-0 fw-bold'>
                        <span>{userLocation.length===0?'':userLocation.city.name}</span>
                        <span class='svg-icon svg-icon-muted svg-icon-2hx px-1'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M17.4 7H4C3.4 7 3 7.4 3 8C3 8.6 3.4 9 4 9H17.4V7ZM6.60001 15H20C20.6 15 21 15.4 21 16C21 16.6 20.6 17 20 17H6.60001V15Z'
                              fill='currentColor'
                            />
                            <path
                              opacity='0.3'
                              d='M17.4 3V13L21.7 8.70001C22.1 8.30001 22.1 7.69999 21.7 7.29999L17.4 3ZM6.6 11V21L2.3 16.7C1.9 16.3 1.9 15.7 2.3 15.3L6.6 11Z'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                        <span> Lahore</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-xl-3 col-lg-4 col-md-6 col-sm-6'>
              <div onClick={()=>OpenModal('Jeddah')} class='pop-touritem'>
                <div class='card rounded-3 h-100 m-0'>
                  <div class='flight-thumb-wrapper'>
                    <div class='popFlights-item-overHidden'>
                      <img src={jeddah} class='img-fluid' alt='' />
                    </div>
                  </div>
                  <div class='touritem-middle position-relative p-3'>
                    <div class='touritem-flexxer'>
                      <h4 class='city fs-6 m-0 fw-bold'>
                        <span>{userLocation.length===0?'':userLocation.city.name}</span>
                        <span class='svg-icon svg-icon-muted svg-icon-2hx px-1'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M17.4 7H4C3.4 7 3 7.4 3 8C3 8.6 3.4 9 4 9H17.4V7ZM6.60001 15H20C20.6 15 21 15.4 21 16C21 16.6 20.6 17 20 17H6.60001V15Z'
                              fill='currentColor'
                            />
                            <path
                              opacity='0.3'
                              d='M17.4 3V13L21.7 8.70001C22.1 8.30001 22.1 7.69999 21.7 7.29999L17.4 3ZM6.6 11V21L2.3 16.7C1.9 16.3 1.9 15.7 2.3 15.3L6.6 11Z'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                        <span> Jeddah</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-xl-3 col-lg-4 col-md-6 col-sm-6'>
              <div onClick={()=>OpenModal('Karachi')} class='pop-touritem'>
                <div class='card rounded-3 h-100 m-0'>
                  <div class='flight-thumb-wrapper'>
                    <div class='popFlights-item-overHidden'>
                      <img src={khi} class='img-fluid' alt='' />
                    </div>
                  </div>
                  <div class='touritem-middle position-relative p-3'>
                    <div class='touritem-flexxer'>
                      <h4 class='city fs-6 m-0 fw-bold'>
                        <span>{userLocation.length===0?'':userLocation.city.name}</span>
                        <span class='svg-icon svg-icon-muted svg-icon-2hx px-1'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M17.4 7H4C3.4 7 3 7.4 3 8C3 8.6 3.4 9 4 9H17.4V7ZM6.60001 15H20C20.6 15 21 15.4 21 16C21 16.6 20.6 17 20 17H6.60001V15Z'
                              fill='currentColor'
                            />
                            <path
                              opacity='0.3'
                              d='M17.4 3V13L21.7 8.70001C22.1 8.30001 22.1 7.69999 21.7 7.29999L17.4 3ZM6.6 11V21L2.3 16.7C1.9 16.3 1.9 15.7 2.3 15.3L6.6 11Z'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                        <span> Karachi</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-xl-3 col-lg-4 col-md-6 col-sm-6'>
              <div onClick={()=>OpenModal('Madinah')} class='pop-touritem'>
                <div class='card rounded-3 h-100 m-0'>
                  <div class='flight-thumb-wrapper'>
                    <div class='popFlights-item-overHidden'>
                      <img src={madinah} class='img-fluid' alt='' />
                    </div>
                  </div>
                  <div class='touritem-middle position-relative p-3'>
                    <div class='touritem-flexxer'>
                      <h4 class='city fs-6 m-0 fw-bold'>
                        <span>{userLocation.length===0?'':userLocation.city.name}</span>
                        <span class='svg-icon svg-icon-muted svg-icon-2hx px-1'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M17.4 7H4C3.4 7 3 7.4 3 8C3 8.6 3.4 9 4 9H17.4V7ZM6.60001 15H20C20.6 15 21 15.4 21 16C21 16.6 20.6 17 20 17H6.60001V15Z'
                              fill='currentColor'
                            />
                            <path
                              opacity='0.3'
                              d='M17.4 3V13L21.7 8.70001C22.1 8.30001 22.1 7.69999 21.7 7.29999L17.4 3ZM6.6 11V21L2.3 16.7C1.9 16.3 1.9 15.7 2.3 15.3L6.6 11Z'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                        <span> Madinah</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-xl-3 col-lg-4 col-md-6 col-sm-6'>
              <div onClick={()=>OpenModal('Mexico City')} class='pop-touritem'>
                <div class='card rounded-3 h-100 m-0'>
                  <div class='flight-thumb-wrapper'>
                    <div class='popFlights-item-overHidden'>
                      <img src={maxico} class='img-fluid' alt='' />
                    </div>
                  </div>
                  <div class='touritem-middle position-relative p-3'>
                    <div class='touritem-flexxer'>
                      <h4 class='city fs-6 m-0 fw-bold'>
                        <span>{userLocation.length===0?'':userLocation.city.name}</span>
                        <span class='svg-icon svg-icon-muted svg-icon-2hx px-1'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M17.4 7H4C3.4 7 3 7.4 3 8C3 8.6 3.4 9 4 9H17.4V7ZM6.60001 15H20C20.6 15 21 15.4 21 16C21 16.6 20.6 17 20 17H6.60001V15Z'
                              fill='currentColor'
                            />
                            <path
                              opacity='0.3'
                              d='M17.4 3V13L21.7 8.70001C22.1 8.30001 22.1 7.69999 21.7 7.29999L17.4 3ZM6.6 11V21L2.3 16.7C1.9 16.3 1.9 15.7 2.3 15.3L6.6 11Z'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                        <span> Mexico City</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default InternationalRoutes
