import React, { useState, useEffect } from 'react'
import c1 from '../../Images/city/c-1.png'
import c2 from '../../Images/city/c-2.png'
import c3 from '../../Images/city/c-3.png'
import c4 from '../../Images/city/c-4.png'
import c5 from '../../Images/city/c-4.png'
import c6 from '../../Images/city/c-6.png'
import c7 from '../../Images/city/c-7.png'
import c8 from '../../Images/city/c-8.png'
import team1 from '../../Images/team-1.jpg'
import team2 from '../../Images/team-2.jpg'
import team3 from '../../Images/team-3.jpg'
import team4 from '../../Images/team-4.jpg'
import InternationalRoutes from '../../Components/InternationRoutes/InternationalRoutes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faGifts,
  faHeadset,
  faPersonWalkingLuggage,
  faPlaneArrival,
  faPlaneDeparture,
  faSackDollar,
  faUmbrellaBeach,
  faUser
} from '@fortawesome/free-solid-svg-icons'
function IndexComponents () {

  
  return (
    <>
    <section class="border-bottom">
      <div class="container">
        <div class="row align-items-center justify-content-between g-4">

          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-5">
            <div class="featuresBox-wrap">
              <div class="featuresBox-icons mb-3">
                <i class="fa-solid fa-sack-dollar fs-1 text-primary"><FontAwesomeIcon icon={faSackDollar}/></i>
              </div>
              <div class="featuresBox-captions">
                <h4 class="fw-bold fs-5 lh-base mb-0">Easy Booking</h4>
                <p class="m-0">Our easy booking process ensures a seamless and efficient reservation experience. Select your preferences and confirm your booking in just a few clicks. Enjoy the convenience of planning your trips effortlessly.</p>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-5">
            <div class="featuresBox-wrap">
              <div class="featuresBox-icons mb-3">
                <i class="fa-solid fa-umbrella-beach fs-1 text-primary"><FontAwesomeIcon icon={faUmbrellaBeach}/></i>
              </div>
              <div class="featuresBox-captions">
                <h4 class="fw-bold fs-5 lh-base mb-0">Best Destinations</h4>
                <p class="m-0">Immerse yourself in unparalleled beauty and discover top-rated locations that promise memorable moments.Whether you seek relaxation, adventure, or cultural enrichment , our best destinations guarantee an extraordinary journey.</p>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-5">
            <div class="featuresBox-wrap">
              <div class="featuresBox-icons mb-3">
                <i class="fa-solid fa-person-walking-luggage fs-1 text-primary"><FontAwesomeIcon icon={faPersonWalkingLuggage}/></i>
              </div>
              <div class="featuresBox-captions">
                <h4 class="fw-bold fs-5 lh-base mb-0">Travel Guides</h4>
                <p class="m-0">Dive into rich cultural insights, dining recommendations, and must-see attractions, ensuring every trip is infused with authenticity and excitement. Let our Travel Guides be your trusted companion in exploring the world.</p>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-5">
            <div class="featuresBox-wrap">
              <div class="featuresBox-icons mb-3">
                <i class="fa-solid fa-headset fs-1 text-primary"><FontAwesomeIcon icon={faHeadset}/></i>
              </div>
              <div class="featuresBox-captions">
                <h4 class="fw-bold fs-5 lh-base mb-0">Friendly Support</h4>
                <p class="m-0">Experience the warmth of our dedicated support team ready to assist you at every step. Our commitment to providing friendly support.Whether you need guidance, or seek assistance, our team is here.</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

    <InternationalRoutes/>

    <section>
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-xl-8 col-lg-9 col-md-11 col-sm-12">
            <div class="secHeading-wrap text-center mb-5">
              <h2>Explore Popular Destination</h2>
              <p>Cicero famously orated against his political opponent Lucius Sergius Catilina.</p>
            </div>
          </div>
        </div>

        <div class="row justify-content-center gy-4 gx-3">

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div class="destination-blocks bg-white p-2 rounded border br-dashed h-100">
              <div class="destination-blocks-pics p-1">
                <a href="#"><img src={c8} class="img-fluid rounded" alt=""/></a>
              </div>
              <div class="destination-blocks-captions">
                <div class="touritem-flexxer text-center p-3">
                  <h4 class="city fs-5 m-0 fw-bold">
                    <span>New York</span>
                  </h4>
                  <p class="detail ellipsis-container m-0">
                    <span class="ellipsis-item__normal">10 Destinations</span>
                   
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div class="destination-blocks bg-white p-2 rounded border br-dashed h-100">
              <div class="destination-blocks-pics p-1">
                <a href="#"><img src={c7} class="img-fluid rounded" alt=""/></a>
              </div>
              <div class="destination-blocks-captions">
                <div class="touritem-flexxer text-center p-3">
                  <h4 class="city fs-5 m-0 fw-bold">
                    <span>Las Vegas</span>
                  </h4>
                  <p class="detail ellipsis-container m-0">
                    <span class="ellipsis-item__normal">06 Destinations</span>
                   
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div class="destination-blocks bg-white p-2 rounded border br-dashed h-100">
              <div class="destination-blocks-pics p-1">
                <a href="#"><img src={c1} class="img-fluid rounded" alt=""/></a>
              </div>
              <div class="destination-blocks-captions">
                <div class="touritem-flexxer text-center p-3">
                  <h4 class="city fs-5 m-0 fw-bold">
                    <span>San Antonio</span>
                  </h4>
                  <p class="detail ellipsis-container m-0">
                    <span class="ellipsis-item__normal">09 Destinations</span>
                   
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div class="destination-blocks bg-white p-2 rounded border br-dashed h-100">
              <div class="destination-blocks-pics p-1">
                <a href="#"><img src={c2} class="img-fluid rounded" alt=""/></a>
              </div>
              <div class="destination-blocks-captions">
                <div class="touritem-flexxer text-center p-3">
                  <h4 class="city fs-5 m-0 fw-bold">
                    <span>Houston</span>
                  </h4>
                  <p class="detail ellipsis-container m-0">
                    <span class="ellipsis-item__normal">07 Destinations</span>
                    
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div class="destination-blocks bg-white p-2 rounded border br-dashed h-100">
              <div class="destination-blocks-pics p-1">
                <a href="#"><img src={c3} class="img-fluid rounded" alt=""/></a>
              </div>
              <div class="destination-blocks-captions">
                <div class="touritem-flexxer text-center p-3">
                  <h4 class="city fs-5 m-0 fw-bold">
                    <span>San Francisco</span>
                  </h4>
                  <p class="detail ellipsis-container m-0">
                    <span class="ellipsis-item__normal">4 Destinations</span>
                    
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div class="destination-blocks bg-white p-2 rounded border br-dashed h-100">
              <div class="destination-blocks-pics p-1">
                <a href="#"><img src={c4} class="img-fluid rounded" alt=""/></a>
              </div>
              <div class="destination-blocks-captions">
                <div class="touritem-flexxer text-center p-3">
                  <h4 class="city fs-5 m-0 fw-bold">
                    <span>Nashville</span>
                  </h4>
                  <p class="detail ellipsis-container m-0">
                    <span class="ellipsis-item__normal">16 Destinations</span>
                    
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div class="destination-blocks bg-white p-2 rounded border br-dashed h-100">
              <div class="destination-blocks-pics p-1">
                <a href="#"><img src={c5} class="img-fluid rounded" alt=""/></a>
              </div>
              <div class="destination-blocks-captions">
                <div class="touritem-flexxer text-center p-3">
                  <h4 class="city fs-5 m-0 fw-bold">
                    <span>Philadelphia</span>
                  </h4>
                  <p class="detail ellipsis-container m-0">
                    <span class="ellipsis-item__normal">14 Destinations</span>
                   
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div class="destination-blocks bg-white p-2 rounded border br-dashed h-100">
              <div class="destination-blocks-pics p-1">
                <a href="#"><img src={c6} class="img-fluid rounded" alt=""/></a>
              </div>
              <div class="destination-blocks-captions">
                <div class="touritem-flexxer text-center p-3">
                  <h4 class="city fs-5 m-0 fw-bold">
                    <span>San Diego</span>
                  </h4>
                  <p class="detail ellipsis-container m-0">
                    <span class="ellipsis-item__normal">12 Destinations</span>
                   
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <div class="row align-items-center justify-content-between">

          <div class="col-xl-5 col-lg-5 col-md-12 position-relative pe-xl-5 pe-lg-4 pe-md-4 pb-xl-5 pb-lg-4 pb-md-4">
            <div class="position-relative mb-lg-0 mb-4">
              <img src={team3} class="img-fluid rounded-3 position-relative z-1" alt=""/>
              <div class="position-absolute bottom-0 start-0 z-index-1 mb-4 ms-5">
                <div class="bg-body d-flex d-inline-block rounded-3 position-relative p-3 z-2">

                
                  <div class="me-4">
                    <h6 class="fw-normal">Client</h6>
                  
                    <ul class="avatar-group mb-0">
                      <li class="avatar avatar-md">
                        <img class="avatar-img circle" src={team1} alt="avatar"/>
                      </li>
                      <li class="avatar avatar-md">
                        <img class="avatar-img circle" src={team2} alt="avatar"/>
                      </li>
                      <li class="avatar avatar-md">
                        <img class="avatar-img circle" src={team3} alt="avatar"/>
                      </li>
                      <li class="avatar avatar-md">
                        <img class="avatar-img circle" src={team4} alt="avatar"/>
                      </li>
                      <li class="avatar avatar-md">
                        <div class="avatar-img circle bg-primary">
                          <span class="text-white position-absolute top-50 start-50 translate-middle small">1K+</span>
                        </div>
                      </li>
                    </ul>
                  </div>

                 
                  <div>
                    <h6 class="fw-normal mb-3">Rating</h6>
                    <h6 class="m-0">4.5<i class="fa-solid fa-star text-warning ms-1"></i></h6>
                  </div>
                </div>
              </div>
            </div>
            <figure class="position-absolute bottom-0 end-0 d-none d-md-block mb-n5 me-n4">
              <svg height="400" class="fill-primary opacity-25" viewBox="0 0 340 340">
                <circle cx="194.2" cy="2.2" r="2.2"></circle>
                <circle cx="2.2" cy="2.2" r="2.2"></circle>
                <circle cx="218.2" cy="2.2" r="2.2"></circle>
                <circle cx="26.2" cy="2.2" r="2.2"></circle>
                <circle cx="242.2" cy="2.2" r="2.2"></circle>
                <circle cx="50.2" cy="2.2" r="2.2"></circle>
                <circle cx="266.2" cy="2.2" r="2.2"></circle>
                <circle cx="74.2" cy="2.2" r="2.2"></circle>
                <circle cx="290.2" cy="2.2" r="2.2"></circle>
                <circle cx="98.2" cy="2.2" r="2.2"></circle>
                <circle cx="314.2" cy="2.2" r="2.2"></circle>
                <circle cx="122.2" cy="2.2" r="2.2"></circle>
                <circle cx="338.2" cy="2.2" r="2.2"></circle>
                <circle cx="146.2" cy="2.2" r="2.2"></circle>
                <circle cx="170.2" cy="2.2" r="2.2"></circle>
                <circle cx="194.2" cy="26.2" r="2.2"></circle>
                <circle cx="2.2" cy="26.2" r="2.2"></circle>
                <circle cx="218.2" cy="26.2" r="2.2"></circle>
                <circle cx="26.2" cy="26.2" r="2.2"></circle>
                <circle cx="242.2" cy="26.2" r="2.2"></circle>
                <circle cx="50.2" cy="26.2" r="2.2"></circle>
                <circle cx="266.2" cy="26.2" r="2.2"></circle>
                <circle cx="74.2" cy="26.2" r="2.2"></circle>
                <circle cx="290.2" cy="26.2" r="2.2"></circle>
                <circle cx="98.2" cy="26.2" r="2.2"></circle>
                <circle cx="314.2" cy="26.2" r="2.2"></circle>
                <circle cx="122.2" cy="26.2" r="2.2"></circle>
                <circle cx="338.2" cy="26.2" r="2.2"></circle>
                <circle cx="146.2" cy="26.2" r="2.2"></circle>
                <circle cx="170.2" cy="26.2" r="2.2"></circle>
                <circle cx="194.2" cy="50.2" r="2.2"></circle>
                <circle cx="2.2" cy="50.2" r="2.2"></circle>
                <circle cx="218.2" cy="50.2" r="2.2"></circle>
                <circle cx="26.2" cy="50.2" r="2.2"></circle>
                <circle cx="242.2" cy="50.2" r="2.2"></circle>
                <circle cx="50.2" cy="50.2" r="2.2"></circle>
                <circle cx="266.2" cy="50.2" r="2.2"></circle>
                <circle cx="74.2" cy="50.2" r="2.2"></circle>
                <circle cx="290.2" cy="50.2" r="2.2"></circle>
                <circle cx="98.2" cy="50.2" r="2.2"></circle>
                <circle cx="314.2" cy="50.2" r="2.2"></circle>
                <circle cx="122.2" cy="50.2" r="2.2"></circle>
                <circle cx="338.2" cy="50.2" r="2.2"></circle>
                <circle cx="146.2" cy="50.2" r="2.2"></circle>
                <circle cx="170.2" cy="50.2" r="2.2"></circle>
                <circle cx="194.2" cy="74.2" r="2.2"></circle>
                <circle cx="2.2" cy="74.2" r="2.2"></circle>
                <circle cx="218.2" cy="74.2" r="2.2"></circle>
                <circle cx="26.2" cy="74.2" r="2.2"></circle>
                <circle cx="242.2" cy="74.2" r="2.2"></circle>
                <circle cx="50.2" cy="74.2" r="2.2"></circle>
                <circle cx="266.2" cy="74.2" r="2.2"></circle>
                <circle cx="74.2" cy="74.2" r="2.2"></circle>
                <circle cx="290.2" cy="74.2" r="2.2"></circle>
                <circle cx="98.2" cy="74.2" r="2.2"></circle>
                <circle cx="314.2" cy="74.2" r="2.2"></circle>
                <circle cx="122.2" cy="74.2" r="2.2"></circle>
                <circle cx="338.2" cy="74.2" r="2.2"></circle>
                <circle cx="146.2" cy="74.2" r="2.2"></circle>
                <circle cx="170.2" cy="74.2" r="2.2"></circle>
                <circle cx="194.2" cy="98.2" r="2.2"></circle>
                <circle cx="2.2" cy="98.2" r="2.2"></circle>
                <circle cx="218.2" cy="98.2" r="2.2"></circle>
                <circle cx="26.2" cy="98.2" r="2.2"></circle>
                <circle cx="242.2" cy="98.2" r="2.2"></circle>
                <circle cx="50.2" cy="98.2" r="2.2"></circle>
                <circle cx="266.2" cy="98.2" r="2.2"></circle>
                <circle cx="74.2" cy="98.2" r="2.2"></circle>
                <circle cx="290.2" cy="98.2" r="2.2"></circle>
                <circle cx="98.2" cy="98.2" r="2.2"></circle>
                <circle cx="314.2" cy="98.2" r="2.2"></circle>
                <circle cx="122.2" cy="98.2" r="2.2"></circle>
                <circle cx="338.2" cy="98.2" r="2.2"></circle>
                <circle cx="146.2" cy="98.2" r="2.2"></circle>
                <circle cx="170.2" cy="98.2" r="2.2"></circle>
                <circle cx="194.2" cy="122.2" r="2.2"></circle>
                <circle cx="2.2" cy="122.2" r="2.2"></circle>
                <circle cx="218.2" cy="122.2" r="2.2"></circle>
                <circle cx="26.2" cy="122.2" r="2.2"></circle>
                <circle cx="242.2" cy="122.2" r="2.2"></circle>
                <circle cx="50.2" cy="122.2" r="2.2"></circle>
                <circle cx="266.2" cy="122.2" r="2.2"></circle>
                <circle cx="74.2" cy="122.2" r="2.2"></circle>
                <circle cx="290.2" cy="122.2" r="2.2"></circle>
                <circle cx="98.2" cy="122.2" r="2.2"></circle>
                <circle cx="314.2" cy="122.2" r="2.2"></circle>
                <circle cx="122.2" cy="122.2" r="2.2"></circle>
                <circle cx="338.2" cy="122.2" r="2.2"></circle>
                <circle cx="146.2" cy="122.2" r="2.2"></circle>
                <circle cx="170.2" cy="122.2" r="2.2"></circle>
                <circle cx="194.2" cy="146.2" r="2.2"></circle>
                <circle cx="2.2" cy="146.2" r="2.2"></circle>
                <circle cx="218.2" cy="146.2" r="2.2"></circle>
                <circle cx="26.2" cy="146.2" r="2.2"></circle>
                <circle cx="242.2" cy="146.2" r="2.2"></circle>
                <circle cx="50.2" cy="146.2" r="2.2"></circle>
                <circle cx="266.2" cy="146.2" r="2.2"></circle>
                <circle cx="74.2" cy="146.2" r="2.2"></circle>
                <circle cx="290.2" cy="146.2" r="2.2"></circle>
                <circle cx="98.2" cy="146.2" r="2.2"></circle>
                <circle cx="314.2" cy="146.2" r="2.2"></circle>
                <circle cx="122.2" cy="146.2" r="2.2"></circle>
                <circle cx="338.2" cy="146.2" r="2.2"></circle>
                <circle cx="146.2" cy="146.2" r="2.2"></circle>
                <circle cx="170.2" cy="146.2" r="2.2"></circle>
                <circle cx="194.2" cy="170.2" r="2.2"></circle>
                <circle cx="2.2" cy="170.2" r="2.2"></circle>
                <circle cx="218.2" cy="170.2" r="2.2"></circle>
                <circle cx="26.2" cy="170.2" r="2.2"></circle>
                <circle cx="242.2" cy="170.2" r="2.2"></circle>
                <circle cx="50.2" cy="170.2" r="2.2"></circle>
                <circle cx="266.2" cy="170.2" r="2.2"></circle>
                <circle cx="74.2" cy="170.2" r="2.2"></circle>
                <circle cx="290.2" cy="170.2" r="2.2"></circle>
                <circle cx="98.2" cy="170.2" r="2.2"></circle>
                <circle cx="314.2" cy="170.2" r="2.2"></circle>
                <circle cx="122.2" cy="170.2" r="2.2"></circle>
                <circle cx="338.2" cy="170.2" r="2.2"></circle>
                <circle cx="146.2" cy="170.2" r="2.2"></circle>
                <circle cx="170.2" cy="170.2" r="2.2"></circle>
                <circle cx="194.2" cy="194.2" r="2.2"></circle>
                <circle cx="2.2" cy="194.2" r="2.2"></circle>
                <circle cx="218.2" cy="194.2" r="2.2"></circle>
                <circle cx="26.2" cy="194.2" r="2.2"></circle>
                <circle cx="242.2" cy="194.2" r="2.2"></circle>
                <circle cx="50.2" cy="194.2" r="2.2"></circle>
                <circle cx="266.2" cy="194.2" r="2.2"></circle>
                <circle cx="74.2" cy="194.2" r="2.2"></circle>
                <circle cx="290.2" cy="194.2" r="2.2"></circle>
                <circle cx="98.2" cy="194.2" r="2.2"></circle>
                <circle cx="314.2" cy="194.2" r="2.2"></circle>
                <circle cx="122.2" cy="194.2" r="2.2"></circle>
                <circle cx="338.2" cy="194.2" r="2.2"></circle>
                <circle cx="146.2" cy="194.2" r="2.2"></circle>
                <circle cx="170.2" cy="194.2" r="2.2"></circle>
                <circle cx="194.2" cy="218.2" r="2.2"></circle>
                <circle cx="2.2" cy="218.2" r="2.2"></circle>
                <circle cx="218.2" cy="218.2" r="2.2"></circle>
                <circle cx="26.2" cy="218.2" r="2.2"></circle>
                <circle cx="242.2" cy="218.2" r="2.2"></circle>
                <circle cx="50.2" cy="218.2" r="2.2"></circle>
                <circle cx="266.2" cy="218.2" r="2.2"></circle>
                <circle cx="74.2" cy="218.2" r="2.2"></circle>
                <circle cx="290.2" cy="218.2" r="2.2"></circle>
                <circle cx="98.2" cy="218.2" r="2.2"></circle>
                <circle cx="314.2" cy="218.2" r="2.2"></circle>
                <circle cx="122.2" cy="218.2" r="2.2"></circle>
                <circle cx="338.2" cy="218.2" r="2.2"></circle>
                <circle cx="146.2" cy="218.2" r="2.2"></circle>
                <circle cx="170.2" cy="218.2" r="2.2"></circle>
                <circle cx="194.2" cy="242.2" r="2.2"></circle>
                <circle cx="2.2" cy="242.2" r="2.2"></circle>
                <circle cx="218.2" cy="242.2" r="2.2"></circle>
                <circle cx="26.2" cy="242.2" r="2.2"></circle>
                <circle cx="242.2" cy="242.2" r="2.2"></circle>
                <circle cx="50.2" cy="242.2" r="2.2"></circle>
                <circle cx="266.2" cy="242.2" r="2.2"></circle>
                <circle cx="74.2" cy="242.2" r="2.2"></circle>
                <circle cx="290.2" cy="242.2" r="2.2"></circle>
                <circle cx="98.2" cy="242.2" r="2.2"></circle>
                <circle cx="314.2" cy="242.2" r="2.2"></circle>
                <circle cx="122.2" cy="242.2" r="2.2"></circle>
                <circle cx="338.2" cy="242.2" r="2.2"></circle>
                <circle cx="146.2" cy="242.2" r="2.2"></circle>
                <circle cx="170.2" cy="242.2" r="2.2"></circle>
                <circle cx="194.2" cy="266.2" r="2.2"></circle>
                <circle cx="2.2" cy="266.2" r="2.2"></circle>
                <circle cx="218.2" cy="266.2" r="2.2"></circle>
                <circle cx="26.2" cy="266.2" r="2.2"></circle>
                <circle cx="242.2" cy="266.2" r="2.2"></circle>
                <circle cx="50.2" cy="266.2" r="2.2"></circle>
                <circle cx="266.2" cy="266.2" r="2.2"></circle>
                <circle cx="74.2" cy="266.2" r="2.2"></circle>
                <circle cx="290.2" cy="266.2" r="2.2"></circle>
                <circle cx="98.2" cy="266.2" r="2.2"></circle>
                <circle cx="314.2" cy="266.2" r="2.2"></circle>
                <circle cx="122.2" cy="266.2" r="2.2"></circle>
                <circle cx="338.2" cy="266.2" r="2.2"></circle>
                <circle cx="146.2" cy="266.2" r="2.2"></circle>
                <circle cx="170.2" cy="266.2" r="2.2"></circle>
                <circle cx="194.2" cy="290.2" r="2.2"></circle>
                <circle cx="2.2" cy="290.2" r="2.2"></circle>
                <circle cx="218.2" cy="290.2" r="2.2"></circle>
                <circle cx="26.2" cy="290.2" r="2.2"></circle>
                <circle cx="242.2" cy="290.2" r="2.2"></circle>
                <circle cx="50.2" cy="290.2" r="2.2"></circle>
                <circle cx="266.2" cy="290.2" r="2.2"></circle>
                <circle cx="74.2" cy="290.2" r="2.2"></circle>
                <circle cx="290.2" cy="290.2" r="2.2"></circle>
                <circle cx="98.2" cy="290.2" r="2.2"></circle>
                <circle cx="314.2" cy="290.2" r="2.2"></circle>
                <circle cx="122.2" cy="290.2" r="2.2"></circle>
                <circle cx="338.2" cy="290.2" r="2.2"></circle>
                <circle cx="146.2" cy="290.2" r="2.2"></circle>
                <circle cx="170.2" cy="290.2" r="2.2"></circle>
                <circle cx="194.2" cy="314.2" r="2.2"></circle>
                <circle cx="2.2" cy="314.2" r="2.2"></circle>
                <circle cx="218.2" cy="314.2" r="2.2"></circle>
                <circle cx="26.2" cy="314.2" r="2.2"></circle>
                <circle cx="242.2" cy="314.2" r="2.2"></circle>
                <circle cx="50.2" cy="314.2" r="2.2"></circle>
                <circle cx="266.2" cy="314.2" r="2.2"></circle>
                <circle cx="74.2" cy="314.2" r="2.2"></circle>
                <circle cx="290.2" cy="314.2" r="2.2"></circle>
                <circle cx="98.2" cy="314.2" r="2.2"></circle>
                <circle cx="314.2" cy="314.2" r="2.2"></circle>
                <circle cx="122.2" cy="314.2" r="2.2"></circle>
                <circle cx="338.2" cy="314.2" r="2.2"></circle>
                <circle cx="146.2" cy="314.2" r="2.2"></circle>
                <circle cx="170.2" cy="314.2" r="2.2"></circle>
                <circle cx="194.2" cy="338.2" r="2.2"></circle>
                <circle cx="2.2" cy="338.2" r="2.2"></circle>
                <circle cx="218.2" cy="338.2" r="2.2"></circle>
                <circle cx="26.2" cy="338.2" r="2.2"></circle>
                <circle cx="242.2" cy="338.2" r="2.2"></circle>
                <circle cx="50.2" cy="338.2" r="2.2"></circle>
                <circle cx="266.2" cy="338.2" r="2.2"></circle>
                <circle cx="74.2" cy="338.2" r="2.2"></circle>
                <circle cx="290.2" cy="338.2" r="2.2"></circle>
                <circle cx="98.2" cy="338.2" r="2.2"></circle>
                <circle cx="314.2" cy="338.2" r="2.2"></circle>
                <circle cx="122.2" cy="338.2" r="2.2"></circle>
                <circle cx="338.2" cy="338.2" r="2.2"></circle>
                <circle cx="146.2" cy="338.2" r="2.2"></circle>
                <circle cx="170.2" cy="338.2" r="2.2"></circle>
              </svg>
            </figure>
          </div>

          <div class="col-xl-6 col-lg-6 col-md-12">
            <div class="row gy-xl-5 g-4">

              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div class="fbxer-wraps">
                  <div class="fbxerWraps-icons mb-3">
                    <div class="square--70 circle bg-light-primary"><i class="fa-solid fa-gifts text-primary fs-3"><FontAwesomeIcon icon={faGifts}/></i>
                    </div>
                  </div>
                  <div class="fbxerWraps-caps">
                    <h5 class="fw-bold fs-6">Get Superb Deals</h5>
                    <p class="fw-light fs-6 m-0">Unlock incredible savings on your next journey with our unbeatable deals. From exclusive discounts to limited-time offers, seize the opportunity to travel more for less.</p>
                  </div>
                </div>
              </div>

              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div class="fbxer-wraps">
                  <div class="fbxerWraps-icons mb-3">
                    <div class="square--70 circle bg-light-info"><i class="fa-solid fa-gifts text-info fs-3"><FontAwesomeIcon icon={faGifts}/></i></div>
                  </div>
                  <div class="fbxerWraps-caps">
                    <h5 class="fw-bold fs-6">100% Transparency Price</h5>
                    <p class="fw-light fs-6 m-0">Experience peace of mind with our commitment to 100% transparent pricing. No hidden fees or surprises - just clear, upfront costs so you can book with confidence every time.</p>
                  </div>
                </div>
              </div>

              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div class="fbxer-wraps">
                  <div class="fbxerWraps-icons mb-3">
                    <div class="square--70 circle bg-light-success"><i class="fa-solid fa-gifts text-success fs-3"><FontAwesomeIcon icon={faGifts}/></i>
                    </div>
                  </div>
                  <div class="fbxerWraps-caps">
                    <h5 class="fw-bold fs-6">Pure Trusted & Free</h5>
                    <p class="fw-light fs-6 m-0">Discover pure trust and freedom in your booking experience. With our trusted platform, you can explore flights freely, knowing you're in safe hands - all without any hidden charges.</p>
                  </div>
                </div>
              </div>

              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div class="fbxer-wraps">
                  <div class="fbxerWraps-icons mb-3">
                    <div class="square--70 circle bg-light-warning"><i class="fa-solid fa-gifts text-warning fs-3"><FontAwesomeIcon icon={faGifts}/></i>
                    </div>
                  </div>
                  <div class="fbxerWraps-caps">
                    <h5 class="fw-bold fs-6">Travel With Confidence</h5>
                    <p class="fw-light fs-6 m-0"> From seamless transactions to reliable customer support, embark on your journey knowing you're backed by a trusted travel companion every step of the way.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </section>
    </>
  )
}

export default IndexComponents;
