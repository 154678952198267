import React from 'react';
import gif from '../../Images/logo.png'
const Loading = ({status}) => {
  return (

    <div id="loading-wrapper" style={{zIndex:"9999"}}>
      <div id="loading-text">
              <img style={{width:'100%'}} src={gif}/>
           </div>
          
    </div>

  );
};

export default Loading;
