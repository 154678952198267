import React, { useEffect, useState } from 'react';
import bgimage from '../../Images/Hotels/bg.jpg';
import Axios from 'axios';
import moment from 'moment';
import payment from '../../Images/Packages/Logo/payment-img.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDown,
  faArrowRight,
  faAngleRight,
  faCheck,
  faClockFour,
  faPlane,
  faWindowRestore,
  faLock
} from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { Stripe } from 'stripe';
import { useStripe, useElements,CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import {useNavigate } from 'react-router-dom';
import { AirLineNames } from '../../Components/Data/AirLineNames';
import { ApiEndPoint,FlightSearchToken,StripeClientSecret } from '../../Components/GlobalData/GlobalData';
import { ToastContainer, toast } from 'react-toastify';
import Layout from '../../Components/Layout/Layout';
import WithoutLayout from '../../Components/Layout/WithoutLayout';
import HotelSearch from '../../Components/SearchBar/HotelSearch';
var Totalsum=0;
var Currency='';
var ConverterTotalSum=0;
var StripePayment=0;
var StripeCurrency='';
var flighttotal=0;
var hotaltotal=0;
var Gbpexhangeprice=0;
var admingbpprice=0;
var exchangerateprice=0;

function BookRoom (){
  var MarkupSum=0;
  var ConverterCurrency='';
  var Secretkey=StripeClientSecret();
  const stripe = new Stripe(Secretkey);
  const elements = useElements();
  const stripeInstance = useStripe();
  const [error, setError] = useState(null);
  const navigate=useNavigate();
  var endpoint=ApiEndPoint();
  let AdultAmount=0;
  let ChildAmount=0;
  let InfantAmount=0;
  var Airlinenamesdata = AirLineNames;
  const Search_response = useSelector( state => state.hotels.OneWayFlight);
  const homesearch = useSelector(state => state.hotels.hsearch);
  const IndexPageSearchData = useSelector( state => state.hotels.OneWayFlightSearchData);
  const adultCount = homesearch.adult;
  const childCount = homesearch.child;
  var infantCount;
  if(IndexPageSearchData !== undefined){
    infantCount=IndexPageSearchData.infant;
  };
  const [gbpPrices, setGbpPrices] = useState({adult:'',child:'',infant:'',adultqty:'',childqty:'',infantqty:''});
  const [isChecked, setIsChecked] = useState(false);
  const [otherGuestData, setOtherGuestData] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [countryListsimple, setCountryListsimple] = useState([]);
  // const [adminPrices, setAdminPrices] = useState({gbpprice:0,adminPrice:0});
  const [otherDiv, setOtherDiv] = useState({adult:'',child:'',infant:''});
  const [baseCName, setBaseCName] = useState('GBP');
  const [ConversionRates, setConversionRates] = useState({gbpRate:'',exchangeRate:''});
  const [showPrice, setShowPrice] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCountryother, setSelectedCountryother] = useState(null);
  const [exchangemarkup, setExchangemarkup] = useState({admin:0,client:0});
  const [totalperson, setTotalperson] = useState({adult:'',child:'',infant:'',adultprice:'',childprice:'',infantprice:''});
  const [savemarkup, setSavemarkup] = useState({totalroomprice:0,admin:0,client:0,final:0});
  const [selectedNationality, setSelectedNationality] = useState('');
  const [gestinfo, setGestinfo] = useState({
    title:'',
    firstname: '',
    lastname: '',
    dateofbirth: '',
    numbercode:'',
    email: '',
    phonenumber: '',
    gender:'',
    pno:'',
    pexpiry:'',
    country:'',
    nationality:'',
    postalcode:''
  });
  const [cardInfo, setCardInfo] = useState({
    name: '',
    cardnumber: '',
    mm: '',
    yy: '',
    cvv: ''
  });
  const [isBooking, setIsBooking] = useState(false);
  const [guests, setGuests] = useState(Array(adultCount).fill({}));
  const [childs, setChilds] = useState(Array(childCount).fill({}));
  const [infants, setinfants] = useState(Array(infantCount).fill({}));
  const checkoutdetail = useSelector(state => state.hotels.checkoutdetail)
  const hotelimg = useSelector(state => state.hotels.hoteldetail.hotel_gallery[0]);
  let Checkin = moment(checkoutdetail?.checkIn);
  let checkout = moment(checkoutdetail?.checkOut);
  let daysBetween = Math.abs(checkout.diff(Checkin, 'days'));
  const childsArray = Array.from({ length: childCount });
  const adultsArray = Array.from({ length: adultCount-1 });
  const infantsArray = Array.from({ length: infantCount });
  const FlightCurrency = useSelector( state => state.hotels.FlightCurrency);
  const CheckoutFlightData = useSelector( state => state.hotels.OneWayFlightcheckout);
  const storedData = JSON.parse(sessionStorage.getItem('FlightCheckOut'));
  var FlightMarkup=JSON.parse( localStorage.getItem('FlightMarkup'));
  useEffect(() => {
    fetchData();
    fetchData2();
    if(storedData !== null){
     Totalsum=calculateSum();
  }
    totalcount();
  }, []);

  const CurrencyRates = useSelector(state => state.hotels.Currency);
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency);
  async function fetchData () {
    Axios.get('https://restcountries.com/v3.1/all?fields=name,flags,cca2,cca3,idd')
          .then(response => {
            const countryOptions = response?.data.map((country) => ({
                value:country.name.common,
                label: country.name.common,
                flag: country.flags.png,
                phoneCode: country.idd.root+country.idd.suffixes[0],
              }));
            setCountryList(countryOptions)
          })
          .catch(error => {
            console.error('Error:', error)
          })
  };
  async function fetchData2 () {
    Axios.get(endpoint+'/api/countries/fetch')
      .then(response => {
        setCountryListsimple(response.data.countries)
      })
      .catch(error => {
        console.error('Error:', error)
      })
  };
  const totalcount=()=>{ 
    
   let allroomsprice=0;
  let markupprice=0;
  let adminmarkupprice=0;
  let clientmarkupprice=0;
  let finalpricemarkup=0;
   allroomsprice= checkoutdetail.rooms_list.reduce((sum,item)=>sum+Number(item.rooms_total_price),0);
   savemarkup.totalroomprice=allroomsprice;
   finalpricemarkup=allroomsprice;
   if(Number(checkoutdetail.admin_markup) !== 0)
   {
    if(checkoutdetail.admin_markup_type === "Percentage")
    {
      markupprice=( allroomsprice * Number(checkoutdetail.admin_markup))/100;
    }else{
      markupprice= Number(checkoutdetail.admin_markup);
     }
      adminmarkupprice=markupprice;
      finalpricemarkup +=markupprice
      savemarkup.admin=adminmarkupprice;
      savemarkup.final=finalpricemarkup;
   }
   if(Number(checkoutdetail.customer_markup) !== 0)
   {
    if(checkoutdetail.customer_markup_type === "Percentage")
    {
      markupprice= (finalpricemarkup * Number(checkoutdetail.customer_markup))/100;
    }else{
      markupprice= Number(checkoutdetail.customer_markup);
     }
     clientmarkupprice=markupprice;
      finalpricemarkup +=markupprice;
      savemarkup.client=clientmarkupprice;
      savemarkup.final=finalpricemarkup;
   }else{
    savemarkup.final=allroomsprice;
   }
  };
  
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
     setSelectedNationality(selectedOption.value);
    setGestinfo(prevdata=>({...prevdata, phonenumber:selectedOption.phoneCode, numbercode:selectedOption.phoneCode,nationality:selectedOption.value }));
  };
  const handleCountryChangeother = (selectedOption) => {
    setSelectedCountryother(selectedOption);
    setGestinfo(prevdata => ({
      ...prevdata,
      country: selectedOption.value,
    }))
  };
  const handlePayment = async () => {
    if(cardInfo.name===""){
      setError("Enter Card Holder Name.");
      return { success: false};
    };
    const response = await stripe.paymentIntents.create({
      amount: (Number(StripePayment)*100).toFixed(0), // Amount in cents
      currency: StripeCurrency,
      description: 'Example payment',
      payment_method_types: ['card'],
    });
    var Client_Secret= response.client_secret;
    
    try {
      // Confirm the payment intent with the payment method
      const { paymentIntent, error } = await stripeInstance.confirmCardPayment(Client_Secret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name:cardInfo.name,
          },
        },
      });

      if (error) {
         setError(error.message);
        console.error(error);
        setIsBooking(false);
        return { success: false};
      } else {
        return { success: true,data:paymentIntent};
      }
    } catch (error) {
      console.error('Error:', error);
    setIsBooking(false);

      return { success: false};
    }
  };

  const calculateSum = () => {
    const pricedItinerary = storedData.PricedItineraries[0];
    if (pricedItinerary && pricedItinerary.AirItineraryPricingInfo) {
      const fareBreakdowns = pricedItinerary.AirItineraryPricingInfo.PTC_FareBreakdowns;
     Currency=fareBreakdowns[0].PassengerFare.TotalFare.CurrencyCode;
      const sum = fareBreakdowns.reduce((accumulator, item) => {
        var Code=item.PassengerTypeQuantity.Code;
        var x=item.PassengerTypeQuantity.Quantity;
        switch (Code) {
          case 'ADT':
              setTotalperson(prevdata => ({
                  ...prevdata,
                  adult: x,
                 adultprice:item.PassengerFare.TotalFare.Amount
                }))
                setOtherDiv(prevdata => ({
                  ...prevdata,
                  adult: x-1,
                
                }))
            break;
          case 'CHD':
              setTotalperson(prevdata => ({
                  ...prevdata,
                  child: x,
                  childprice:item.PassengerFare.TotalFare.Amount
                }))
                setOtherDiv(prevdata => ({
                  ...prevdata,
                  child: x,
                }))
            break;
          case 'INF':
              setTotalperson(prevdata => ({
                  ...prevdata,
                  infant: x,
                  infantprice:item.PassengerFare.TotalFare.Amount
                }));
                setOtherDiv(prevdata => ({
                  ...prevdata,
                  infant: x,
                }))
            break;
          default:
            break;
        }
       
        return accumulator + (Number(item.PassengerFare.TotalFare.Amount)*item.PassengerTypeQuantity.Quantity);
      }, 0);
      return sum.toFixed(2);
    } else {
      return 0; // Handle the case where the data is missing or empty
    }
  };

  const calculateMarkup = (price) => {
    if(Object.keys(checkoutdetail).length !==0){
    let markupprice=0;
    let adminmarkupprice=0;
    let clientmarkupprice=0;
    let finalpricemarkup=Number(price);
    if(Number(checkoutdetail.admin_markup) !== 0)
   {
    if(checkoutdetail.admin_markup_type === "Percentage")
    {
      markupprice=( price * Number(checkoutdetail.admin_markup))/100;
    }else{
      markupprice= Number(checkoutdetail.admin_markup);
     }
      adminmarkupprice=markupprice;
      finalpricemarkup +=markupprice
      
   }
   if(Number(checkoutdetail.customer_markup) !== 0)
   {
    if(checkoutdetail.customer_markup_type=== "Percentage")
    {
      markupprice= (finalpricemarkup * Number(checkoutdetail.customer_markup))/100;
    }else{
      markupprice= Number(checkoutdetail.customer_markup);
     }
     clientmarkupprice=markupprice;
      finalpricemarkup +=markupprice;
   }
   return finalpricemarkup.toFixed(2);
  }
  };
  

const handlegestchange=(e)=>{
  const {value,name}=e.target;
  setGestinfo(prevgestinfo=>({...prevgestinfo,
    [name]:value,
  }));
};
const confirmbooking=async()=>{
if(gestinfo.title==='')
{
  toast.error('Please Select Lead Guest Title.', {
    position: toast.POSITION.TOP_RIGHT
});
return;
}else if(gestinfo.firstname===''){
  toast.error('Please Enter Lead Guest First Name.', {
    position: toast.POSITION.TOP_RIGHT
});
return;
}else if(gestinfo.lastname===''){
  toast.error('Please Enter Lead Guest Last Name.', {
    position: toast.POSITION.TOP_RIGHT
});
return;
}else if(gestinfo.dateofbirth===''){
  toast.error('Please Enter Lead Guest Date of Birth.', {
    position: toast.POSITION.TOP_RIGHT
});
return;
}else if(gestinfo.email===''){
  toast.error('Please Enter Lead Guest Email.', {
    position: toast.POSITION.TOP_RIGHT
});
return;
}else if(selectedNationality===''){
  toast.error('Please Select Lead Guest Nationality.', {
    position: toast.POSITION.TOP_RIGHT
});
return;
}else if(gestinfo.phonenumber.length < 5 ){
  toast.error('Please Enter Phone Number.', {
    position: toast.POSITION.TOP_RIGHT
});
return;
}

 if(isChecked){
  setIsBooking(true);
  if(storedData !==null){
    BothServicesBooking();
    return;
  }
  setIsBooking(true);
   var status=await handlePayment();
  // var status=true;
      if(status.success===false){
        setIsBooking(false);
        return;
      };
      var title=[];
      var firstname=[];
      var lastname=[];
      var nationality=[];
      if(guests.length!==0){
        guests.forEach(person => {
          if (person.other_title && person.other_first_name) {
          title.push(person.other_title);
          firstname.push(person.other_first_name);
          lastname.push(person.other_last_name);
          nationality.push(person.other_passport_country);
          }
       });
      };
      var Childtitle=[];
      var Childfirstname=[];
      var Childlastname=[];
      var Childnationality=[];
      if(childs.length!==0){
        childs.forEach(children => {
          if (children.title && children.firstName) {
            Childtitle.push(children.title);
            Childfirstname.push(children.firstName);
            Childlastname.push(children.lastName);
            Childnationality.push(children.nationality );
          }
       });
    
      };
  const jsonString = {
    "lead_title":gestinfo.title,
    "lead_first_name": gestinfo.firstname,
    "lead_last_name": gestinfo.lastname,
    "lead_email": gestinfo.email,
    "lead_date_of_birth": gestinfo.dateofbirth,
    "lead_country": selectedNationality,
    "lead_phone": gestinfo.phonenumber,
    "other_title":title,
    "other_first_name":firstname,
    "other_last_name": lastname,
    "other_nationality": nationality,
    "child_title":Childtitle,
    "child_first_name":Childfirstname,
    "child_last_name": Childlastname,
    "child_nationality": Childnationality,
    "slc_pyment_method": "slc_stripe",
    "name": "on",
    "base_exchange_rate": "1",
    "base_currency":'GBP',
    "selected_exchange_rate": '1',
    "exchange_price":StripePayment,
    "admin_markup": checkoutdetail.admin_markup,
    "client_markup": checkoutdetail.customer_markup,
    "exchange_currency": StripeCurrency,
  };
  const customersearch={
    "token":'r9fdvwRyF35JUnD6xXdRiDELANYjtfASzPmyGol4-1PN461EY50LbXcqkfEfISsOJDrnFDJbuMzPuxTz37zFWGWBVemQGhi2SYLrr-MZ75vJSAiV73z94UOVrDz5P6R-0KjFqr9XR6P2857snQbcKTUn9YNqjBOQQIkXENeO7tmjxdTJs2KUVoXqo6fFyT9TTq99eKe288N-wyanZXxOsfABWPjtSom2oKLVz6vJnn1WeQwHSp7VnzPUqq53rn80eFXNBSMIiEXBdDmlsokRYSa0evDrQKluhnIzMYkRiazxtnkb-z5Xj0tQReTTHsLz1sgnit2mRGGzP4EIdBK8TiLuEN7GD1kmOT3CMreL7ELrI4yxmEbnYyflICtG-ySk3aZkk8iM9mRZlA7CS10Zuj-C0HEBOFW8vMzy4Eq2CET5WN62S1xe0HPAOrDVwO6jDvVpKEMwm-NiyyjkU8oTTlgYpN77pXtfFjKPTF0julnAMC6cPzxZOGBIkRv0',
    "city_name":checkoutdetail.destinationName,
    "destination": checkoutdetail.destinationName,
    "room_searching":homesearch.room,
    "child_searching": homesearch.child,
    "adult_searching": homesearch.adult,
    "adult_per_room": homesearch.adult,
    "child_per_room": homesearch.children,
    "country_nationality": homesearch.slc_nationality,
    "check_in": homesearch.check_in,
    "check_out": homesearch.check_out,
    "request_all_data":JSON.stringify(homesearch)
  };  

  const phpArray = {
    "token":'r9fdvwRyF35JUnD6xXdRiDELANYjtfASzPmyGol4-1PN461EY50LbXcqkfEfISsOJDrnFDJbuMzPuxTz37zFWGWBVemQGhi2SYLrr-MZ75vJSAiV73z94UOVrDz5P6R-0KjFqr9XR6P2857snQbcKTUn9YNqjBOQQIkXENeO7tmjxdTJs2KUVoXqo6fFyT9TTq99eKe288N-wyanZXxOsfABWPjtSom2oKLVz6vJnn1WeQwHSp7VnzPUqq53rn80eFXNBSMIiEXBdDmlsokRYSa0evDrQKluhnIzMYkRiazxtnkb-z5Xj0tQReTTHsLz1sgnit2mRGGzP4EIdBK8TiLuEN7GD1kmOT3CMreL7ELrI4yxmEbnYyflICtG-ySk3aZkk8iM9mRZlA7CS10Zuj-C0HEBOFW8vMzy4Eq2CET5WN62S1xe0HPAOrDVwO6jDvVpKEMwm-NiyyjkU8oTTlgYpN77pXtfFjKPTF0julnAMC6cPzxZOGBIkRv0',
    "lead_title":gestinfo.title,
    'admin_exchange_currency':'GBP',
    'admin_exchange_rate':Gbpexhangeprice.toFixed(4),
    'admin_exchange_total_markup_price':admingbpprice,
    "lead_first_name": gestinfo.firstname,
    "lead_last_name": gestinfo.lastname,
    "lead_email": gestinfo.email,
    "lead_date_of_birth": gestinfo.dateofbirth,
    "lead_country": selectedNationality,
    "lead_phone": gestinfo.phonenumber,
    "other_title":title,
    "other_first_name":firstname,
    "other_last_name": lastname,
    "other_nationality": nationality,
    "slc_pyment_method": "slc_stripe",
    'payment_details': JSON.stringify(status.data),
   "base_exchange_rate": Gbpexhangeprice,
    "base_currency":'GBP',
    "selected_exchange_rate": exchangerateprice,
    "selected_currency": StripeCurrency,
   "exchange_price":StripePayment,
   "admin_markup": checkoutdetail.admin_markup,
   "client_markup": checkoutdetail.customer_markup,
    "exchange_currency": StripeCurrency,
    "request_data": JSON.stringify(jsonString),
    "creditAmount":StripePayment,
"hotel_checkout_select":JSON.stringify(checkoutdetail),
"customer_search_data":JSON.stringify(customersearch),
  };
  // _Live
  try {
  const response = await Axios.post(endpoint+'/api/hotels/reservation',phpArray , {
    headers: {
      "Access-Control-Allow-Origin": "*",
      // Required for CORS support to work
       "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
      "Access-Control-Allow-Headers":
      "Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale",
      // "Access-Control-Allow-Methods": "POST, OPTIONS"
    } ,
    
  });

  // Handle the API response here
  setIsBooking(false);
  if(response.data.message==='Internal server error')
  {
    toast.error('Please Search Hotel Again.', {
      position: toast.POSITION.TOP_RIGHT
  });
  }else if(response.data.message==='Insufficient allotment'){
    toast.error('Insufficient allotment.', {
      position: toast.POSITION.TOP_RIGHT
  });
  }else if(response.data.status==='error'){
    toast.error(response.data.message, {
      position: toast.POSITION.TOP_RIGHT
  });
  }else if(response.data.status==="success"){
    toast.success('Your Bookinf is Confirmed.', {
      position: toast.POSITION.TOP_RIGHT
  });
  navigate(`/hotel_booking_invoice/${response.data.Invoice_id}`)

  }
  // navigate(`/hotel_detail/${id}`,{state:{index}})
} catch (error) {
  // Handle errors here
  setIsBooking(false);
  console.error('Error:', error);
}
 
}else{
  toast.error('Please Agree with Terms and Conditions.', {
    position: toast.POSITION.TOP_RIGHT
});
}
};
const handlecarddetail=(e)=>{
  const {value,name}=e.target;
  setCardInfo(prevcardinfo=>({...prevcardinfo,
  [name]:value,
  }));
};
const handleCheckboxContinue=()=>{
  setIsChecked(!isChecked);
};
const otherGuestInfo = (e, guestIndex,digit) => {
  
  const selectedValue = e.target.value;
  if(digit===2){
    setChilds((prevChilds) => {
      const updatedChilds = [...prevChilds];
      updatedChilds[guestIndex] = {
        ...updatedChilds[guestIndex],
        title: selectedValue,
      };
      return updatedChilds;
    });
  }else if(digit===1){
    setGuests((prevGuests) => {
      const updatedGuests = [...prevGuests];
      updatedGuests[guestIndex] = {
        ...updatedGuests[guestIndex],
        other_title: selectedValue,
      };
      return updatedGuests;
    });
  }
  else if(digit===3){
    setinfants((prevInfants) => {
      const updatedGuests = [...prevInfants];
      updatedGuests[guestIndex] = {
        ...updatedGuests[guestIndex],
        title: selectedValue,
      };
      return updatedGuests;
    });
  }
    
};
const otherGuestFirstName = (e, guestIndex,digit) => {
  // Handle the first name input and update the state
  const firstName = e.target.value;
  if(digit===2){
    setChilds((prevChilds) => {
      const updatedChilds = [...prevChilds];
      updatedChilds[guestIndex] = {
        ...updatedChilds[guestIndex],
        firstName: firstName,
      };
      return updatedChilds;
    });
  }else if(digit===1){
  setGuests((prevGuests) => {
    const updatedGuests = [...prevGuests];
    updatedGuests[guestIndex] = {
      ...updatedGuests[guestIndex],
      other_first_name: firstName,
    };
    return updatedGuests;
  });
}else if(digit===3){
  setinfants((prevInfant) => {
    const updatedGuests = [...prevInfant];
    updatedGuests[guestIndex] = {
      ...updatedGuests[guestIndex],
      firstName: firstName,
    };
    return updatedGuests;
  });
}
};
const otherGuestLastName = (e, guestIndex,digit) => {
  // Handle the last name input and update the state
  const lastName = e.target.value;
  if(digit===2){
    setChilds((prevChilds) => {
      const updatedChilds = [...prevChilds];
      updatedChilds[guestIndex] = {
        ...updatedChilds[guestIndex],
        lastName: lastName,
      };
      return updatedChilds;
    });
  }else if(digit===1){
    setGuests((prevGuests) => {
      const updatedGuests = [...prevGuests];
      updatedGuests[guestIndex] = {
        ...updatedGuests[guestIndex],
        other_last_name: lastName,
      };
      return updatedGuests;
    });
  }else if(digit===3){
    setinfants((prevInfant) => {
      const updatedGuests = [...prevInfant];
      updatedGuests[guestIndex] = {
        ...updatedGuests[guestIndex],
        lastName: lastName,
      };
      return updatedGuests;
    });
  }
};
const otherGuestdob = (e, guestIndex,digit) => {
  // Handle the last name input and update the state
  const other_dob = e.target.value;
  if(digit===2){
    setChilds((prevChilds) => {
      const updatedChilds = [...prevChilds];
      updatedChilds[guestIndex] = {
        ...updatedChilds[guestIndex],
        dob: other_dob,
      };
      return updatedChilds;
    });
  }else if(digit===1){
    setGuests((prevGuests) => {
      const updatedGuests = [...prevGuests];
      updatedGuests[guestIndex] = {
        ...updatedGuests[guestIndex],
        other_date_of_birth: other_dob,
      };
      return updatedGuests;
    });
  }
  else if(digit===3){
    setinfants((prevInfant) => {
      const updatedGuests = [...prevInfant];
      updatedGuests[guestIndex] = {
        ...updatedGuests[guestIndex],
        dob: other_dob,
      };
      return updatedGuests;
    });
  }
};
const otherGuestGender = (e, guestIndex,digit) => {
  // Handle the last name input and update the state
  const other_g = e.target.value;
  if(digit===2){
    setChilds((prevChilds) => {
      const updatedChilds = [...prevChilds];
      updatedChilds[guestIndex] = {
        ...updatedChilds[guestIndex],
        gender: other_g,
      };
      return updatedChilds;
    });
  }else if(digit===1){
    setGuests((prevGuests) => {
      const updatedGuests = [...prevGuests];
      updatedGuests[guestIndex] = {
        ...updatedGuests[guestIndex],
        other_gender: other_g,
      };
      return updatedGuests;
    });
  }else if(digit===3){
    setinfants((prevInfant) => {
      const updatedGuests = [...prevInfant];
      updatedGuests[guestIndex] = {
        ...updatedGuests[guestIndex],
        gender: other_g,
      };
      return updatedGuests;
    });
  }
};
const otherGuestPNumber = (e, guestIndex,digit) => {
  // Handle the last name input and update the state
  const value= e.target.value;
  if(digit===2){
    setChilds((prevChilds) => {
      const updatedChilds = [...prevChilds];
      updatedChilds[guestIndex] = {
        ...updatedChilds[guestIndex],
        pnumber: value,
      };
      return updatedChilds;
    });
  }else if(digit===1){
    setGuests((prevGuests) => {
      const updatedGuests = [...prevGuests];
      updatedGuests[guestIndex] = {
        ...updatedGuests[guestIndex],
        other_passport_no: value,
      };
      return updatedGuests;
    });
  }else if(digit===3){
    setinfants((prevInfant) => {
      const updatedGuests = [...prevInfant];
      updatedGuests[guestIndex] = {
        ...updatedGuests[guestIndex],
        pnumber: value,
      };
      return updatedGuests;
    });
  }
};

const otherGuestPExpiry = (e, guestIndex,digit) => {
  // Handle the last name input and update the state
  const value= e.target.value;
  if(digit===2){
    setChilds((prevChilds) => {
      const updatedChilds = [...prevChilds];
      updatedChilds[guestIndex] = {
        ...updatedChilds[guestIndex],
        pexpiry: value,
      };
      return updatedChilds;
    });
  }else if(digit===1){
    setGuests((prevGuests) => {
      const updatedGuests = [...prevGuests];
      updatedGuests[guestIndex] = {
        ...updatedGuests[guestIndex],
        other_passport_expiry_date: value,
      };
      return updatedGuests;
    });
  }else if(digit===3){
    setinfants((prevInfants) => {
      const updatedGuests = [...prevInfants];
      updatedGuests[guestIndex] = {
        ...updatedGuests[guestIndex],
        pexpiry: value,
      };
      return updatedGuests;
    });
  }
};
const otherGuestNationality = (e, guestIndex,digit) => {
  // Handle the last name input and update the state
  const newvalue = e.target.value;
  if(digit===2){
    setChilds((prevChilds) => {
      const updatedChilds = [...prevChilds];
      updatedChilds[guestIndex] = {
        ...updatedChilds[guestIndex],
        nationality: newvalue,
      };
      return updatedChilds;
    });
  }else if(digit===1){
  setGuests((prevGuests) => {
    const updatedGuests = [...prevGuests];
    updatedGuests[guestIndex] = {
      ...updatedGuests[guestIndex],
      other_passport_country: newvalue,
    };
    return updatedGuests;
  });
}else if(digit===3){
  setinfants((prevInfants) => {
    const updatedGuests = [...prevInfants];
    updatedGuests[guestIndex] = {
      ...updatedGuests[guestIndex],
      nationality: newvalue,
    };
    return updatedGuests;
  });
}
};
const renderPrice = (price,check) =>{
  const userData = localStorage.getItem('HotelCurrency');
  const Rates = JSON.parse(userData);
  if(userData !==null){
  if(CurrencyRates===undefined){
    const gbpprice = Rates.conversion_rates[baseCName]; // Use square brackets to access the property
    // setConversionRates({gbpRate:gbpprice});
    var baseprice = (Number(gbpprice) * Number(price));
    StripeCurrency=baseCName;
    Gbpexhangeprice=gbpprice;
    admingbpprice=baseprice
  }else{
    var select123 = CurrencyRates.selectedcurrency;
    StripeCurrency=select123;
    const gbpprice = Rates.conversion_rates[baseCName];
    var baseprice1 = (Number(gbpprice) * Number(price));
    const gbpprice2 = GBPCurrencyRates.conversion_rates[select123]; // Use square brackets to access the property
    var baseprice = (Number(gbpprice2) * Number(baseprice1));
    Gbpexhangeprice=gbpprice;
    admingbpprice=baseprice1;
    exchangerateprice=gbpprice2;
    // setConversionRates({gbpRate:gbpprice,exchangeRate:gbpprice2});

  }
  // if(check===1){
  //   StripePayment=baseprice;
  // }
  return baseprice.toFixed(0)
}else{
  setShowPrice(false);
}
};

const renderPrice2 = (price,qty,check) => {
  
  if(FlightCurrency !==undefined){
  if (CurrencyRates === undefined) {
    const gbpprice = FlightCurrency[baseCName] // Use square brackets to access the property
    var baseprice = (Number(gbpprice) * Number(price))
      
    if(check===2){
      gbpPrices.adult=baseprice;
      gbpPrices.adultqty=qty;
    }else if(check===3){
      gbpPrices.child=baseprice;
      gbpPrices.childqty=qty;
    }else if(check===4){
      gbpPrices.infant=baseprice;
      gbpPrices.infantqty=qty;
    };

    StripeCurrency=baseCName;

  } else {
    var select123 = CurrencyRates.selectedcurrency
    const gbpprice = FlightCurrency[baseCName]
    var baseprice1 = (Number(gbpprice) * Number(price))
    if(check===2){
      gbpPrices.adult=baseprice1;
      gbpPrices.adultqty=qty;
    }else if(check===3){
      gbpPrices.child=baseprice1;
      gbpPrices.childqty=qty;
    }else if(check===4){
      gbpPrices.infant=baseprice1;
      gbpPrices.infantqty=qty;
    };
    const gbpprice2 = GBPCurrencyRates.conversion_rates[select123] // Use square brackets to access the property
    var baseprice = (Number(gbpprice2) * Number(baseprice1))
  }
}else{
  setShowPrice(false);
};
  return baseprice
};

const ShowOtherGuestForm=()=>{
  setOtherGuestData(!otherGuestData);
};
const BothServicesBooking= async()=>{
//   debugger
//   var check=0;
//   if(adultCount-1 !==0){
//     for (let i = 0; i < adultsArray.length; i++) {
//      if (!validateAdultData(adultsArray[i], i)) {
//        // Validation failed for at least one adult, handle accordingly
//        check=1;
//        return;
//      }
//    }
//  }
//    if(check==1){
//      return;
//     }
//    check=0;
//    if(childCount !==''){
//    for (let i = 0; i < childsArray.length; i++) {
//      if (!validateChildData(childsArray[i], i)) {
//        // Validation failed for at least one adult, handle accordingly
//        check=1;
//        return;
//      }
//    }
//  }
//    if(check==1){
//      return;
//     }
var token=FlightSearchToken();
var limit={
  'token':token
}
var limitcheck;
try {
  const response = await Axios.post(endpoint+'/api/flight_credit_limit', limit, {
    headers: {
      "Access-Control-Allow-Origin": "*",
    } ,
    
  });
  limitcheck=response.data;
  // Handle the API response here
 
} catch (error) {
  // Handle errors here
  setIsBooking(false);
  console.error('Error:', error);
}

if(Number(flighttotal) > Number(limitcheck.credit_data.remaining_amount)){
  toast.info('There is a problem in the payment, Please contact Support or try again later on.', {
    position: toast.POSITION.TOP_RIGHT
  });
  setIsBooking(false);
  return;
  

}else{
  var status=await handlePayment();
      if(status.success===false){
        setIsBooking(false);
        return;
      };
      var adminmarkup='';
    var clientmarkup='';

    for(var i=0;i < FlightMarkup.markups.length;i++){
      if(FlightMarkup.markups[i].services_type==='flight'){
        if(FlightMarkup.markups[i].added_markup=='synchtravel'){
          adminmarkup=FlightMarkup.markups[i];
        }else if(FlightMarkup.markups[i].added_markup=='alhijaz'){
          clientmarkup=FlightMarkup.markups[i];
        }
      }
    };
  var leadpassengerdetail= {
    "title":gestinfo.title,
    "first_name": gestinfo.firstname,
    "last_name": gestinfo.lastname,
    "email": gestinfo.email,
    "date_of_birth":gestinfo.dateofbirth,
    "gender": gestinfo.gender,
    "passport_no": gestinfo.pno,
    "passport_expiry_date": gestinfo.pexpiry,
    "passport_country": gestinfo.country,
   "passenger_nationality_id": gestinfo.nationality,
    "passenger_nationality_code": gestinfo.numbercode,
    "passenger_phone_no": gestinfo.phonenumber,
    "postal_code":gestinfo.postalcode
  };
  

  const retrievedNumber = sessionStorage.getItem('15digitnumber');
  var token=FlightSearchToken();
  var farerequest={
    'token_authorization':token,
'ConversationId':retrievedNumber,
'FareSourceCode':CheckoutFlightData.PricedItineraries[0].AirItineraryPricingInfo.FareSourceCode,
  };
  const customersearch={
    "token":'r9fdvwRyF35JUnD6xXdRiDELANYjtfASzPmyGol4-1PN461EY50LbXcqkfEfISsOJDrnFDJbuMzPuxTz37zFWGWBVemQGhi2SYLrr-MZ75vJSAiV73z94UOVrDz5P6R-0KjFqr9XR6P2857snQbcKTUn9YNqjBOQQIkXENeO7tmjxdTJs2KUVoXqo6fFyT9TTq99eKe288N-wyanZXxOsfABWPjtSom2oKLVz6vJnn1WeQwHSp7VnzPUqq53rn80eFXNBSMIiEXBdDmlsokRYSa0evDrQKluhnIzMYkRiazxtnkb-z5Xj0tQReTTHsLz1sgnit2mRGGzP4EIdBK8TiLuEN7GD1kmOT3CMreL7ELrI4yxmEbnYyflICtG-ySk3aZkk8iM9mRZlA7CS10Zuj-C0HEBOFW8vMzy4Eq2CET5WN62S1xe0HPAOrDVwO6jDvVpKEMwm-NiyyjkU8oTTlgYpN77pXtfFjKPTF0julnAMC6cPzxZOGBIkRv0',
    "city_name":checkoutdetail.destinationName,
    "destination": checkoutdetail.destinationName,
    "room_searching":homesearch.room,
    "child_searching": homesearch.child,
    "adult_searching": homesearch.adult,
    "adult_per_room": homesearch.adults,
    "child_per_room": homesearch.children,
    "country_nationality": homesearch.slc_nationality,
    "check_in": homesearch.check_in,
    "check_out": homesearch.check_out,
    "request_all_data":JSON.stringify(homesearch)
  };
  var adulttotal= Number(gbpPrices.adult)*Number(gbpPrices.adultqty);
  var childtotal=Number(gbpPrices.child)*Number(gbpPrices.childqty);
  var infanttotal=Number(gbpPrices.infant)*Number(gbpPrices.infantqty);
  var total=adulttotal+childtotal+infanttotal;
  var adminmarkupprice=0;
  var clientmarkupprice=0;
  
  FlightMarkup.markups.forEach((markup) => {
    if (markup.services_type === "flight" ) {
      if(markup.added_markup==="alhijaz"){

        if(markup.markup_type==='Percentage'){
          const markupValue = Number(markup.markup_value);
          const markupAmount = (Number(total) * markupValue) / 100;
          clientmarkupprice=markupAmount;
        }else {
          clientmarkupprice =Number(markup.markup_value);
        }

      }else if(markup.added_markup==="synchtravel"){

        if(markup.markup_type==='Percentage'){
          const markupValue = parseFloat(Number(markup.markup_value));
          const markupAmount = (Number(total) * markupValue) / 100;
          adminmarkupprice=markupAmount;
        }else {
          adminmarkupprice= Number(markup.markup_value);
        }

      }
      
    }
  });
  var data={
    'token_authorization':token,
    'ConversationId':retrievedNumber,
     'lead_passenger_details': JSON.stringify(leadpassengerdetail),
     'other_passenger_details': JSON.stringify(guests),
     'child_details': JSON.stringify(childs),
    'infant_details': JSON.stringify(infants),
    'extra_services_details':'',
    'other_extra_services_details':'',
    'child_extra_services_details':'',
    'revalidation_res':JSON.stringify({'Data':CheckoutFlightData}),
    'childs': IndexPageSearchData.child,
    'adults': IndexPageSearchData.adult,
    'infant': IndexPageSearchData.infant,
    'departure_date':IndexPageSearchData.DepartureDate,
    'search_rq':JSON.stringify( IndexPageSearchData),
    'search_rs': JSON.stringify({'Data':Search_response}),
    'farerules_rq': '',
    'farerules_rs':'',
    'revalidation_rq': JSON.stringify(farerequest),
    'revalidation_rs':  JSON.stringify({'Data':CheckoutFlightData}),
    'payment_details': JSON.stringify(status.data),
    'adult_price':Number(totalperson.adult)*AdultAmount,
    'child_price': Number(totalperson.child)*ChildAmount,
    'infant_price': Number(totalperson.infant)*InfantAmount,
    'total_price': ConverterTotalSum,
    'adult_price_markup': CalculateFLightMarkup(Number(totalperson.adult)*AdultAmount),
    'child_price_markup':CalculateFLightMarkup(Number(totalperson.child)*ChildAmount),
    'infant_price_markup': CalculateFLightMarkup(Number(totalperson.infant)*InfantAmount),
    'total_price_markup': MarkupSum,
    'client_commission_amount':exchangemarkup.client,
    'admin_commission_amount': exchangemarkup.admin,
    'currency': ConverterCurrency,
    'client_payable_price':Number(MarkupSum)-Number( exchangemarkup.client),
    'client_markup': clientmarkup.markup_value === undefined ? '' : clientmarkup.markup_value,
        'client_markup_type':clientmarkup.markup_type === undefined ? '' :  clientmarkup.markup_type,
        'client_commision_amount_exchange':clientmarkupprice,
        'client_without_markup_price': total,
        'client_markup_price': clientmarkupprice,
        'client_payable_price_exchange':  Number(total)+Number(clientmarkupprice),
        'client_currency': 'GBP',
        'admin_markup':adminmarkup.markup_value === undefined ? '' : adminmarkup.markup_value,
        'admin_markup_type':adminmarkup.markup_type === undefined ? '' : adminmarkup.markup_type,
        'admin_commision_amount_exchange': adminmarkupprice,
        'admin_without_markup_price': total,
        'admin_markup_price': adminmarkupprice,
        'admin_payable_price_exchange': Number(total)+Number(adminmarkupprice),
        'admin_currency': 'GBP',
    'creditAmount':MarkupSum,
    "hotel_checkout_select":JSON.stringify(checkoutdetail),
    "hotel_customer_search_data":JSON.stringify(customersearch),
  };  
 
      try {
      const response = await Axios.post('https://api.synchtravel.com/api/combine_booking_apis_new',data , {
        headers: {
        // "Access-Control-Allow-Origin": "*",
          // Required for CORS support to work
          //"Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
          // "Access-Control-Allow-Headers":
          // "Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale",
          // "Access-Control-Allow-Methods": "POST"
        } ,
        
      });
      setIsBooking(false);
        if(response.data.message==='success')
        {
            // navigation(`/Flight_invoice/${retrievedNumber}`, {
            //       state: {retrievedNumber}
            //     });
        }else{
          var data=JSON.parse( response.data.error);
          toast.info(data.Message, {
            position: toast.POSITION.TOP_RIGHT
          });
          return;

        }
      // navigate(`/hotel_detail/${id}`,{state:{index}})
    } catch (error) {
      // Handle errors here
      setIsBooking(false);

    console.log(error);
      console.error('Error:', error);
    }
}
};
const CalculateFLightMarkup=(price,check)=>{
  var admin=0;
var client=0;
  if(price !== "NaN"){
  FlightMarkup.markups.forEach((markup) => {
    if (markup.services_type === "flight" ) {
      if(markup.added_markup==="alhijaz"){

        if(markup.markup_type==='Percentage'){
          const markupValue = Number(markup.markup_value);
          const markupAmount = (Number(price) * markupValue) / 100;
          client= markupAmount;
      }else {
        client =Number(markup.markup_value);
      }

      }else if(markup.added_markup==="synchtravel"){

        if(markup.markup_type==='Percentage'){
          const markupValue = parseFloat(Number(markup.markup_value));
          const markupAmount = (Number(price) * markupValue) / 100;
          admin = markupAmount;
        }else {
          admin =Number(markup.markup_value);
        }
      }
      
    }
  });
  if(check===5){
    exchangemarkup.admin=admin;
    exchangemarkup.client=client;
  }
  var total=Number(price)+admin+client;
return total.toFixed(2);
}
};
  return (
    <>
    <WithoutLayout>
    <ToastContainer/>
      <div className='container'>
          <h1 class="section-header-main" style={{fontWeight:'30px'}}>Secure booking</h1>
          {/* <div id="price-change-display">
            <div class="hidden price-change booking-error">
              <div class="hidden price-change-up">
                <div class="alert alert-warn  priceChangeUp" role="alert">
                  <span class="icon icon-warn" aria-hidden="true"></span>
                  <h5 class="alert-title">We hate it when this happens...</h5>
                  <div class="alert-message">
                    <div class="price-change-message-avg hidden">The price of your trip has changed from 
                      <span data-price-update="oldTotalPrice">$900.48</span> to 
                      <span data-price-update="newTotalPrice">$900.48</span> average per night. Rates can change frequently. Book now to lock in this price.
                    </div>
                    <div class="price-change-message-booking ">The price of your trip has changed from 
                      <span data-price-update="oldTotalPrice">$900.48</span> to 
                      <span data-price-update="newTotalPrice">$900.48</span>. Rates can change frequently. Book now to lock in this price.
                    </div>
                  </div>
                </div>    
              </div>
              <div class="hidden price-change-down">
                <div class="alert alert-success  priceChangeDown" role="alert">
                  <span class="icon icon-success" aria-hidden="true"></span>
                  <h5 class="alert-title">Good News</h5>
                  <div class="alert-message">
                    <div class="price-change-message-avg hidden">The price of your trip decreased from 
                      <span data-price-update="oldTotalPrice">$900.48</span> to 
                      <span data-price-update="newTotalPrice">$900.48</span> average per night. Book now to secure this price.
                    </div>
                    <div class="price-change-message-booking ">The price of your trip decreased from 
                      <span data-price-update="oldTotalPrice">$900.48</span> to 
                      <span data-price-update="newTotalPrice">$900.48</span>. Book now to secure this price.
                    </div>
                  </div>
                </div>    
              </div>
            </div>    
          </div> */}
        <div className='row mt-2'>
          <div className='col-lg-8 margin-checkout'>
            <div class="uitk-spacing bex-homepage-module SimpleContainer px-0 mb-3 rounded-0">
              <div data-testid="one-key-banner-1" id="one-key-banner-1" className='px-0 rounded-0'>
                  <div className='rounded-0'>
                      <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-padded uitk-card-has-global-loyalty-standard-theme rounded-0 chech-theme" data-stid="one-key-message-card">
                          <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-spacing uitk-spacing-padding-inline-three uitk-spacing-padding-block-three rounded-0">
                              <div class="uitk-layout-flex-item-align-self-flex-start uitk-layout-flex-item">
                                  <div class="uitk-spacing uitk-spacing-margin-small-inlineend-three uitk-spacing-margin-medium-inlineend-six">
                                      <img class="uitk-mark uitk-mark-landscape-oriented" alt="" src="https://a.travel-assets.com/egds/marks/onekey__standard__always_light.svg" id="onekey__standard__always_light" />
                                  </div>
                              </div>
                              <div class="uitk-card-content-section uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                                  <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two uitk-layout-flex-flex-wrap-wrap">
                                      <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item uitk-layout-flex-item-flex-basis-88x uitk-layout-flex-item-flex-grow-1">
                                          <div class="uitk-spacing uitk-spacing-padding-inlineend-two">
                                              {/* <p class="uitk-heading uitk-heading-6 uitk-layout-flex-item uitk-text-inverse-theme"> */}
                                                <span class="one-key-loyalty-text" data-user-type="guest">Sign in or create an account to earn <strong><span data-price-update="oneKeyLoyaltyEarnModulePoints">$15.53</span></strong> in OneKeyCash™ after this trip.</span>
                                              {/* </p> */}
                                          </div>
                                      </div>
                                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-four uitk-layout-flex-item">
                                          <div class="uitk-layout-flex uitk-spacing uitk-layout-flex-item">
                                            <span class="universal-login-forward-icon" data-user-type="guest">
                                                <span class="icon icon-toggle90" aria-hidden="true" style={{color:'white', fontSize:'20px'}}><FontAwesomeIcon icon={faAngleRight} /></span>
                                              </span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
            <div class='row'>
              <div className='hotel-checkout-shadow p-3'>
                <div className='row'>
                  <div><h2 class="checkout-title-visual-alignment">Who's checking in?</h2></div>
                  <h3 class="room-title room-1-margin">
                      {checkoutdetail?.rooms_list.map((item,index)=>(
                        <span key={index} class="room-index">
                            <span class="display-segment last-segment non-smoking-icon"></span>Room {item.selected_qty}:        
                        </span>
                      ))}
                     {checkoutdetail?.rooms_list.map((item,index)=>(
                        <span key={index} class="room-details">
                          <span id="adultCount-1" class="adult-count display-segment ">{' '}{item.adults} Adults</span>
                          <span id="adultCount-1" class="adult-count display-segment ">{' '}{item.childs} Children</span>
                          <span id="smokingOption-1" class="smoking-option display-segment last-segment ">{' '}{item.board_id} </span>
                        </span>   
                      ))}    
                  </h3>
                  <div class='form-group mt-2 col-md-6'>
                    <label className='fw-bold'>Title</label>
                    <select id='inputState' name='title' value={gestinfo.title} onChange={handlegestchange} class='form-control mt-2 form-select select-styling'>
                      <option selected>Select Title</option>
                      <option value='MR'>Mr</option>
                      <option value='MRS'>Mrs</option>
                    </select>
                  </div>
                  <div class='form-group mt-2 col-md-6'>
                  <label className='fw-bold'>First Name</label>
                    <input
                      type='text'
                      class='form-control mt-2'
                      value={gestinfo.firstname}
                      name='firstname'
                      onChange={handlegestchange}
                      placeholder='First Name'
                    />
                  </div>
                  <div class='form-group mt-2 col-md-6'>
                  <label className='fw-bold'>Last Name</label>
                    <input
                      type='text'
                      class='form-control mt-2'
                      value={gestinfo.lastname}
                      name='lastname'
                      onChange={handlegestchange}
                      placeholder='Last Name'
                    />
                  </div>
                  <div class='form-group mt-2 col-md-6'>
                  <label className='fw-bold'>Email</label>
                    <input
                      type='email'
                      class='form-control mt-2'
                      value={gestinfo.email}
                      name='email'
                      onChange={handlegestchange}
                      placeholder='Email'
                    />
                  </div>
                  <div class='form-group mt-4 row'>
                    <label for='inputEmail3' class='col-sm-2 col-4 col-form-label'>
                      Date Of Birth
                    </label>
                    <div class='col-sm-10 col-8'>
                      <input
                        type='date'
                        class='form-control'
                        value={gestinfo.dateofbirth}
                        name='dateofbirth'
                        onChange={handlegestchange}
                        placeholder='Email'
                      />
                    </div>
                  </div>
                  <div class='form-group field-icon-wrap mt-2 col-md-6'>
                     <label className='fw-bold'>Nationality</label>
                      <Select
                        options={countryList}
                        isSearchable={true}
                        className="mt-2"
                        onChange={handleCountryChange}
                        value={selectedCountry}
                        getOptionLabel={(option) => (
                            <div>
                            <img
                                src={option.flag}
                                alt={option.label}
                                style={{ width: '20px', marginRight: '8px' }}
                            />
                            {option.label} ({option.phoneCode})
                            </div>
                        )}
                        getOptionValue={(option) => option.value}
                        />
                  </div>
                  <div class='form-group mt-2 col-md-6'>
                  <label className='fw-bold'>Phone Number</label>
                    <input
                      type='text'
                      class='form-control mt-2'
                      value={gestinfo.phonenumber}
                      name='phonenumber'
                      onChange={handlegestchange}
                      placeholder='Phone Number'
                    />
                  </div>
                  {storedData !==null && (
                    <>
                  <div class='form-group mt-2 col-md-6'>
                  <label className='fw-bold'>Gender</label>
                    <select id='inputState' name='gender' value={gestinfo.gender} onChange={handlegestchange} class='form-control form-select mt-2 select-styling'>
                    <option selected>Select Gender</option>
                            <option value='M'>Male</option>
                            <option value='F'>Female</option>
                    </select>
                  </div>
                  <div class='form-group mt-2 col-md-6'>
                  <label className='fw-bold'>Passport Number</label>
                    <input
                      type='text'
                      class='form-control mt-2'
                      value={gestinfo.pno}
                      name='pno'
                      onChange={handlegestchange}
                      placeholder='Passport Number'
                    />
                  </div>
                  <div class='form-group mt-2 col-md-6'>
                    <label className='fw-bold'>Passport Expiry</label>
                    <input
                      type='date'
                      value={gestinfo.pexpiry}
                      onChange={handlegestchange}
                      class='form-control mt-2'
                      name='pexpiry'
                    />
                  </div>
                  <div class='form-group mt-2 col-md-6'>
                    <label className='fw-bold'>Country</label>
                    <Select
                                        options={countryList}
                                        isSearchable={true}
                                        className="mt-2"
                                        onChange={handleCountryChangeother}
                                        value={selectedCountryother}
                                        getOptionLabel={(option) => (
                                            <div>
                                            <img
                                                src={option.flag}
                                                alt={option.label}
                                                style={{ width: '20px', marginRight: '8px' }}
                                            />
                                            {option.label} ({option.phoneCode})
                                            </div>
                                        )}
                                        getOptionValue={(option) => option.value}
                                        />
                  </div>
                  <div class='form-group mt-2 col-md-6'>
                  <label className='fw-bold'>Pstal Code</label>
                    <input
                      type='number'
                      class='form-control mt-2'
                      value={gestinfo.postalcode}
                      name='postalcode'
                      onChange={handlegestchange}
                      placeholder='Postal Code'
                    />
                  </div>
                  </>
                  )}
                </div>
              </div>
            </div>
            {(adultCount-1 !==0 || childCount !==0 || infantCount !==0) && (
            <div class='row'>
              <div className='hotel-checkout-shadow mt-4 p-3'>
                <div className='d-flex justify-content-between'>
                  <h4>Other Guest Information {storedData !== null ? '' : '(Optional)'}</h4>
                  <span onClick={ShowOtherGuestForm} style={{cursor:'pointer'}} className='mt-auto mb-auto'><FontAwesomeIcon icon={faAngleDown}/></span>
                </div>
                {otherGuestData && (
                  <div>
                  {adultsArray.map((_,index)=>(
                  <div key={index} className='row pt-2'>
                    <h5 className='mb-2'>Guest #{index+2}</h5>
                  <div className='col-md-3 mt-2'>
                  <label className='fw-bold'>Title</label>
                  <select value={guests.title} id={`inputState_${index}`} name='title'  onChange={(e) => otherGuestInfo(e, index,1)}  class='form-control mt-2 form-select select-styling'>
                        <option selected>Select Title</option>
                        <option value='MR'>Mr.</option>
                        <option value='MRS'>Mrs.</option>
                      </select>
                  </div>
                    <div className='col-md-3 mt-2'>
                    <label className='fw-bold'>First Name</label>
                    <input
                        type='text'
                        class='form-control mt-2'
                        value={guests.firstname}
                        name='firstname'
                        placeholder='First Name'
                        onChange={(e) => otherGuestFirstName(e, index,1)}
                      />
                      </div>
                      <div className='col-md-3 mt-2'>
                      <label className='fw-bold'>Last Name</label>
                    <input
                        type='text'
                        class='form-control mt-2'
                        value={guests.lastname}
                        name='lastname'
                        placeholder='Last Name'
                        onChange={(e) => otherGuestLastName(e, index,1)}
                      />
                      </div>
                      <div className='col-md-3 mt-2'>
                      <label className='fw-bold'>Nationality</label>
                      <select
                        value={guests.nationality} // Set the selected value from the state
                        onChange={(e) => otherGuestNationality(e, index,1)}
                        className='form-control form-select mt-2 select-styling'
                        aria-label='Default select example'
                      >
                        <option selected>Nationality</option>
                        {countryListsimple.map((item, index) => (
                          <option key={item.id} value={item.iso2}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      </div>
                      {storedData !== null && (
                        <>
                      <div className='col-md-3 mt-2'>
                    <label className='fw-bold'>Date of Birth</label>
                    <input
                        type='date'
                        class='form-control mt-2'
                        value={guests.dob}
                        name='dob'
                        onChange={(e) => otherGuestdob(e, index,1)}
                      />
                      </div>
                      <div className='col-md-3 mt-2'>
                            <label className='fw-bold'>Gender:</label>
                            <select
                              value={guests.gender}
                              name='gender'
                              onChange={e => otherGuestGender(e, index,1)}
                              class='form-control  mt-2 form-select select-styling'
                            >
                              <option selected>Select Gender</option>
                              <option value='M'>Male</option>
                              <option value='F'>Female</option>
                            </select>
                          </div>
                          <div className='col-md-3 mt-2'>
                    <label className='fw-bold'>Passport Number</label>
                    <input
                        type='text'
                        class='form-control mt-2'
                        value={guests.pnumber}
                        name='pnumber'
                        placeholder='Passport Number'
                        onChange={(e) => otherGuestPNumber(e, index,1)}
                      />
                      </div>
                      <div className='col-md-3 mt-2'>
                    <label className='fw-bold'>Passport Expiry</label>
                    <input
                        type='date'
                        class='form-control mt-2'
                        value={guests.pexpiry}
                        name='pexpiry'
                        onChange={(e) => otherGuestPExpiry(e, index,1)}
                      />
                      </div>
                      </>
                      )}
                      </div>
                      ))}
                      {childsArray.map((_,index)=>(
                  <div key={index} className='row pt-2'>
                    <h5 className='mb-2'>Child #{index+1}</h5>
                  <div className='col-md-3 mt-2'>
                  <select value={guests.title} id={`inputState_${index}`} name='title'  onChange={(e) => otherGuestInfo(e, index,2)}  class='form-control form-select select-styling'>
                        <option selected>Select Title</option>
                        <option value='MSTR'>Mr.</option>
                        <option value='MSTRS'>Mrs.</option>
                      </select>
                  </div>
                    <div className='col-md-3 mt-2'>
                    <input
                        type='text'
                        class='form-control'
                        name='firstname'
                        placeholder='First Name'
                        onChange={(e) => otherGuestFirstName(e, index,2)}

                      />
                      </div>
                      <div className='col-md-3 mt-2'>
                    <input
                        type='text'
                        class='form-control'
                        name='firstname'
                        placeholder='Last Name'
                        onChange={(e) => otherGuestLastName(e, index,2)}
                      />
                      </div>
                      <div className='col-md-3 mt-2'>
                      <select
                        value={childs.nationality} // Set the selected value from the state
                        onChange={(e) => otherGuestNationality(e, index,2)}
                        className='form-control form-select select-styling'
                        aria-label='Default select example'
                      >
                        <option selected>Nationality</option>
                        {countryListsimple.map((item, index) => (
                          <option key={item.id} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      </div>
                      {storedData !== null && (
                        <>
                      <div className='col-md-3 mt-2'>
                    <label className='fw-bold'>Date of Birth</label>
                    <input
                        type='date'
                        class='form-control mt-2'
                        value={guests.dob}
                        name='dob'
                        onChange={(e) => otherGuestdob(e, index,2)}
                      />
                      </div>
                      <div className='col-md-3 mt-2'>
                            <label className='fw-bold'>Gender:</label>
                            <select
                              value={guests.gender}
                              name='gender'
                              onChange={e => otherGuestGender(e, index,2)}
                              class='form-control  mt-2 form-select select-styling'
                            >
                              <option selected>Select Gender</option>
                              <option value='M'>Male</option>
                              <option value='F'>Female</option>
                            </select>
                          </div>
                          <div className='col-md-3 mt-2'>
                    <label className='fw-bold'>Passport Number</label>
                    <input
                        type='text'
                        class='form-control mt-2'
                        value={guests.pnumber}
                        name='pnumber'
                        placeholder='Passport Number'
                        onChange={(e) => otherGuestPNumber(e, index,2)}
                      />
                      </div>
                      <div className='col-md-3 mt-2'>
                    <label className='fw-bold'>Passport Expiry</label>
                    <input
                        type='date'
                        class='form-control mt-2'
                        value={guests.pexpiry}
                        name='pexpiry'
                        onChange={(e) => otherGuestPExpiry(e, index,2)}
                      />
                      </div>
                      </>
                      )}
                      </div>
                      ))}
                        {infantsArray.map((_,index)=>(
                  <div key={index} className='row pt-2'>
                    <h5 className='mb-2'>Infant #{index+1}</h5>
                  <div className='col-md-3 mt-2'>
                  <select value={guests.title} id={`inputState_${index}`} name='title'  onChange={(e) => otherGuestInfo(e, index,3)}  class='form-control form-select select-styling'>
                        <option selected>Select Title</option>
                        <option value='MSTR'>Mr.</option>
                        <option value='MSTRS'>Mrs.</option>
                      </select>
                  </div>
                    <div className='col-md-3 mt-2'>
                    <input
                        type='text'
                        class='form-control'
                        name='firstname'
                        placeholder='First Name'
                        onChange={(e) => otherGuestFirstName(e, index,3)}

                      />
                      </div>
                      <div className='col-md-3 mt-2'>
                    <input
                        type='text'
                        class='form-control'
                        name='firstname'
                        placeholder='Last Name'
                        onChange={(e) => otherGuestLastName(e, index,3)}
                      />
                      </div>
                      <div className='col-md-3 mt-2'>
                      <select
                        value={infants.nationality} // Set the selected value from the state
                        onChange={(e) => otherGuestNationality(e, index,3)}
                        className='form-control form-select select-styling'
                        aria-label='Default select example'
                      >
                        <option selected>Nationality</option>
                        {countryListsimple.map((item, index) => (
                          <option key={item.id} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      </div>
                      {storedData !== null && (
                        <>
                      <div className='col-md-3 mt-2'>
                    <label className='fw-bold'>Date of Birth</label>
                    <input
                        type='date'
                        class='form-control mt-2'
                        value={guests.dob}
                        name='dob'
                        onChange={(e) => otherGuestdob(e, index,3)}
                      />
                      </div>
                      <div className='col-md-3 mt-2'>
                            <label className='fw-bold'>Gender:</label>
                            <select
                              value={guests.gender}
                              name='gender'
                              onChange={e => otherGuestGender(e, index,3)}
                              class='form-control  mt-2 form-select select-styling'
                            >
                              <option selected>Select Gender</option>
                              <option value='M'>Male</option>
                              <option value='F'>Female</option>
                            </select>
                          </div>
                          <div className='col-md-3 mt-2'>
                    <label className='fw-bold'>Passport Number</label>
                    <input
                        type='text'
                        class='form-control mt-2'
                        value={guests.pnumber}
                        name='pnumber'
                        placeholder='Passport Number'
                        onChange={(e) => otherGuestPNumber(e, index,3)}
                      />
                      </div>
                      <div className='col-md-3 mt-2'>
                    <label className='fw-bold'>Passport Expiry</label>
                    <input
                        type='date'
                        class='form-control mt-2'
                        value={guests.pexpiry}
                        name='pexpiry'
                        onChange={(e) => otherGuestPExpiry(e, index,3)}
                      />
                      </div>
                      </>
                      )}
                      </div>
                      ))}
                    </div>
                  )}
              </div>
              </div>
            )}
             {/* <article id="payments" class="segment no-target  page-2 ">
                <h2 class="checkout-title-visual-alignment">Payment method</h2>
                <fieldset class="single-payment-option submit-multiple-options ">
                  <div>
                    <div class="alert alert-error  hidden" id="bin-validation-payment-message" role="alert">
                       <span class="icon icon-warn" aria-hidden="true"></span>
                    </div>
                    <h2 class="module-title module-title-visual-alignment  module-title-urgency  visuallyhidden">
                        How would you like to reserve?
                    </h2>
                    <div id="purchase-payment">
                        <input id="payment-method-selection-value" type="hidden" name="paymentMethodSelectionValue" value="0"/>
                        <input id="cko-user-state" type="hidden" name="ckoUserState" value="GUEST"/>
                        <ul role="tablist" class="payment-tabs single-payment-option">
                          <li role="presentation" data-cko-click="togglePaymentTab,trackOmniture" data-cko-space-click="togglePaymentTab,trackOmniture" data-cko-tab-id="payment-type-creditcard" data-cko-payment-option="CreditCard" data-cko-india-tcs-enabled="false" class="tab-link payment-option active ">
                              <a role="tab" href="javascript:void(null);" aria-selected="true" class="notranslate">Debit/Credit Card</a>
                          </li>
                        </ul>
                  <div id="payment-type-creditcard" class="tab payment-type card-fees-enabled active card-type-hidden">
                    <div class="payment-statement-without-bg">
                        <span class="no-charge-unless-insurance">
                  <span class="icon icon-success" aria-hidden="true"></span><strong>$0.00</strong> due now for your hotel. Your card will only be charged for <span id="insurance-display-name-on-payment"></span> today.        </span>
                        <span class="no-charge-statement">
                  <span class="icon icon-success" aria-hidden="true"></span>$0.00 due now. Payment information is only needed to hold your reservation.        </span>
                    </div>
                    <div class="credit_card_section credit_card_section_mobile_breakpoint" data-hide-zero-lcc-fee="true">
                            <div>
                                <div class="standard-payments-mode">
                                            <ul class="cc-logos">
                                                        <li class="logo cc-master-card" data-brand-name="MasterCard" data-payment-submethod="MasterCard" data-resp-card="master_card">
                  <figure class="image aspect-ratio16-9 resize-shrink" data-media-type="image" data-src="https://a.travel-assets.com/dms-svg/payments/cards-cc_master_card.svg" data-aspect-ratio="16-9" data-class="tile-media">
                    <img src="https://a.travel-assets.com/dms-svg/payments/cards-cc_master_card.svg" onerror="this.src=this.src.replace(/dms-svg/,'dms-png').replace(/\.svg($|\?)/, '.png$1');this.onerror='';" alt="MasterCard" class="tile-media" border="0" role="img"/></figure>                                        </li>
                                                        <li class="logo cc-american-express" data-brand-name="AmericanExpress" data-payment-submethod="AmericanExpress" data-resp-card="american_express">
                  <figure class="image aspect-ratio16-9 resize-shrink" data-media-type="image" data-src="https://a.travel-assets.com/dms-svg/payments/cards-cc_american_express.svg" data-aspect-ratio="16-9" data-class="tile-media">
                    <img src="https://a.travel-assets.com/dms-svg/payments/cards-cc_american_express.svg" onerror="this.src=this.src.replace(/dms-svg/,'dms-png').replace(/\.svg($|\?)/, '.png$1');this.onerror='';" alt="AmericanExpress" class="tile-media" border="0" role="img"/></figure>                                        </li>
                                                        <li class="logo cc-visa" data-brand-name="Visa" data-payment-submethod="Visa" data-resp-card="visa">
                  <figure class="image aspect-ratio16-9 resize-shrink" data-media-type="image" data-src="https://a.travel-assets.com/egds/marks/payment__visa.svg" data-alt="Visa" data-aspect-ratio="16-9" data-class="tile-media">
                    <img src="https://a.travel-assets.com/egds/marks/payment__visa.svg" alt="Visa" class="tile-media"/></figure>
                                                        </li>
                                            </ul>



                                </div>
                            </div>

                            <div id="cc-module-error" class="booking-error module-error" data-module-errors="oms_travcc_invalid_ccnumber,oms_travcc_invalid_expiration_date,oms_card_declined,oms_card_not_activated,oms_travcc_cantvalidate,oms_travcc_invalid_ccnumber,oms_travcc_limitexceeded,oms_travcc_pickup,oms_travcc_referral,card_security_code_error_529,card_number_error_invalid,payment_error,card_type_error,oms_card_refused,oms_bad_address_and_zip,oms_travcc_authorization__network_comms_error,oms_msterr_travcc_unkown_failure,invalid_card_number_error,duplicate_payment_instrument_ids_provided,gulfstream_card_error,gulfstream_card_decline,gulfstream_crav_error,gulfstream_payment_failure,gulfstream_crav_approval,gulfstream_card_invalid,gulfstream_invalid_card_error,gulfstream_expired_card_error,gulfstream_lost_card_error,gulfstream_payment_declined,invalid_zip_or_postal_code,invalid_country_code,invalid_billing_state,debit_card_not_accepted,gulfstream_generic_address_error">
                  <span class="icon icon-warn" aria-hidden="true"></span>                <span class="alert-message"></span>
                            </div>

                        <form autocomplete="off"> 

                        <fieldset>
                            <div class="standard-payments-mode">

                                        <span class="hidden" id="paymentOptionBindingIndex" data-paymentoption-index="0"></span>

                                    <div class="card-option">
                                        <div class="card-info">
                                                <div class="card-method-select ">
                                                    <fieldset>
                                                        <legend class="visuallyhidden">Payment options Select a card</legend>
                                                        <label class="check"><input type="radio" name="stored_new_card_toggle" data-cko-click="toggleCard,trackOmniture" data-cko-card-method="stored-card" data-cko-rfrr-id="CKO.RADIO.STOREDCARD" class="gb-whitelist "/><span class="inline-label check">Use a stored card</span></label>
                                                        <label class="check"><input class="use-new-card" type="radio" name="stored_new_card_toggle" data-cko-click="toggleCard,trackOmniture" data-cko-card-method="new-card" data-cko-rfrr-id="CKO.RADIO.NEWCARD" checked=""/><span class="use-new-card-span inline-label check">Use a different card</span></label>
                                                    </fieldset>
                                                </div>
                                            <div class="stored-card-menu-container ">
                                                <label class="select icon icon-toggle180 cc-stored-card-dropdown">
                                                        <span class="label">Card<span class="required-field" aria-hidden="true">*</span></span>
                                                    <span class="visuallyhidden">Payment options Select a stored card</span>
                                                    <select class="stored-card-menu cko-field gb-whitelist" name="creditCards[0].storedCreditCardPaymentInstrumentId" data-cko-change="updateCardView,updateSelectedStoredCard,getFeaturesForStoredCard,updateSplitStoredCards,fieldValidation,trackOmniture,billingCountryValidation" data-cko-click="trackOmniture" data-cko-rfrr-id="CKO.STOREDCARDNUMBER" data-custom-rules="storedCreditCardType" data-validation-message="Sorry, that card isn't accepted. Please use a different card." data-approved-country-code="" data-approved-country-validation-message="does not allow purchases for credit cards that do not have a billing address in . Please select or add a card with a billing address in , or select another airline." required="" aria-required="true" aria-invalid="false" data-cko-enter-click="submitForm">
                                                    </select>
                    
                                                </label>
                                                    <div class="lc-stored-card-fee lc-card-fee-display hidden">
                                                        <p class="lc-card-fee-text">
                  Airline card fee:                                         <span class="lc-card-fee-amount"></span></p>
                                                    </div>
                                            </div>
                                        </div>

                                        <div class="card-method stored-card " data-tl-payment-option="Stored Credit Card" data-tl-split-payment-option="Stored Split">
                                            <div class="credit-card-item" data-tl-split-payment-prefix="creditCards[0].">
                                                <input type="hidden" class="stored-card-field" name="creditCards[0].useStoredCreditCard" value="true"/>
                                                <input type="hidden" class="stored-card-field sub-method" name="creditCards[0].paymentSubMethod" value=""/>
                                                <input type="hidden" class="stored-card-field card-holder-name" name="creditCards[0].cardholder_name" value=""/>
                                                <input type="hidden" class="stored-card-field payment-item-correlation-id" name="creditCards[0].identifyingFieldName"/>
                                                    <div class="text cc-cvv helper-text-available">
                                                        <span class="label">Security code<span class="required-field" aria-hidden="true">*</span></span>
                                                            <div class="help-icon-wrapper">
                        <a href="#what-is-cvv-stored-card" data-control="tooltip" role="button" data-width="225" data-arrow="true" data-fade="out" data-manual="none" data-show-tooltip="true"><span class="icon icon-help  cc-cvv-help-icon" aria-hidden="true"></span><span class="alt">What is a card identification number?</span></a><div id="what-is-cvv-stored-card" class="hidden"><div class="cvv-tooltip-nonamex-wrapper"><p><span aria-hidden="true">All cards (except American Express)</span><span class="visuallyhidden">All cards (except American Express) have the security code on the back right of the card.</span></p><div class="cc-cvv-example cc-cvv-nonamex-updated">
                          <img src="https://a.travel-assets.com/dms-svg/payments/cards-cvv_nonamex.svg" onerror="this.src=this.src.replace(/dms-svg/,'dms-png').replace(/\.svg($|\?)/, '.png$1');this.onerror='';" alt="All cards (except American Express) have the security code on the back right of the card." class="tile-media" border="0" role="img"/></div></div><div class="cvv-tooltip-amex-wrapper"><p><span aria-hidden="true">American Express</span><span class="visuallyhidden">American Express has the security code on the front right of the card.</span></p><div class="cc-cvv-example cc-cvv-amex-updated">
                            <img src="https://a.travel-assets.com/dms-svg/payments/cards-cvv_amex.svg" onerror="this.src=this.src.replace(/dms-svg/,'dms-png').replace(/\.svg($|\?)/, '.png$1');this.onerror='';" alt="American Express has the security code on the front right of the card." class="tile-media" border="0" role="img"/></div></div></div>
                                                            </div>
                                                        <label class="text cvv-field">
                                                            <span class="visuallyhidden">Security code</span>
                                                            <input id="stored_card_security_code" class="text cko-field cvv cvv-1" type="tel" data-cko-rfrr-id="CKO.STOREDCARDCVV" data-cko-blur="fieldValidation,trackOmniture" data-hide-cvv-module="false" name="creditCards[0].stored_card_security_code" value="" placeholder="" size="4" data-validation-message="Please enter a valid card security code." maxlength="4" data-field-highlight-errors="creditCards[0].card_security_code_error_529" required="" aria-required="true" pattern="^\d{3,4}$" aria-invalid="false" data-cko-enter-click="submitForm" data-cko-change="setEditedFieldState"/>
                    
                                                        </label>
                                                    </div>
                                            </div>
                                        </div>
                                        <div class="card-method new-card card-active" data-tl-payment-option="New Credit Card" data-tl-split-payment-option="New Split">
                                            <div class="credit-card-item " data-tl-split-payment-prefix="creditCards[0].">
                                                <fieldset>
                                                    <legend class="visuallyhidden">Enter payment information</legend>
                                                    <label class="text cc-cardholder-name">
                                                        <span class="label">Name on Card<span class="required-field" aria-hidden="true">*</span></span>
                                                        <input type="text" class="text billing-cardholder-name cko-field cardholder-above-cardname gb-whitelist" name="creditCards[0].cardholder_name" placeholder="" data-tealeaf-name="cardHolderName_1" data-tealeaf-error-id="9005" data-cko-blur="fieldValidation,trackOmniture" data-cko-change="clearCardDetails" data-cko-rfrr-id="HOT.CKO.CARDHOLDERNAME" data-validation-message="Please enter the cardholder's name exactly as it appears on the card." data-field-highlight-errors="creditCards[0].oms_card_refused,creditCards[0].oms_travcc_limitexceeded,creditCards[0].gulfstream_card_error,creditCards[0].gulfstream_card_decline,creditCards[0].gulfstream_payment_failure,creditCards[0].gulfstream_crav_approval,creditCards[0].gulfstream_card_invalid,creditCards[0].gulfstream_payment_declined,debit_card_not_accepted" maxlength="61" required="" aria-required="true" pattern="^[\x20-\xFF]*[\x21-\xFF]+\s+[\x21-\xFF]+[\x20-\xFF]*$" aria-invalid="false" data-cko-enter-click="submitForm"/>
                                                    </label>
                                                    <label class="text cc-number ">
                                                      <span class="input-box-container">
                                                          <span class="label">Debit/Credit card number<span class="required-field" aria-hidden="true">*</span></span>
                                                          <img aria-hidden="true" class=" inline-higlighted-cc-logo" src="" onerror="this.style.display='none'
                                                          " style={{display: 'none'}}/>
                                                          <input type="tel" id="creditCardInput" data-cko-keyup="showCreditCardLogo,creditCardInputChange" class="text cko-field" data-cko-blur="fieldValidation,creditCardBlur,getFeaturesForNewCard,trackOmniture" data-cko-input="getFeaturesForNewCard" data-cko-change="clearCardDetails" data-cko-focus="getFeaturesForNewCard" data-cko-rfrr-id="HOT.CKO.CARDNUMBER" name="creditCards[0].card_number" data-paymentinstrumentid="" data-field-highlight-errors="creditCards[0].oms_travcc_invalid_ccnumber,creditCards[0].card_number_error_invalid,creditCards[0].oms_card_refused,creditCards[0].oms_travcc_limitexceeded,creditCards[0].card_type_error,creditCards[0].invalid_card_number_error,creditCards[0].gulfstream_card_error,creditCards[0].gulfstream_card_decline,creditCards[0].gulfstream_payment_failure,creditCards[0].gulfstream_crav_approval,creditCards[0].gulfstream_card_invalid,creditCards[0].gulfstream_invalid_card_error,gulfstream_invalid_card_error,creditCards[0].gulfstream_payment_declined,debit_card_not_accepted" value="" placeholder="0000 0000 0000 0000" data-validation-message="Please enter a valid card number." data-credit-card-type-validation-message="Sorry, that card isn't accepted. Please use a different card." maxlength="23" data-custom-rules="creditCard,validateDuplicateCardEntry" data-validation-message-list="useDifferentCards:To continue, please use 2 different cards" data-tealeaf-name="cardNumber1" data-tealeaf-error-id="9001" data-has-flexed-payment-options="false" required="" aria-required="true" pattern="\d| {15,22}" data-credit-card-payment-types="" data-credit-card-sub-method-vfop-switch="false" data-improved-cvv-treatment-enabled="true" data-threedsv2-refactor-enabled="true" data-spacing-enabled="true" aria-invalid="false" data-cko-enter-click="submitForm"/>
                                                      </span>
                                                      <input type="hidden" class="identifying-name-field" name="creditCards[0].identifyingFieldName"/>
                                                    </label>
                                                    <label class="confidence-message">
                                                        <span class="message">Only needed to guarantee your booking                                        </span>
                                                    </label>
                                                    <div class="lc-card-fee lc-card-fee-display hidden">
                                                        <p class="lc-card-fee-text">Airline card fee:<span class="lc-card-fee-amount"></span></p>
                                                    </div>
                                                    <label class="select icon icon-toggle180 cc-type-dropdown hidden" aria-hidden="true">
                                                        <span class="label">Card type<span class="required-field" aria-hidden="true">*</span></span>
                                                        <select name="creditCards[0].paymentSubMethod" class="card-type-select cko-field gb-whitelist" data-field-highlight-errors="creditCards[0].oms_card_refused,creditCards[0].oms_travcc_limitexceeded,creditCards[0].card_type_error,creditCards[0].gulfstream_card_error,creditCards[0].gulfstream_card_decline,creditCards[0].gulfstream_payment_failure,creditCards[0].gulfstream_crav_approval,creditCards[0].gulfstream_card_invalid,creditCards[0].gulfstream_payment_declined" required="" aria-required="true" data-cko-change="clearCardDetails" aria-label="Card type" aria-invalid="false" data-cko-enter-click="submitForm">
                                                                    <option value="MasterCard">MasterCard</option>
                                                                    <option value="AmericanExpress">AmericanExpress</option>
                                                                    <option value="Visa">Visa</option>
                                                        </select>
                                                    </label>
                                                    <fieldset class="group-validation cc-expiry-fieldset" data-validation-message="Please choose a valid month and year." data-error-message-id="card-expiry">
                                                        <legend><span class="group-label">Expiration date<span class="required-field" aria-hidden="true">*</span></span></legend>
                                                        <label class="select icon icon-toggle180 cc-expiry-dropdown">
                                                            <span class="label visuallyhidden">Expiration Month</span>
                                                            <select name="creditCards[0].expiration_month" class="cko-field cc-expiry-month gb-whitelist" data-expiration-text-field-enabled="false" data-tealeaf-name="expirationMonth_1" data-tealeaf-error-id="9002" data-cko-blur="fieldValidation,trackOmniture" data-cko-change="clearCardDetails" data-cko-rfrr-id="HOT.CKO.CARDEXPIREMONTH" data-custom-rules="ccExpiry" data-field-highlight-errors="creditCards[0].oms_travcc_invalid_expiration_date,creditCards[0].oms_card_refused,creditCards[0].oms_travcc_limitexceeded,creditCards[0].card_type_error,creditCards[0].gulfstream_card_error,creditCards[0].gulfstream_card_decline,creditCards[0].gulfstream_payment_failure,creditCards[0].gulfstream_crav_approval,creditCards[0].gulfstream_card_invalid,creditCards[0].gulfstream_payment_declined,creditCards[0].cruise_credit_card_expired,debit_card_not_accepted" required="" aria-required="true" aria-invalid="false" data-cko-enter-click="submitForm">
                                                                <option value="" selected="">Month</option>
                  <option value="1">01-Jan</option>
                  <option value="2">02-Feb</option>
                  <option value="3">03-Mar</option>
                  <option value="4">04-Apr</option>
                  <option value="5">05-May</option>
                  <option value="6">06-Jun</option>
                  <option value="7">07-Jul</option>
                  <option value="8">08-Aug</option>
                  <option value="9">09-Sep</option>
                  <option value="10">10-Oct</option>
                  <option value="11">11-Nov</option>
                  <option value="12">12-Dec</option>
                                                            </select>
                    
                                                        </label>
                                                        <label class="select icon icon-toggle180 cc-expiry-dropdown">
                                                            <span class="label visuallyhidden">Expiration Year</span>
                                                            <select name="creditCards[0].expiration_year" class="cko-field cc-expiry-year gb-whitelist" data-expiration-text-field-enabled="false" data-tealeaf-name="expirationYear_1" data-tealeaf-error-id="9003" data-cko-blur="fieldValidation,trackOmniture" data-cko-change="clearCardDetails" data-cko-rfrr-id="HOT.CKO.CARDEXPIREYEAR" data-custom-rules="ccExpiry" data-field-highlight-errors="creditCards[0].oms_travcc_invalid_expiration_date,creditCards[0].oms_card_refused,creditCards[0].oms_travcc_limitexceeded,creditCards[0].card_type_error,creditCards[0].gulfstream_card_error,creditCards[0].gulfstream_card_decline,creditCards[0].gulfstream_payment_failure,creditCards[0].gulfstream_crav_approval,creditCards[0].gulfstream_card_invalid,creditCards[0].gulfstream_payment_declined,creditCards[0].cruise_credit_card_expired,debit_card_not_accepted" required="" aria-required="true" aria-invalid="false" data-cko-enter-click="submitForm">
                                                                <option value="" selected="">Year</option>
                                                                    <option value="2024">2024</option>
                                                                    <option value="2025">2025</option>
                                                                    <option value="2026">2026</option>
                                                                    <option value="2027">2027</option>
                                                                    <option value="2028">2028</option>
                                                                    <option value="2029">2029</option>
                                                                    <option value="2030">2030</option>
                                                                    <option value="2031">2031</option>
                                                                    <option value="2032">2032</option>
                                                                    <option value="2033">2033</option>
                                                                    <option value="2034">2034</option>
                                                                    <option value="2035">2035</option>
                                                                    <option value="2036">2036</option>
                                                                    <option value="2037">2037</option>
                                                                    <option value="2038">2038</option>
                                                                    <option value="2039">2039</option>
                                                                    <option value="2040">2040</option>
                                                                    <option value="2041">2041</option>
                                                                    <option value="2042">2042</option>
                                                                    <option value="2043">2043</option>
                                                            </select>
                    
                                                        </label>
                                                    </fieldset>
                                                    <div class="text cc-cvv cc-optimization helper-text-available improvedCVVTreatment">

                                                            <div class="cvv-label-and-field-wrapper">
                                                            <span class="label" aria-hidden="true">Security code<span class="required-field" aria-hidden="true">*</span></span>
                <label class="text cvv-field">
                    <span class="visuallyhidden">Security code</span>
                    <input class="text cko-field cvv " aria-describedby="cvv-unknown-wrapper" id="new_cc_security_code" type="tel" data-tealeaf-name="cardSecurityCode_1" data-tealeaf-error-id="9004" data-hide-cvv-signed-in-new-card="false" data-cko-blur="fieldValidation,trackOmniture,hideCreditCardImage" data-cko-focus="showCreditCardImage" data-cko-rfrr-id="HOT.CKO.CidEntered" name="creditCards[0].new_card_security_code" value="" placeholder="" size="4" data-validation-message="Please enter a valid card security code." maxlength="4" data-field-highlight-errors="creditCards[0].card_security_code_error_529,creditCards[0].oms_card_refused,creditCards[0].oms_travcc_limitexceeded,creditCards[0].card_type_error,creditCards[0].gulfstream_payment_declined,creditCards[0].gulfstream_card_error,creditCards[0].gulfstream_card_decline,creditCards[0].gulfstream_payment_failure,creditCards[0].gulfstream_crav_approval,creditCards[0].gulfstream_card_invalid,debit_card_not_accepted" data-field-clear-errors="debit_card_not_accepted" required="" aria-required="true" pattern="^\d{3,4}$" aria-invalid="false" data-cko-enter-click="submitForm" data-cko-change="setEditedFieldState"/>
                
                </label>
                                                            </div>

                <div class="cvv-amex-wrapper improvedCVVTreatment hidden">
                    <p id="cvv-amex-wrapper" aria-live="polite" role="region">
                        <span class="visuallyhidden">American Express has 4 digit security code on the front right of the card.</span>
                        </p><div class="cc-cvv-example cc-cvv-amex-updated">
              <img src="https://a.travel-assets.com/dms-svg/payments/cards-cvv_amex.svg" onerror="this.src=this.src.replace(/dms-svg/,'dms-png').replace(/\.svg($|\?)/, '.png$1');this.onerror='';" alt="American Express has 4 digit security code on the front right of the card." class="tile-media" border="0" role="img"/> 
                      </div>
                        <span class="cvv-card-text">
              4 digit security code            </span>
                    <p></p>
                </div>
                <div class="cvv-non-amex-wrapper improvedCVVTreatment hidden waiting-for-focus">
                    <p id="cvv-non-amex-wrapper" aria-live="polite" role="region">
                        </p><div class="cc-cvv-example cc-cvv-nonamex-updated">
              <img src="https://a.travel-assets.com/dms-svg/payments/cards-cvv_nonamex.svg" onerror="this.src=this.src.replace(/dms-svg/,'dms-png').replace(/\.svg($|\?)/, '.png$1');this.onerror='';" alt="All cards (except American Express) have 3 digit security code on the back right of the card" class="tile-media" border="0" role="img"/>          
              </div>
                        <span class="cvv-card-text">
              3 digit security code            </span>
                    <p></p>
                </div>
                                                            <div class="cvv-unknown-wrapper hidden">
                                                                <p id="cvv-unknown-wrapper" aria-live="polite" role="region">
                                                                    <span class="visuallyhidden">
              All cards (except American Express) have 3 digit security code on the back right of the card. American Express has 4 digit security code on the front right of the card                                                        </span>
                                                                </p>
                                                            </div>
                                                    </div>
                                                    <label class="text cc-zipcode cc-optimization hidden">
                                                        <span class="label">Billing ZIP code<span class="required-field" aria-hidden="true">*</span></span>
                                                        <span class="zip-code-label hidden">ZIP code </span>
                                                        <input type="tel" class="text cko-field pwp-insurance-zipcode gb-whitelist" name="creditCards[0].hiddenzipcode" data-tealeaf-name="zipcode_1" data-tealeaf-error-id="9006" data-cko-blur="fieldValidation,trackOmniture" data-cko-change="clearCardDetails" data-cko-rfrr-id="HOT.CKO.BILLINGZIPCODE" data-validation-message="Please enter a ZIP code." data-field-highlight-errors="creditCards[0].oms_bad_address_and_zip,debit_card_not_accepted" value="" placeholder="" maxlength="10" required="" aria-required="true" pattern="^[\u0020-\u00ff]*$" aria-invalid="false" data-cko-enter-click="submitForm"/>
                                                    </label>
                                                    <div class="save-card-wrapper" data-credit-card-description-card-text="Card" data-credit-card-description-ending-in-text="CREDIT_CARD_TYPE_PLACEHOLDER ending in LAST_FOUR_NUMBERS_PLACEHOLDER">
                                                        <label for="creditCards[0].save-card" class="check save-card-message">
                                                            <input id="creditCards[0].save-card" type="checkbox" aria-label="Remember this card for future use" class="cko-field gb-whitelist" name="creditCards[0].save_credit_card" data-cko-change="enableSavingCreditCard,trackSaveCard,trackOmniture" data-cko-rfrr-id="HOT.CKO.SAVECARD" data-hidden-credit-card-description-enabled="false" data-cko-enter-click="submitForm"/>
                                                                <span class="inline-label">Remember this card for future use</span>
                                                        </label>
                                                        <label class="text saving-description">
                                                            <span class="label">Description</span>
                                                            <input type="text" class="cko-field save_credit_card_desc gb-whitelist" name="creditCards[0].save_credit_card_desc" value="" data-cko-enter-click="submitForm" data-cko-change="setEditedFieldState" />
                                                        </label>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <input type="hidden" name="useFakeAddress" value="true" class="cko-field" data-cko-enter-click="submitForm" data-cko-change="setEditedFieldState"/>
                        </fieldset>
                        </form>
                    </div>
                  </div>
                    </div>
                  </div>
                </fieldset>
             </article> */}
            <article id="insurance" class="segment no-target insurance-module checkout-hotel-detail mt-3">
                <div class="global-insurance-container-v2 ins-grid-c1-15" id="hotelInsuranceContainer">
                    <div class="insurance-header remove-margin">
                        <div class="align-urgency cf">
                            <div class="shield-header-with-recommend-text">
                                <h2 class="benefits-heading">        
                                  <span class="insurance-header-title-padding">Protect your stay</span>
                                  <span class="badge badge-success badge-notification  insurance-badge insurance-badge-side-title bold-700 insurance-badge-line-height"><span class="insurtech-badge-text">Recommended</span></span>
                                </h2>
                            </div>
                        </div>
                    </div>      
                    <div id="US_HOTEL_GENERALI" data-ftl-template="US_HOTEL_GENERALI_RETRO" class="insurance-template insurance-offering ins-grid-c1-15" data-lob="hotel">
                        <div class="ins-wrapper remove-margin">
                          <div class="global-hotel template-body ins-grid-c1-15">
                            <form id="insurance-form" data-lob="hotel">
                                <fieldset class="fieldset-margin insurance-fields ins-grid-c1-15">
                                  <div class="insurance-benefit-solicitation">
                                    <div class="enhanceBenefitListTemplate ins-grid-c1-13 " data-template="benefit_popup_graphic_template">
                                      <div id="renderEnhanceBenefitList" data-stid="renderEnhanceBenefitList" class="benefits-container ">
                                          <ul data-stid="enhance-benefit-list" class="enhance-benefit type-300" title="Why should you protect your stay?">
                                              <li class="enhance-benefit-li">
                                                  <span class="enhance-benefit-icon" style={{}}>
                                                          <img src="https://a.travel-assets.com/dms-svg/insurance/benefit-clear.svg" alt="benefit-icon" data-stid="graphic" aria-hidden="true"/>
                                                  </span>
                                                  <div class="enhance-benefit-description">
                                                      <div class="enhance-benefit-description-header">Cancellation and interruption protection up to $100,000 per plan</div>
                                                  </div>
                                              </li>
                                              <li class="enhance-benefit-li">
                                                  <span class="enhance-benefit-icon" style={{}}>
                                                      <img src="https://a.travel-assets.com/dms-svg/insurance/benefit-skip_the_line.svg" alt="benefit-icon" data-stid="graphic" aria-hidden="true"/>
                                                  </span>
                                                  <div class="enhance-benefit-description">
                                                      <div class="enhance-benefit-description-header">Emergency assistance and transportation up to $200,000 per plan</div>
                                                  </div>
                                              </li>
                                              <li class="enhance-benefit-li">
                                                  <span class="enhance-benefit-icon" style={{}}>
                                                      <img src="https://a.travel-assets.com/dms-svg/insurance/benefit-lob_hotels.svg" alt="benefit-icon" data-stid="graphic" aria-hidden="true"/>
                                                  </span>
                                                  <div class="enhance-benefit-description">
                                                      <div class="enhance-benefit-description-header">Material misrepresentation of advertised property up to $500 per plan</div>
                                                  </div>
                                              </li>
                                              <li class="enhance-benefit-li">
                                                  <span class="enhance-benefit-icon" style={{}}>
                                                      <img src="https://a.travel-assets.com/dms-svg/insurance/benefit-timelapse.svg" alt="benefit-icon" data-stid="graphic" aria-hidden="true"/>
                                                  </span>
                                                  <div class="enhance-benefit-description">
                                                      <div class="enhance-benefit-description-header">Expenses due to travel delay up to $15,000 per plan</div>
                                                  </div>
                                              </li>
                                              <li class="enhance-benefit-li">
                                                  <span class="enhance-benefit-icon" style={{}}>
                                                    <img src="https://a.travel-assets.com/dms-svg/insurance/benefit-physical_activity_level.svg" alt="benefit-icon" data-stid="graphic" aria-hidden="true"/>
                                                  </span>
                                                  <div class="enhance-benefit-description">
                                                      <div class="enhance-benefit-description-header">Medical expenses up to $200,000 per plan</div>
                                                  </div>
                                              </li>
                                          </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <section id="socialProofingTemplate" class="socialProofingTemplate social-proof " data-template="placard" data-product="hotel" data-rfrr="SOCIAL_PROOF.PLACARD" data-track="ins-impression">
                                    <div class="placardTemplate ">
                                       <span class="placard-graphic" aria-hidden="true">                       
                                         <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                                              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6799 8C11.6799 9.66 10.2664 11 8.50213 11C6.73787 11 5.31371 9.66 5.31371 8C5.31371 6.34 6.73787 5 8.50213 5C10.2664 5 11.6799 6.34 11.6799 8ZM20.1823 8C20.1823 9.66 18.7688 11 17.0046 11C15.2403 11 13.8161 9.66 13.8161 8C13.8161 6.34 15.2403 5 17.0046 5C18.7688 5 20.1823 6.34 20.1823 8ZM8.50213 13C6.02579 13 1.0625 14.17 1.0625 16.5V19H15.9418V16.5C15.9418 14.17 10.9785 13 8.50213 13ZM15.9736 13.05C16.3456 13.02 16.6963 13 17.0046 13C19.4809 13 24.4442 14.17 24.4442 16.5V19H18.0674V16.5C18.0674 15.02 17.2065 13.89 15.9736 13.05Z" fill="#3D65D1"></path>
                                          </svg>
                                        </span>
                                        <div class="placard-content"> <p><b>27000</b><b>+</b> travelers protected their stay on Expedia last week.</p></div>
                                    </div>
                                  </section>
                                  <div class="insurance-selection-container retro">
                                      <span class="label required insReqMessage p-t-0" aria-hidden="true">Select an option to continue</span>
                                      <div class="one-plan-yes-highlight remove-yes-collapse">
                                          <div class="one-plan-yes-label">
                                              <div class="one-plan-CTA">
                                                 <label class="check insurance-product margin-adjustment" for="yes_insurance" data-cko-click="addGlobalInsuranceV2" data-piid="30010" data-insurancetypeid="30010" data-label="Yes, I want to add protection to my stay.">
                                                    <input type="radio" id="yes_insurance" name="insurance_piid" value="Generali US Hotel International" class="  cko-field-validate loggedForcedChoice" aria-label="Yes, I want to add protection to my stay." 
                                                        data-custom-rules="insuranceForcedChoiceValidate" data-product-type="hotel" />
                                                    <span class="inline-label">
                                                      <span class="yes-text yes-cta-text-header" aria-hidden="true">Yes, I want to add protection to my stay.</span>
                                                    </span>
                                                  </label>
                                              </div>
                                              <div id="one-plan-price" class="insurance-price" onclick="Checkout.GlobalInsuranceV2.Metrics.viewGlobalCalendarToolTipV2(Checkout.Model.productType)">
                                                  <div class="price type-600" aria-hidden="true">  $44.81</div>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="insurance-offering decline-offering remove-no-collapse">
                                          <div class="no-highlight-slim">
                                            <label class="check decline" for="no_insurance" data-cko-click="removeGlobalInsuranceV2" data-piid="no_insurance" data-label="No, I'm willing to risk my $1,433.83 stay booking. I understand by declining this coverage that I may be responsible for certain cancellation fees and delay expenses personally or through alternate coverage.">
                                              <input type="radio" id="no_insurance" name="insurance_piid" value="none" class="  gb-whitelist loggedForcedChoice" aria-label="No, I'm willing to risk my $1,433.83 stay booking. I understand by declining this coverage that I may be responsible for certain cancellation fees and delay expenses personally or through alternate coverage." data-custom-rules="insuranceForcedChoiceValidate" data-product-type="hotel" data-ins-product-internal-name="Generali US Hotel International" data-product="hotel"/>
                                              <span class="inline-label"><span class="no-text" aria-hidden="true">No, I'm willing to risk my $1,433.83 stay booking. I understand by declining this coverage that I may be responsible for certain cancellation fees and delay expenses personally or through alternate coverage.</span></span>
                                            </label>
                                          </div>
                                      </div>
                                  </div>
                                </fieldset>
                            </form>
                        </div>
                        </div>
                    </div>
                </div>
            </article>
            <article class="segment no-target checkout-hotel-detail mt-3">
              <div class="cancellation-policies">
                <h2 class="checkout-title-visual-alignment">Cancellation policy    </h2>
                <ul class="rr-bullets rules-list" style={{listStyle:'circle',marginLeft:'20px'}}>
                  <div class="important-information-rr-cancellation-div">
                      <li>Cancellations or changes made after 6:00pm (property local time) or no-shows are subject to a property fee equal to the first nights rate plus taxes and fees.</li>
                  </div>
                </ul>
              </div>
            </article>
            <article id="complete" class="segment no-target review-and-book checkout-hotel-detail">
              <div id="fxmp" class="hidden">
                  <p class="fx-header">
                      <strong>The following currencies are available for your booking:</strong>
                  </p>
                  <fieldset class="fx-fields">
                      <label class="check">
                          <input type="radio" class="fx-poscurrency gb-whitelist" name="fx_selected" value="false" data-cko-change="trackFX"/>
                          <span class="inline-label fx-label">
                            Pay in <span class="fx-code fx-poscurrency-code"></span> - <span class="fx-value fx-poscurrency-value"></span>            
                          </span>
                      </label>
                      <label class="check">
                          <input type="radio" class="fx-cccurrency gb-whitelist" name="fx_selected" value="true" checked="checked" data-cko-change="trackFX" />
                          <span class="inline-label fx-label">Pay in <span class="fx-code fx-cccurrency-code"></span> - <span class="fx-value fx-cccurrency-value"></span>            </span>
                      </label>
                  </fieldset>
              </div>
              <div id="important-information" class="important-information-rr-cancellation">
                  <h2 class="checkout-title-visual-alignment">Important information        </h2>
                  <div class="rr-cancellation-div">
                      <ul class="rr-bullets rules-list" style={{listStyle:'circle',marginLeft:'20px'}}>
                        <li>There is no front desk at this property. The host will greet guests on arrival. </li>
                      </ul>
                  </div>
              </div>
              <div id="booking-summary">
                  <hr class="booking-summary-separator" aria-hidden="true"/>
                  <div class="duration">
                      <div class="duration-check-in">
                          <span class="check-in">Check-in:</span>
                          <span class="date-range">{moment(checkoutdetail?.checkIn).format('DD-MM-YYYY')}</span>
                      </div>
                      <div class="duration-check-out">
                          <span class="check-out">Check-out:</span>
                          <span class="date-range total-night-stay-desktop">{moment(checkoutdetail?.checkOut).format('DD-MM-YYYY')} ({daysBetween}-night stay)</span>
                      </div>
                  </div>
                  <hr class="booking-summary-separator" aria-hidden="true"/>
              </div>
              <div id="rules-and-restrictions" class="legal-consent page-2  consent-margin">
                  <div class="implicit-consent">
                    By clicking on the button below, I acknowledge that I have reviewed the 
                    <a href="/privacy?rfrr=HOT.CKO.Privacy" target="_blank" data-cko-click="openWindow" data-cko-dctk-pageid="HOTEL.CKO.PRIVACY">Privacy Statement 
                    <span class="icon icon-popup" aria-hidden="true"><FontAwesomeIcon icon={faWindowRestore} /></span><span class="alt">Opens in a new window.</span></a> and  
                    <a href="https://travel.state.gov/content/passports/en/alertswarnings.html" target="_blank" data-cko-click="openWindow" data-cko-dctk-pageid="HOT.CKO.TravelAdvice"> Government Travel Advice 
                    <span class="icon icon-popup" aria-hidden="true"><FontAwesomeIcon icon={faWindowRestore} /></span><span class="alt">Opens in a new window.</span></a> and have reviewed and accept the 
                    <a href="/Checkout/V1/HotelRulesAndRestrictions?tripid=d3ea7a5a-949a-5162-b4f0-022b5c7fe0a2&amp;rfrr=HOT.CKO.Rules&amp;np=1" target="_blank" data-cko-click="openWindow" data-cko-dctk-pageid="HOTEL.CKO.RULES">Rules &amp; Restrictions 
                    <span class="icon icon-popup" aria-hidden="true"><FontAwesomeIcon icon={faWindowRestore} /></span><span class="alt">Opens in a new window.</span></a> and <a href="/terms?rfrr=HOT.CKO.Terms" target="_blank" data-cko-click="openWindow" data-cko-dctk-pageid="HOTEL.CKO.TERMS">Terms of Use 
                    <span class="icon icon-popup" aria-hidden="true"><FontAwesomeIcon icon={faWindowRestore} /></span><span class="alt">Opens in a new window.</span></a>.        
                  </div>
              </div>
              <div class='row mt-4'>
              <div className='p-3'>
                <div className='row payment' >
                  <div>
                    <h4 class="checkout-title-visual-alignment">Payment Method</h4>
                  </div>
                  <div class='section-tab check-mark-tab text-center mt-3 pb-4'>
                    <ul class='nav' id='myTab' role='tablist'>
                      <li class='nav-item'>
                          <i class='la la-check icon-element'></i>
                          <img
                            src={payment}
                            alt=''
                          />
                      </li>
                    </ul>
                  </div>
                  <div class='form-group mt-2 col-md-6'>
                    <label htmlFor="cardNumber" className="form-label">Card Holder Name</label>
                    <input
                      type='text'
                      class='form-control'
                      name='name'
                     value={cardInfo.name}
                     onChange={handlecarddetail}
                      placeholder='Name'
                    />
                  </div>
                  <div class='form-group mt-2 col-md-6'>
                  <label htmlFor="cardNumber" className="form-label">Card number</label>
                    <CardNumberElement  className="form-control" id="cardNumber" placeholder="0000 0000 0000 0000" />
                  </div>
                  <div class='form-group mt-2 col-md-6'>
                     <label htmlFor="expiryDate" className="form-label">Expiration date</label>
                     <CardExpiryElement className="form-control" id="expiryDate" />
                  </div>
                  <div class='form-group mt-2 col-md-6'>
                    <label htmlFor="cvc" className="form-label">CVC</label>
                    <CardCvcElement className="form-control" id="cvc" style={{width:'40%'}} />
                  </div>
                  {error && (
                    <div style={{color:"red"}}  className="error mt-2" role="alert">
                      {error}
                    </div>
                   )}
                </div>
              </div>
            </div>
            <div class='col-12 mt-3'>
              <div class='form-check'>
                <input
                  class='form-check-input'
                  type='checkbox'
                  checked={isChecked} // Set the checked state
                  onChange={handleCheckboxContinue}
                  id='invalidCheck'
                  required
                />
                <label class='form-check-label' for='invalidCheck'>
                  By continuing, you agree to the{' '}
                  <span style={{ color: 'red' }}> Terms and conditions</span>
                </label>
              </div>
            </div>
              <button type="button"  disabled={isBooking} onClick={confirmbooking} class="btn-primary btn-action complete-booking-cta booking-button">
                <span class="btn-label"> {isBooking ? 'Please Wait....' : 'Complete Booking'}</span>
              </button>
              <div class="security-message" aria-hidden="true">
                <span class="icon icon-lock" aria-hidden="true"><FontAwesomeIcon icon={faLock} /></span>       
                <span class="text1"> We use secure transmission and encrypted storage to protect your personal information.</span>
                <span class="page-2 visa-mandate-message text1">
                  Payments are processed in the U.S. except where the travel provider (hotel / airline etc) processes your payment outside the U.S., in which case your card issuer may charge a foreign transaction fee.        
                </span>
              </div>
            </article>
          </div>
          <div className='col-lg-4 order-first order-md-last'>
            <div id="secondary-content" class="col secondary-content " role="complementary">
              <div class="sticky-anchor"></div>
              <div>
                 <section>
                    <article id="trip-summary" class="trip-summary cf checkout-hotel-detail">
                      <h2 class="trip-summary-header visuallyhidden">Trip Summary</h2>
                      <div class="hotel-details-wrapper two-column">
                        <div id="hotel-details-for-optimized-review" class="hotel-details responsive-hotel-details">
                          <div class="full-hotel-image-overlay image-padding-for-mobile">
                            <figure class="image aspect-ratio16-9 fbimage" data-media-type="image" data-lazy="true" data-src="https://images.trvl-media.com/lodging/28000000/27120000/27113200/27113139/66ed86c2_cko.jpg" data-aspect-ratio="16-9" data-class="tile-media">
                              <img alt="" class="tile-media"  src={hotelimg} /><noscript>
                              <img  src={hotelimg} alt="" class="tile-media" /></noscript>
                              <span class="media-loader"><span class=" loader-primary loader-light loader-static loading" aria-hidden="true" title="Loading..."></span></span>
                              <div class="img-dark-overlay">
                                <div class="hotel-description content-overlay decreased-height  bottom">
                                  <h3 class="hotel-name"><span class="unlocalized-hotel-name"><span class="hotel-summary-name">{checkoutdetail?.hotel_name}</span></span></h3>
                                </div>
                              </div>
                            </figure>
                          </div>
                        </div>
                        <div class="booking-summary display-optimized-hotel-review">
                          <div class="hotel-review-summary optimized-review-summary ">
                            <div class="rating_out_of_maximumRating optimized-review-rating ">
                              <div class="hotel-rating-badge green-badge">9.2</div>
                              <div class="hotel-rating-details">
                                 <span class="hotel-review-description ">Wonderful</span>
                                 <span class="hotel-review-count">(14 reviews) </span>
                              </div>
                            </div>
                          </div>
                          {checkoutdetail?.rooms_list.map((item,index)=>(
                          <div  key={index} class="room-summary ">
                            <span class="room-count">{item.selected_qty} Room:  </span>
                            <span class="room-description">{item.board_id}</span>
                          </div>
                          ))}
                          <div class="expedia-extras"><div id="TH_IL"></div></div>
                          <div id="confidence-messaging-summary" class="with-no-background  confidence-messaging-summary">
                            <ul class="confidence-items">
                              <li class="confidence-item non-refundable-details">
                                <a data-control="modal" href="#non-refundable-content" id="non-refundable-modal" data-role-dialog="true" data-modal-id="non-refundable-modal" data-js-theme="default" data-animation="slide-in-slide-out">     Non-refundable
                                  <span class="icon icon-infoalt" aria-hidden="true"></span>
                                </a>
                                <div id="non-refundable-content" class="hidden">
                                  <span>If you change or cancel your booking you will not get a refund or credit to use for a future stay. This policy will apply regardless of COVID-19, subject to any local consumer laws.</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="duration">
                            <div class="duration-check-in">
                              <span class="check-in">Check-in:</span>
                              <span class="date-range"> {moment(checkoutdetail?.checkIn).format('DD-MM-YYYY')}</span>
                            </div>
                            <div class="duration-check-out">
                              <span class="check-out">Check-out:</span>
                              <span class="date-range">{moment(checkoutdetail?.checkOut).format('DD-MM-YYYY')}</span>
                            </div>
                            <span class="total-night-stay">{daysBetween}-night stay    </span>
                          </div>
                        </div>
                      </div>
                    </article>
                    <article>
                      <div class="booking-nudge-wrapper checkout-hotel-detail" data-booking-nudge-dynamic-messaging-enabled="false">
                        <span id="booking-nudge-general">
                          <div class="nudging-text">
                            <span class="icon icon-success" aria-hidden="true"><FontAwesomeIcon icon={faCheck} /></span> <span>You have good taste! Book now before someone else grabs it!</span>
                          </div>
                        </span>
                      </div>
                    </article>
                    <article>
                      <h2 class="price-summary-header visuallyhidden">Price summary</h2>
                      <section class="segmented-list price-summary checkout-hotel-detail">
                        <article id="price-summary-title" class="segment no-target">
                          <div class="price-summary-title">Price details</div>
                        </article>
                        <article id="price-summary" class="segment no-target">
                          <div id="newPriceDisplayEnabled" class="pricing-details-wrapper two-column">
                            <div id="details-content" className='tour_booking_amount_area'>
                              {checkoutdetail?.rooms_list.map((item,index)=>(
                                <div key={index}>
                                  <ul class='list-items list-items-2 py-3'>
                                    {showPrice ?(
                                      <li>
                                      <span>Room Price:</span>{CurrencyRates===undefined ? (baseCName):(CurrencyRates.selectedcurrency)} {hotaltotal=Number(renderPrice(calculateMarkup(item.rooms_total_price))).toFixed(2)}
                                    </li>
                                    ):(
                                      <li>
                                      <span>Room Price:</span>{checkoutdetail.currency} {hotaltotal=Number(calculateMarkup(item.rooms_total_price)).toFixed(2)}
                                    </li>
                                    )}
                                    <li><span>Adults:</span>{item.adults} </li>
                                    <li><span> Children :</span>{item.childs}</li>
                                  </ul>
                                </div>
                              ))}    
                              <div class="taxes">
                                <a data-control="modal" href="#taxes-and-fees-description" id="taxes-and-fees-link" data-role-dialog="true" data-modal-id="taxes-and-fees-link" data-js-theme="default" data-title="Taxes and fees" data-animation="slide-in-slide-out">     Taxes and fees <span class="icon icon-infoalt" aria-hidden="true"></span></a>
                                <div id="taxes-and-fees-description" class="hidden"><p>The taxes are tax recovery charges Expedia pays to its vendors (e.g. hotels); for details, please see our Terms of Use. We retain our service fees as compensation in servicing your travel reservation.</p></div>
                                <span class="taxes-and-fees-total-without-points amount-value " data-tax-included="false" data-price-update="totalTaxes">$0 </span>
                              </div>
                              <div class="fee-details due-at-hotel-fees non-daily"></div>
                              <hr class="fee-breakdown-bottom-separator" aria-hidden="true"/>
                              <div class="total-without-pwp">
                                <ul class='list-items list-items-2 pt-3'>
                                  {storedData ===null ? (
                                    <>
                                    {showPrice?(
                                    <li>
                                    <span>Sub Total:</span> {CurrencyRates===undefined ? (baseCName):(CurrencyRates.selectedcurrency)} {Number(renderPrice(savemarkup.final))}
                                  </li>
                                  ):(
                                    <li><span>Sub Total:</span>{checkoutdetail.currency} {savemarkup.final.toFixed(2)}</li>
                                  )}
                                  {showPrice ?(
                                    <li className='fw-bold'>
                                    <span>Total Price:</span>{CurrencyRates===undefined ? (StripeCurrency=baseCName):(StripeCurrency= CurrencyRates.selectedcurrency)} {StripePayment=(Number(renderPrice(savemarkup.final,1)))}
                                  </li>
                                  ):(
                                    <li className='fw-bold'>
                                    <span>Total Price:</span>{StripeCurrency=checkoutdetail.currency} {StripePayment=savemarkup.final.toFixed(2)}
                                  </li>
                                  )}
                                    </>
                                  ):(
                                  <>
                                    <li><span>Sub Total:</span>{ConverterCurrency} {Number(hotaltotal)+Number(flighttotal)}</li>
                                    <li className='fw-bold'><span>Total Price:</span>{StripeCurrency=ConverterCurrency} {StripePayment=Number(hotaltotal)+Number(flighttotal)}</li>
                                  </>
                                  )}
                                </ul>
                              </div>
                              <div class="hotel-rate-information">Rates are quoted in US dollars.</div>
                            </div>
                          </div>
                        </article>
                      </section>                            
                      {/* <div className='checkout-hotel-detail p-3'>
                        <div className='tour_booking_amount_area'>
                          <div className='Hotel-img'>
                            <div className='card-body'>
                              {storedData !==null && (
                                <>
                              {storedData.PricedItineraries.map((item, index) => (
                            <div key={index}>
                              <h4 className='text-center' style={{color:'cadetblue'}}>Flight Detail</h4>
                              <div className='mt-2 text-center'>
                                <h5>{item.DirectionInd} Flight</h5>
                              </div>
                              {item.OriginDestinationOptions.map((item3,index)=>(
                              <div key={index}>
                              {item3.FlightSegments.map(
                                (flight, index) => (
                                  <div key={index}>
                                    <div className='border-line mt-2'></div>
                                    <div class='container3 mt-2'>
                                      <h5 class='text3'>
                                        {flight.DepartureAirportLocationCode}
                                      </h5>
                                      <h5>
                                        <FontAwesomeIcon icon={faArrowRight} />
                                      </h5>
                                      <h5 class='text2'>
                                        {flight.ArrivalAirportLocationCode}
                                      </h5>
                                    </div>
                                    <div className='border-line mt-2'></div>
                                    <div className='tour_booking_amount_area'>
                                      <div className='Hotel-img'>
                                        <div className='card-body  '>
                                          <ul class='list-items  list-items-2 mt-2 py-2'>
                                            <li className='mt-2'>
                                              <span>Airline :</span>
                                              {
                                                Airlinenamesdata[
                                                  storedData.PricedItineraries[0]
                                                    .ValidatingAirlineCode
                                                ].AirLineName
                                              }
                                            </li>

                                            <li className='mt-2'>
                                              <span>Flight Type :</span>
                                              {
                                                flight.CabinClassType
                                              }
                                            </li>

                                            <li className='mt-2'>
                                              <span>Flight Number :</span>
                                              {flight.FlightNumber}
                                            </li>
                                            <li className='mt-2'>
                                              <span>Stop:</span>
                                              {flight.StopQuantity === 0 ? "Non-stop" : flight.StopQuantity+"Stop"}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class='fl-detail-left ms-0'
                                      style={{ padding: '0' }}
                                    >
                                      <div class='fl-detail-left-container'>
                                        <div class='fl-flight-schedual'>
                                          <div
                                            class='fl-flight-route'
                                            style={{ paddingBottom: '0' }}
                                          >
                                            <div class='fl-route-detail'>
                                              <h4 class='left h4-line'>
                                                {' '}
                                                {moment(flight.DepartureDateTime).format(
                                                  'LT'
                                                )}
                                                <br></br>(
                                                {flight.DepartureAirportLocationCode})
                                              </h4>
                                              <h4 class='center fl-width  h4-line text-center'>
                                                <FontAwesomeIcon
                                                  color='gray'
                                                  icon={faClockFour}
                                                />{' '}
                                              
                                                {Math.floor(flight.JourneyDuration / 60)}h{' '}
                                                {flight.JourneyDuration % 60}m
                                              </h4>
                                              <h4 class='right  h4-line fl-width text-end'>
                                                {moment(flight.ArrivalDateTime).format(
                                                  'LT'
                                                )}
                                                <span>
                                                  {' '}
                                                  ({flight.ArrivalAirportLocationCode})
                                                </span>
                                              </h4>
                                            </div>
                                            <div class='fl-route-direction'>
                                              <div class='fl-route-bar'></div>
                                              <div class='fl-icon'>
                                                <FontAwesomeIcon icon={faPlane} />
                                              </div>
                                            </div>
                                            <div class='fl-route-detail'>
                                              <p
                                                class='left'
                                                style={{ fontSize: '.9em' }}
                                              >
                                                {' '}
                                                <br />{' '}
                                                {moment(flight.DepartureDateTime).format(
                                                  'll'
                                                )}
                                              </p>
                                              <p
                                                class='center text-center'
                                                style={{ fontSize: '.9em' }}
                                              >
                                              </p>
                                              <p
                                                class='right text-end'
                                                style={{ fontSize: '.9em' }}
                                              >
                                                <br />{' '}
                                                {moment(flight.ArrivalDateTime).format(
                                                  'll'
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                              </div>
                              ))}
                              <div className='border-line mt-2'></div>
                              <div className='tour_booking_amount_area'>
                                <div className='Hotel-img'>
                                  <div class='card-img mt-2 pb-4'></div>
                                  <div className='card-body  '>
                                    <div className='mt-2'>
                                      <h4 class='card-title'>Baggage Info:</h4>
                                    </div>
                                    <div >
                                    <ul class='list-items mt-2 list-items-2 py-2'>
                                    {item.AirItineraryPricingInfo.PTC_FareBreakdowns.map((item1, index) => (
                                  
                                    <li key={index} className='mt-2'>
                                      {item1.PassengerTypeQuantity.Code==='ADT' && (
                                          <div style={{display:'contents'}}>
                                        <span>Adult Baggage :</span>  <span>{item1.BaggageInfo[0]}/ {item1.CabinBaggageInfo[0]}</span>
                                        </div>
                                      
                                        )}
                                        {item1.PassengerTypeQuantity.Code==='CHD' && (
                                          <div style={{display:'contents'}}>
                                        <span>Child Baggage :</span>  <span>{item1.BaggageInfo[0]}/ {item1.CabinBaggageInfo[0]}</span>
                                        </div>
                                      
                                        )}
                                        {item1.PassengerTypeQuantity.Code==='INF' && (
                                          <div style={{display:'contents'}}>
                                        <span>Infant Baggage :</span>  <span>{item1.BaggageInfo[0]}/ {item1.CabinBaggageInfo[0]}</span>
                                        </div>
                                      
                                        )}
                                      </li>
                                      ))}
                                    </ul>
                                    </div>
                                    <div className='border-line'></div>
                                    <div className='mt-2'>
                                      <h4 class='card-title'>Payment Info:</h4>
                                    </div>
                                    <div>
                                      {showPrice ?(
                                        <ul class='list-items mt-2 list-items-2 py-2'>
                                        {item.AirItineraryPricingInfo.PTC_FareBreakdowns.map((item1, index) => (
                                      
                                      <li key={index} className='mt-2'>
                                      {item1.PassengerTypeQuantity.Code==='ADT' && (
                                          <div style={{display:'contents'}}>
                                        <span>Adult Price :</span>  <span>{item1.PassengerTypeQuantity.Quantity} * {CurrencyRates===undefined ? (baseCName):(CurrencyRates.selectedcurrency)} {CalculateFLightMarkup(AdultAmount =renderPrice2(item1.PassengerFare.TotalFare.Amount,item1.PassengerTypeQuantity.Quantity,2))}</span>
                                        </div>
                                      
                                        )}
                                        {item1.PassengerTypeQuantity.Code==='CHD' && (
                                          <div style={{display:'contents'}}>
                                        <span>Child Price :</span>  <span>{item1.PassengerTypeQuantity.Quantity} * {CurrencyRates===undefined ? (baseCName):(CurrencyRates.selectedcurrency)} {CalculateFLightMarkup(ChildAmount=renderPrice2(item1.PassengerFare.TotalFare.Amount,item1.PassengerTypeQuantity.Quantity,3))}</span>
                                        </div>
                                      
                                        )}
                                        {item1.PassengerTypeQuantity.Code==='INF' && (
                                          <div style={{display:'contents'}}>
                                        <span>Infant Price :</span>  <span>{item1.PassengerTypeQuantity.Quantity} * {CurrencyRates===undefined ? (baseCName):(CurrencyRates.selectedcurrency)} {CalculateFLightMarkup(InfantAmount=renderPrice2(item1.PassengerFare.TotalFare.Amount,item1.PassengerTypeQuantity.Quantity,4))}</span>
                                        </div>
                                      
                                        )}
                                      
                                      </li>
                                        
                                          ))}
                                        </ul>
                                        ):(
                                          <ul class='list-items mt-2 list-items-2 py-2'>
                                          {item.AirItineraryPricingInfo.PTC_FareBreakdowns.map((item1, index) => (
                                        
                                          <li key={index} className='mt-2'>
                                            {item1.PassengerTypeQuantity.Code==='ADT' && (
                                                <div style={{display:'contents'}}>
                                                <span>Adult Price :</span>  <span>{item1.PassengerTypeQuantity.Quantity} * {item1.PassengerFare.TotalFare.CurrencyCode}  {CalculateFLightMarkup(AdultAmount =item1.PassengerFare.TotalFare.Amount)}</span> </div>
                                            
                                              )}
                                              {item1.PassengerTypeQuantity.Code==='CHD' && (
                                                <div style={{display:'contents'}}>
                                                <span>Child Price :</span>  <span>{item1.PassengerTypeQuantity.Quantity} * {item1.PassengerFare.TotalFare.CurrencyCode}  {CalculateFLightMarkup(ChildAmount=item1.PassengerFare.TotalFare.Amount)}</span></div>
                                            
                                              )}
                                              {item1.PassengerTypeQuantity.Code==='INF' && (
                                                <div style={{display:'contents'}}>
                                              <span>Infant Price :</span>  <span>{item1.PassengerTypeQuantity.Quantity} * {item1.PassengerFare.TotalFare.CurrencyCode}  {CalculateFLightMarkup(InfantAmount=item1.PassengerFare.TotalFare.Amount)}</span>
                                              </div>
                                            
                                              )}
                                            
                                            </li>
                                      
                                            ))}
                                          </ul>
                                    )}
                                    </div>
                                    <div >
                                      <ul class='list-items mt-2 list-items-2 py-2'>
                                      {showPrice ?(
                                        <>
                                        <div className='border-line'></div>
                                        <li  className='mt-2 '>
                                        <span>Flight Total : </span>{CurrencyRates===undefined ? (ConverterCurrency=baseCName):(ConverterCurrency=CurrencyRates.selectedcurrency)} {flighttotal=MarkupSum=CalculateFLightMarkup(ConverterTotalSum=renderPrice2(Totalsum,1,1),5)}
                                        </li><li  className='mt-2 '>
                                        <span>Hotel Total : </span> {CurrencyRates===undefined ? (baseCName):(CurrencyRates.selectedcurrency)} {hotaltotal}
                                        </li>
                                        </>
                                        ):(
                                          <>
                                          <div className='border-line'></div>
                                          <li  className='mt-2 '>
                                          <span>Flight Total : </span> {ConverterCurrency=Currency} {flighttotal=MarkupSum=CalculateFLightMarkup(ConverterTotalSum=Totalsum)}
                                        </li>
                                        <li  className='mt-2 '>
                                        <span>Hotel Total : </span> {checkoutdetail.currency} {hotaltotal=savemarkup.final.toFixed(2)}
                                        </li>
                                        </>
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </article>
                  </section>
                </div>
              </div>
          </div>
        </div>
      </div>
    </WithoutLayout>
    </>
  )
};

export default BookRoom
