import React from 'react'
import Layout from '../../Components/Layout/Layout'
import img from './faq.jpg'
function PrivacyPolicy () {
  return (
    <>
      <Layout>
        <div>
          <img src={img} />
        </div>
        <div className='container'>
          <div className='row mt-3 text-center'>
            <h3>Privacy Policy for Globetm</h3>
          </div>
          <div className='row mt-4 '>
            <p className='FontSize15'>
            At Globetm, accessible from https://globetm.co.uk/ , one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Globetm and how we use it.
            </p>
            <p className='mt-3'>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
          <p className='mt-3'>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
          </div>
          <div className='row mt-4 '>
            <h4>Information We Collect</h4>
            <p className='FontSize15'>
            The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
            </p>
          <p className='mt-3'>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
          <p className='mt-3'>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>

          </div>
          <div className='row mt-4 '>
            <h4>How We Use Your Information</h4>
            <p className='FontSize15'>
            We use the information we collect in various ways, including to:
            </p>
            <ul style={{ listStyle: 'disc' }}>
              <li>
                <p>
                Provide, operate, and maintain our website
                </p>
              </li>
              <li>
                <p>Improve, personalize, and expand our website</p>
              </li>
              <li>
                <p>
                Understand and analyze how you use our website
                </p>
              </li>
              <li>
                <p>
                Develop new products, services, features, and functionality
                </p>
              </li>
              <li>
                <p>
                Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes
                </p>
              </li>
              <li>
                <p>
                Send you emails
                </p>
              </li>
              <li>
                <p>
                Find and prevent fraud               
                 </p>
              </li>
              <li>
                <p>
                Log Files               
                 </p>
              </li>
              <li>
                <p>
                Globetm follows a standard procedure of using log files.               
                 </p>
              </li>
            </ul>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default PrivacyPolicy
