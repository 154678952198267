import React,{useState,useEffect} from "react";
import  Axios from "axios";
import { Hotelapitoken,ApiEndPoint,CustomerDomainName } from '../GlobalData/GlobalData';
import Loading from "../Loading/Loader";
import {Modal, ModalBody } from 'reactstrap'
import { fetchHotels,fetchHotelsSearh, OneWayFlightIndexSearchData,OneWayFlightSearchData } from '../../Redux/Actions/actions';
import { useNavigate,NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ToastContainer,toast } from 'react-toastify';
import { airportcode } from '../Data/AirportCodes';
import payment from '../../Images/payment.png'
import logo from './logo.png'
import moment from 'moment';
function Footer(){
  const [isLoading, setIsLoading] = useState(false);
  const [userLocation,setUserLocation]=useState([]);
  const [arivalAirpotrs,setArivalAirpotrs]=useState([]);
  const [isOpen,setIsOpen]=useState(false);
  var token = Hotelapitoken();
  var apiendpoint=ApiEndPoint();
  const navigation=useNavigate();
  const Dispatch=useDispatch();
  const Airportcode=airportcode.items;
  useEffect(()=>{
    GetFlighMarkup();
    getUserLocation();
  },[]);
  function getUserLocation() {
    // Fetch user's location data
    fetch("https://api.geoapify.com/v1/ipinfo?&apiKey=0708571ae58c4688824a7d01ca397041")
      .then(response => response.json())
      .then(data => {
        setUserLocation(data);
        // Handle location data as needed
      })
      .catch(error => {
        console.error('Error fetching user location:', error);
      });
  }

  
  const Searchhotels = async (name) => {
    setIsLoading(true);
    const currentDate = new Date();
    const twoDaysLater = new Date(currentDate);
    twoDaysLater.setDate(currentDate.getDate() + 2);
    const threeDaysLater = new Date(currentDate);
    threeDaysLater.setDate(currentDate.getDate() + 3);
    var destination_name='';
     var country='';
     var lat='';
     var lon='';
     var pin='';
    if(name==='New York'){
     destination_name="New York";
     country="United States";
     lat=40.7127753;
     lon=-74.0059728;
     pin="US";
    }else if(name==='Los Angeles'){
     destination_name="Los Angeles";
     country="United States";
     lat=34.0549076;
     lon=-118.242643;
     pin="US";
    }else if(name==='Orlando'){
     destination_name="Orlando";
     country="United States";
     lat=36.1489309;
     lon=-97.378098;
     pin="US";
    }else if(name==='Atlanta'){
      destination_name="Atlanta";
      country="United States";
      lat=33.748752;
      lon=-84.38768449999999;
      pin="US";
     }else if(name==='Dallas'){
      destination_name="Dallas";
      country="United States";
      lat=32.7766642;
      lon=-96.79698789999999;
      pin="US";
     }else if(name==='San Francisco'){
      destination_name="SF";
      country="United States";
      lat=37.7749295;
      lon=-122.4194155;
      pin="US";
     }else if(name==='Miami'){
      destination_name="Miami";
      country="United States";
      lat=25.7616798;
      lon=-80.1917902;
      pin="US";
     }else if(name==='Denver'){
      destination_name="Denver City";
      country="United States";
      lat=32.9645475;
      lon=-102.8290963;
      pin="US";
     }else if(name==='Seattle'){
      destination_name="Seattle";
      country="United States";
      lat=47.6061389;
      lon=-122.3328481;
      pin="US";
     }else if(name==='San Diego'){
      destination_name="San Diego";
      country="United States";
      lat=32.715738;
      lon=-117.1610838;
      pin="US";
     }
     
    // Define your API URL, authToken, and dataToSend as shown in the previous example
    const FormData = {
       "token":token,
      "currency_slc": 'AFN',
      "currency_slc_iso": 'AF',
      "destination_name":destination_name,
      "country":country,
      "lat":  lat,
      "long": lon,
      "pin": pin,
      "cityd":destination_name,
      "country_code": pin,
      "check_in":moment(twoDaysLater).format('YYYY-MM-DD'),
      "check_out":moment(threeDaysLater).format('YYYY-MM-DD'),
      "slc_nationality": country,
      "adult": 2,
      "child": 0,
      "room": 1,
      "Adults": [2],
      "children": [0],
      "child_ages1": [],
      "rooms_counter": [1],
      "child_ages2": [],
     
    };
    try {
    
     Dispatch(fetchHotelsSearh(FormData));
      const response = await Axios.post(apiendpoint+'/api/search/hotels/new',FormData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        
        } ,
        
      });
      window.scrollTo(0, 0);
      sessionStorage.removeItem('FlightCheckOut');
      navigation('/hotels');
      Dispatch(fetchHotels(response.data));
      // Handle the API response here
      setIsLoading(false);
    } catch (error) {
      // Handle errors here
      setIsLoading(false);
      console.error('Error:', error);
    }
  };
  // **********Flight Section*************

  const GetFlighMarkup=async()=>{
    var data={
      'token':token,
    };
  try{
    const response=await Axios.post(apiendpoint+'/api/get_markup_flight_new_Live',data,{
      headers:{
        "Access-Control-Allow-Origin": "*",

      }
    });
    localStorage.setItem('FlightMarkup',JSON.stringify(response.data));
  } catch (error){
      console.error('Error',error);
  }

};

const OneWayapicall = async (arrival) => {
  var Location=userLocation.city.name;
  const UserAirport = Airportcode.filter(option =>
    option.cityName.toLowerCase().includes(Location.toLowerCase())
  );
  if(UserAirport.length===0){
    return;
  }
  const random15DigitNumber = generateRandomNumber();
  const currentDate = new Date();
   const twoDaysLater = new Date(currentDate);
   twoDaysLater.setDate(currentDate.getDate() + 2);
  const data = {
    token_authorization: token,
    case: 'search_flights',
    MaxStopsQuantity:'All',
    DepartureDate: moment(twoDaysLater).format('YYYY-MM-DD'),
    DepartureCode: UserAirport[0].airportCode,
    ArrivalCode: arrival,
    AirTripType:'OneWay',
    AirlinesCode: 'EK',
    adult: 1,
    child: 0,
    infant: 0,
    ConversationId: random15DigitNumber,
    CabinType: 'no'
  }
  Dispatch(OneWayFlightIndexSearchData(data))
  setIsLoading(true)
  try {
    const response = await Axios.post(
      apiendpoint + '/api/flight_search_Live',
      data,
      {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
    setIsLoading(false)
    if (response.data.Success === false) {
      toast.warning(response.data.Message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;

    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      Dispatch(OneWayFlightSearchData(response.data.Data))
      sessionStorage.setItem('15digitnumber', random15DigitNumber.toString())
      navigation('/Flight_search')
    }
  } catch (error) {
    
    setIsLoading(false)
    console.error('Error:', error)
  }
};

function generateRandomNumber () {
  const min = Math.pow(10, 14) 
  const max = Math.pow(10, 15) - 1 
  return Math.floor(Math.random() * (max - min + 1) + min)
};
const OpenModal=(data)=>{
  if(data !==''){
    const UserAirport = Airportcode.filter(option =>
      option.cityName.toLowerCase().includes(data.toLowerCase())
    );
    setArivalAirpotrs(UserAirport);
  }
 
  setIsOpen(!isOpen);
};
    return(
    <>
    {isLoading && ( <Loading/>)}
    <footer class="footer skin-light-footer">
      <div>
        <div class="container">
          <div class="row">

            <div class="col-lg-3 col-md-4">
              <div class="footer-widget">
                <div class="d-flex align-items-start flex-column mb-3">
                  <div class="d-inline-block"><img src={logo} class="img-fluid" width="160"
                      alt="Footer Logo"/>
                  </div>
                </div>
                <div class="footer-add pe-xl-3">
                  <p>We make your dream more beautiful & enjoyful with lots of happiness.</p>
                </div>
                {/* <div class="foot-socials">
                  <ul>
                    <li><a href="JavaScript:Void(0);"><i class="fa-brands fa-facebook"></i></a></li>
                    <li><a href="JavaScript:Void(0);"><i class="fa-brands fa-linkedin"></i></a></li>
                    <li><a href="JavaScript:Void(0);"><i class="fa-brands fa-google-plus"></i></a></li>
                    <li><a href="JavaScript:Void(0);"><i class="fa-brands fa-twitter"></i></a></li>
                    <li><a href="JavaScript:Void(0);"><i class="fa-brands fa-dribbble"></i></a></li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div class="col-lg-3 col-md-4">
              <div class="footer-widget">
                <h4 class="widget-title">Best Routes</h4>
                <ul class="footer-menu">
                  <li><p>Dubai</p></li>
                  <li><p>New York</p></li>
                  <li><p>Istanbul</p></li>
                  <li><p>London</p></li>
                </ul>
              </div>
            </div>

            {/* <div class="col-lg-2 col-md-4">
              <div class="footer-widget">
                <h4 class="widget-title">Our Resources</h4>
                <ul class="footer-menu">
                  <li><a href="JavaScript:Void(0);">Free Business tools</a></li>
                  <li><a href="JavaScript:Void(0);">Affiliate Program</a></li>
                  <li><a href="JavaScript:Void(0);">Success Stories</a></li>
                  <li><a href="JavaScript:Void(0);">Upwork Reviews</a></li>
                  <li><a href="JavaScript:Void(0);">Resources</a></li>
                  <li><a href="JavaScript:Void(0);">Help & Support</a></li>
                </ul>
              </div>
            </div> */}

            <div class="col-lg-3 col-md-6">
              <div class="footer-widget">
                <h4 class="widget-title">The Company</h4>
                <ul class="footer-menu">
                  <li><NavLink to='/terms_and_conditions'>Terms & Conditions</NavLink></li>
                  <li><NavLink to='/complaint_policy'>Complaint Policy</NavLink></li>
                  <li><NavLink to='/privacy_policy'>pivacy policy</NavLink></li>
                  <li><NavLink to='/faqs'>Faqs</NavLink></li>
                </ul>
              </div>
            </div>

            <div class="col-lg-3 col-md-6">
              <div class="footer-widget">
                <h4 class="widget-title">Payment Methods</h4>
                <div class="pmt-wrap">
                  <img src={payment} class="img-fluid" alt=""/>
                </div>
                {/* <div class="our-prtwrap mt-4">
                  <div class="prtn-title">
                    <p class="text-muted-2 fw-medium">Our Partners</p>
                  </div>
                  <div class="prtn-thumbs d-flex align-items-center justify-content-start">
                    <div class="pmt-wrap pe-4">
                      <img src="assets/img/mytrip.png" class="img-fluid" alt=""/>
                    </div>
                    <div class="pmt-wrap pe-4">
                      <img src="assets/img/tripadv.png" class="img-fluid" alt=""/>
                    </div>
                    <div class="pmt-wrap pe-4">
                      <img src="assets/img/goibibo.png" class="img-fluid" alt=""/>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="footer-bottom border-top">
        <div class="container">
          <div class="row align-items-center justify-content-center">

            <div class="col-xl-12 col-lg-12 text-center col-md-12">
              <p class="mb-0">© 2023 Globe Travel Management.</p>
            </div>

            {/* <div class="col-xl-6 col-lg-6 col-md-6">
              <ul class="p-0 d-flex justify-content-start justify-content-md-end text-start text-md-end m-0">
                <li style={{color:'#557685'}}>Terms of services</li>
                <li class="ms-3" style={{color:'#557685'}}>Privacy Policies</li>
                <li class="ms-3" style={{color:'#557685'}}>Cookies</li>
              </ul>
            </div> */}

          </div>
        </div>
      </div>
    </footer>
    </>        
    );
}

export default Footer;